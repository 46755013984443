import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalRequireVoteSignatureChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnProposalRequireVoteSignatureChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    summaryActionText={`Setting "Require Signatures to Vote" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? bundledCollectiveLog.logs[0].data.requireVotingSignature
          ? `to 'Yes'`
          : `to 'No'`
        : ''
    }`}
    hideSummaryActors
    detailsActionText={log => (
      <>
        {`Require Signatures to Vote was changed to `}
        {log.data.requireVotingSignature ? `'Yes'` : `'No'`}
      </>
    )}
  />
)

export default CollectiveLogDataOnProposalRequireVoteSignatureChanged
