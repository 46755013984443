import { formatToken } from 'lib/collectives/helpers'
import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalRequireVoteSignatureChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnMinEthContributionChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    summaryActionText={`Setting "Minimum Ether Contribution" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? `to ${formatToken(
            bundledCollectiveLog.logs[0].data.contributionAmount
          )}`
        : ''
    }`}
    hideSummaryActors
    detailsActionText={log =>
      `Minimum Eth Contribution was changed to ${formatToken(
        log.data.contributionAmount
      )}`
    }
  />
)

export default CollectiveLogDataOnProposalRequireVoteSignatureChanged
