import Typography from 'components/common/Typography'
import { OptionProps, components } from 'react-select'
import { TAbiMethod, friendlyMethodLabel } from './types'

const SelectValueShell: React.FC<{
  abiMethod: TAbiMethod
}> = ({ abiMethod }) => {
  const isPayable = abiMethod.stateMutability === 'payable'
  const inputsText = abiMethod.inputs
    .map(input => friendlyMethodLabel(input.name, input.type))
    .join(', ')
  return (
    <div className="flex justify-start">
      <Typography size="sm" component={'div'}>
        {abiMethod.name}{' '}
        {(!!abiMethod.inputs.length ||
          abiMethod.stateMutability === 'payable') && (
          <Typography size="sm" className="flex" component={'span'} italics>
            {'inputs:'}
            <Typography size="sm" color="gray">
              &nbsp;
              {inputsText}
              {isPayable && !!abiMethod.inputs.length ? ', ' : ''}
              {isPayable && 'ether amount'}
            </Typography>
          </Typography>
        )}
      </Typography>
    </div>
  )
}

export const SelectSingleValue = ({
  ...rest
}: OptionProps<TAbiMethod, false, any>) => {
  const abiMethod = rest.data as TAbiMethod

  return (
    <components.SingleValue {...rest}>
      <SelectValueShell abiMethod={abiMethod} />
    </components.SingleValue>
  )
}

export const SelectOption = ({
  ...rest
}: OptionProps<TAbiMethod, false, any>) => {
  const abiMethod = rest.data as TAbiMethod

  return (
    <components.Option {...rest} isSelected={false}>
      <SelectValueShell abiMethod={abiMethod} />
    </components.Option>
  )
}
