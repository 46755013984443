import { isPresentAndTruthy } from 'lib/helpers'
import { BigNumber } from 'lib/BigInt'
import { useFlag } from 'hooks'
import { useMemo } from 'react'
import { CollectiveQueryResult, WalletType } from 'graphql/generated'

interface IValue {
  usd: string
  eth: string
  ethFormatted: string
}

export interface ISummary {
  balance: IValue
  tokens: IValue
  nfts: IValue
  total: IValue
}

interface IOptions {
  ethFormattedDecimals?: number
  usdFormattedDecimals?: number
}

function sumBigInt(
  bigIntsIsh: (string | number | BigInt | BigNumber)[]
): BigNumber {
  let total = new BigNumber(0)
  bigIntsIsh.forEach(bigIntIsh => {
    total = total.plus(bigIntIsh.toString())
  })

  return total
}

export function useWalletSummary(
  collective: NonNullable<CollectiveQueryResult['collective']>,
  wallets: Array<
    NonNullable<CollectiveQueryResult['collective']>['wallets'][0]
  >,
  options?: IOptions
): ISummary {
  const ethSpotPrice = useFlag('ETH_USD_PRICE', 3000)
  const flowSpotPrice = useFlag('FLOW_USD_PRICE', 8)

  const summary = useMemo(() => {
    // Wallet Balance
    const balanceEth = sumBigInt(
      wallets.map(wallet => {
        if (wallet.type === WalletType.ETHEREUM) {
          return wallet.balance
        }
        // in order to "fit" the rest of our calculations,
        // we'll convert FLOW->ETH at USD value
        else if (wallet.type === WalletType.FLOW) {
          return new BigNumber(wallet.balance)
            .shiftedBy(-8)
            .multipliedBy(flowSpotPrice)
            .dividedBy(ethSpotPrice)
            .shiftedBy(18)
        }

        return 0
      })
    )
    const balanceUsd = balanceEth.shiftedBy(-18).multipliedBy(ethSpotPrice)

    // ECR20 Tokens
    const tokensUsd = sumBigInt(
      collective.erc20Assets
        .filter(x => wallets.some(wallet => wallet.id === x.wallet.id))
        .map(x => x.estimatedValueUSD)
        .filter(isPresentAndTruthy)
    )

    const tokensEth = sumBigInt(
      collective.erc20Assets
        .filter(x => wallets.some(wallet => wallet.id === x.wallet.id))
        .map(x => x.estimatedValueEth)
        .filter(isPresentAndTruthy)
    )

    // NFTs
    const nftsUsd = sumBigInt(
      collective.nftAssets
        .filter(x => wallets.some(wallet => wallet.id === x.wallet.id))
        .map(x => x.estimatedValueUSD)
        .filter(isPresentAndTruthy)
    )

    const nftsEth = sumBigInt(
      collective.nftAssets
        .filter(x => wallets.some(wallet => wallet.id === x.wallet.id))
        .map(x => x.estimatedValueEth)
        .filter(isPresentAndTruthy)
    )

    // Aggregated totals
    const totalUsd = balanceUsd.plus(tokensUsd).plus(nftsUsd)
    const totalEth = balanceEth.plus(tokensEth).plus(nftsEth)

    // Formatting options
    const ethFormattedDecimals = options?.ethFormattedDecimals || 5
    const usdFormattedDecimals = options?.usdFormattedDecimals || 2

    return {
      balance: {
        eth: balanceEth.toFormat(2),
        ethFormatted: balanceEth.formatEther(ethFormattedDecimals),
        usd: balanceUsd.toFormat(usdFormattedDecimals)
      },
      tokens: {
        eth: tokensEth.toFormat(2),
        ethFormatted: tokensEth.formatEther(ethFormattedDecimals),
        usd: tokensUsd.toFormat(usdFormattedDecimals)
      },
      nfts: {
        eth: nftsEth.toFormat(2),
        ethFormatted: nftsEth.formatEther(ethFormattedDecimals),
        usd: nftsUsd.toFormat(usdFormattedDecimals)
      },
      total: {
        eth: totalEth.toFormat(2),
        ethFormatted: totalEth.formatEther(ethFormattedDecimals),
        usd: totalUsd.toFormat(usdFormattedDecimals)
      }
    }
  }, [collective, wallets, ethSpotPrice, flowSpotPrice, options])

  return summary
}
