import { BigNumber } from 'lib/BigInt'
import { IProposalCommandInputProps } from '../ProposalCommand.types'

export type TRemoteConnect = IProposalCommandInputProps & {
  target: string
  setTarget: CallbackWithParam<string>
  abiData: string
  setAbiData: CallbackWithParam<string>
  amountInWei: BigNumber
  setAmountInWei: CallbackWithParam<BigNumber>
}

export type TAbiMethodInputOutput = {
  internalType: string
  name: string
  type: string
}

export type TAbiMethod = {
  name: string
  type: string
  inputs: TAbiMethodInputOutput[]
  outputs: TAbiMethodInputOutput[]
  stateMutability: string
}

export function friendlyMethodInputType(type: string) {
  if (type.includes('uint')) {
    if (type.includes('[]')) {
      return 'numeric[]'
    }
    return 'numeric'
  }
  return type
}

export function friendlyMethodLabel(name: Maybe<string>, type: string) {
  if (!name) {
    return friendlyMethodInputType(type)
  }
  return `${name} (${friendlyMethodInputType(type)})`
}
