import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faPhotoVideo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from 'components/Spinner'
import StyledTooltip from 'components/Tooltip/Tooltip'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type TBaseProps = {
  topContent: React.ReactNode
  bottomContent: React.ReactNode
  isError?: boolean
  isLoading?: boolean
  errorIcon?: IconDefinition
}

export const BaseListItem: React.FC<TBaseProps> = ({
  topContent,
  bottomContent,
  isError = false,
  isLoading = false,
  errorIcon = faPhotoVideo
}) => {
  return (
    <div className="border rounded-md border-gray-300">
      <div className="h-[140px] lg:h-[180px] w-full flex items-center relative">
        <div
          className={twMerge(
            'flex-1 h-full aspect-square flex items-center justify-center border-b border-gray-300',
            isError && 'bg-gray-100 border-gray-200'
          )}
        >
          {isError ? (
            <StyledTooltip arrow title={`Could'nt find or load source`}>
              <div className="text-gray-400">
                <FontAwesomeIcon icon={errorIcon} size="lg" />
              </div>
            </StyledTooltip>
          ) : (
            isLoading && (
              <div className="absolute w-full h-full flex justify-center items-center">
                <Spinner />
              </div>
            )
          )}
          {/* required to run error and isloading states */}
          {topContent}
        </div>
      </div>

      <div className="h-[140px] lg:h-[180px] flex flex-col justify-between p-2 space-y-1">
        {bottomContent}
      </div>
    </div>
  )
}
