import { Typography } from 'components/common'
import { CollectiveProposal, Question } from 'graphql/generated'

export const LogProposalLink: React.FC<{
  disabled?: boolean
  proposal: Pick<CollectiveProposal, 'id' | 'publicUrl' | 'title'> & {
    survey?: Maybe<{ questions: Pick<Question, 'question'>[] }>
  }
}> = ({ disabled, proposal }) => {
  if (disabled) {
    return <Typography>{proposal.title}</Typography>
  }
  return (
    <Typography className="hover:text-blue" href={proposal.publicUrl}>
      {proposal.title}
    </Typography>
  )
}
