import BigNumber from 'bignumber.js'
import { formatToken } from 'lib/collectives/helpers'
import CollectiveToken from '../../Common/CollectiveTokenPopover'
import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnTransfer: React.FC<
  | ICollectiveLogListItemProps<'CollectiveLogDataOnTransfer'>
  | ICollectiveLogListItemProps<'CollectiveLogDataOnTransferSingle'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={`transferred ${
        bundledCollectiveLog.logs.length === 1
          ? 'an asset'
          : `${bundledCollectiveLog.logs.length} assets`
      }`}
      detailsActionText={log => {
        const is1155Single =
          log.data.__typename === 'CollectiveLogDataOnTransferSingle'
        const value = log.data.value
          ? is1155Single
            ? new BigNumber(log.data.value)
            : formatToken(new BigNumber(log.data.value), {
                roundToDecimals: 2
              })
          : 0
        return (
          <>
            {`sent `}
            {log.data.value &&
              new BigNumber(log.data.value).gt(0) &&
              `${value} `}
            {log.data.token && (
              <>
                <CollectiveToken token={log.data.token} removeTokenFromName />
                {'tokens '}
              </>
            )}
            {log.data.tokenId && `(token ID #${log.data.tokenId})`}
            {` to address `}
            <LogEtherscanTxLink txHash={log.data.to} type="address" />
          </>
        )
      }}
    />
  )
}

export default CollectiveLogDataOnTransfer
