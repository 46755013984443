import { useControlledInput, useIsLoggedIn } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import Link from 'next/link'
import { NextSeo } from 'next-seo'
import { useCollectiveContext } from 'context/CollectiveContext'
import CollectiveSummaryCard from './CollectiveSummaryCard/CollectiveSummaryCard'
import ProposalsTable from '../ProposalsTable/index'
import CollectiveAssets from './CollectiveAssets'
import CollectiveParameters from './CollectiveParameters'
import CollectiveWallets from './CollectiveWallets/CollectiveWallets'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import { BoxHover } from 'components/common/StyledBoxes'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { useSelectChannel } from 'hooks/chat/useSelectChannel'
import { CollectiveLogList } from './CollectiveLog/CollectiveLogList'
import { CollectiveAnnoucement } from '../CollectiveAnnoucements/CollectiveAnnouncement'
import CollectivePageHeader from '../Common/CollectivePageHeader/CollectivePageHeader'
import { useListCollectiveProposals } from 'hooks/collectives/useListCollectiveProposals'
import ContentWrapper from '../../ContentWrapper'

function CollectiveDetails() {
  const { collective, demoMode, collectiveId } = useCollectiveContext()
  const isLoggedIn = useIsLoggedIn()
  const [search, setSearch] = useState<string>()
  const [searchString, onSearchStringChange, clearSearchString] =
    useControlledInput()

  const { edges, loading, loadingMore, loadMore, pageInfo } =
    useListCollectiveProposals(collective.id, 5, search)

  const proposals = (!demoMode ? edges || [] : collective.proposals.edges)?.map(
    x => ({
      ...x.node,
      collective // inject
    })
  )

  const onSearchStringChangeDebounced = useDebouncedCallback(e => {
    setSearch(e)
  }, 300)

  useEffect(() => {
    onSearchStringChangeDebounced(searchString)
  }, [onSearchStringChange, onSearchStringChangeDebounced, searchString])

  const { selectChannel } = useSelectChannel()
  useEffect(() => {
    selectChannel(collectiveId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectiveId])

  return (
    <ContentWrapper>
      <NextSeo
        openGraph={{
          url: `https://upstreamapp.com/collective/${collective.id}`,
          images: collective.ogImageUrl
            ? [{ url: collective.ogImageUrl, width: 2048, height: 1077 }]
            : []
        }}
        twitter={{
          cardType: 'summary_large_image'
        }}
        additionalMetaTags={[
          {
            name: 'twitter:image',
            content:
              collective.ogImageUrl ||
              'https://upstreamapp.com/static/robot.png'
          },
          {
            name: 'twitter:image:alt',
            content: 'DAO Card'
          }
        ]}
      />

      <CollectivePageHeader indexPageMode collective={collective} />

      <div className="p-4 space-y-4">
        {collective.archived && (
          <div className="rounded-lg bg-red-100 transform transition-height">
            <div className="py-2 px-4 flex w-full overflow-hidden">
              <div className="mt-1.5 mr-3 bg-red-500 w-2 h-2 rounded-full" />

              <div className="flex-1 overflow-hidden">
                <div className="flex flex-row justify-between items-start space-x-1 overflow-hidden">
                  <div className="mr-4 flex flex-1 flex-col overflow-hidden">
                    <div className="flex-1 max-h-48 overflow-hidden">
                      <p className="text-sm font-semibold text-gray-900 pb-2">{`This DAO has been archived.`}</p>
                      <p className="text-sm font-normal text-gray-700">{`It's no longer operating, accepting new members, contributions, or proposals.`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoggedIn &&
          collective.membership?.approved &&
          collective.announcement && (
            <div className="rounded-lg bg-light-blue-100 transform transition-height">
              <CollectiveAnnoucement
                announcement={collective.announcement}
                collectiveId={collectiveId}
                privileged={collective.membership.privileged}
              />
            </div>
          )}

        <CollectiveSummaryCard />

        {(isLoggedIn || demoMode) && collective.membership?.approved && (
          <>
            <CollectiveParameters />

            <CollectiveLogList />

            <CollectiveWallets />

            <CollectiveAssets />

            <ProposalsTable
              proposals={proposals}
              title={'DAO Polls & Proposals'}
              emptyText={`No poll or proposals yet`}
              loading={loading || loadingMore}
              displaySearch
              searchString={searchString}
              onSearchStringChange={onSearchStringChange}
              clearSearchString={clearSearchString}
              hideExpandButtonOnMobile={false}
              containerClassName="bg-gray-50 p-4 shadow-none"
            >
              {!demoMode && pageInfo?.hasNextPage && searchString && (
                <div className="flex flex-row justify-center pt-5">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-blue px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                    onClick={loadMore}
                  >
                    Load more
                  </button>
                </div>
              )}
              {!demoMode && !searchString && (
                <div className="flex flex-row justify-end pt-2">
                  <Link
                    className="text-blue font-light text-sm"
                    href={`${collective.publicUrl}/proposals`}
                  >
                    {`view all >`}
                  </Link>
                </div>
              )}
              {demoMode && (
                <div className="flex flex-row justify-end pt-2">
                  <StyledTooltip arrow title={DemoTooltipText}>
                    <BoxHover className="text-blue font-light text-sm">{`view all >`}</BoxHover>
                  </StyledTooltip>
                </div>
              )}
            </ProposalsTable>
          </>
        )}
      </div>
    </ContentWrapper>
  )
}

export default CollectiveDetails
