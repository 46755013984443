import { useCollectiveContext } from 'context/CollectiveContext'
import {
  CollectiveLogEventType,
  useListCollectiveLogsQuery
} from 'graphql/generated'
import { useCallback } from 'react'

function useCollectiveLogs(
  collectiveId: string,
  types: CollectiveLogEventType[],
  search: string | undefined,
  limit: number
) {
  const { demoMode } = useCollectiveContext()
  const { data, loading, fetchMore, networkStatus, refetch } =
    useListCollectiveLogsQuery({
      variables: {
        collectiveId,
        where: {
          eventTypes: types.length ? types : undefined,
          searchString: search
        },
        requestConnection: { first: limit }
      },
      skip: demoMode
    })

  const loadingMore = networkStatus === 3

  const loadMore = useCallback(() => {
    if (
      !data?.collective?.logs?.pageInfo ||
      !data?.collective?.logs?.pageInfo?.hasNextPage
    ) {
      return
    } else if (loading || loadingMore) {
      return
    }

    fetchMore({
      variables: {
        collectiveId,
        requestConnection: {
          first: 50,
          after: data.collective.logs.pageInfo.endCursor
        }
      }
    })
  }, [
    collectiveId,
    data?.collective?.logs.pageInfo,
    fetchMore,
    loading,
    loadingMore
  ])

  return {
    logs: data?.collective?.logs.edges || [],
    loadMore,
    loading,
    loadingMore,
    pageInfo: data?.collective?.logs.pageInfo,
    refetch
  }
}

export default useCollectiveLogs
