import { useState } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'
import AssetsTable from './AssetsTable/AssetsTable'
import { Typography } from 'components/common'
import CollectiveDetailsSectionWrapper from './CollectiveDetailsSectionWrapper'

function CollectiveAssets() {
  const [show, setShow] = useState(false)
  const { collective } = useCollectiveContext()

  const assetsCount =
    collective.erc20Assets.length +
    collective.nftAssets.length +
    collective.externalAssets.length
  const hasAssets = assetsCount > 0

  return (
    <div className={'bg-gray-50 p-2 sm:p-4 rounded-md'}>
      <CollectiveDetailsSectionWrapper
        title="DAO Vault"
        description="Treasury and Linked Wallets Value."
        show={show}
        setShow={setShow}
        badge={assetsCount}
      />

      {show &&
        (!hasAssets ? (
          <Typography fontWeight="light" className="pt-[30px] text-center">
            This DAO has no assets yet. When the DAO owns shared items, they
            will appear here.
          </Typography>
        ) : (
          <AssetsTable />
        ))}
    </div>
  )
}

export default CollectiveAssets
