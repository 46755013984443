import {
  CollectiveSignatorsQueryResult,
  ProposalCommandType,
  useCollectiveMembersQuery
} from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import CollectiveUserSelectOption from '../../../Common/SelectComponents/CollectiveUserSelectOption'
import CollectiveUserSelectSingleValue from '../../../Common/SelectComponents/CollectiveUserSelectSingleValue'
import { StyledSelect } from 'components/common/Select'
import { getSearchableUser } from 'components/Collectives/Common/SelectComponents/common'

type TCollectiveUser = NonNullable<
  CollectiveSignatorsQueryResult['collective']
>['signators'][0]['user']

function AddSignatorCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const [signatorAddress, setSignatorAddress] = useState<string>(
    data.signatorAddress || ''
  )

  const { data: membersData } = useCollectiveMembersQuery({
    variables: {
      collectiveId: collective.id,
      memberConnection: {
        first: 5000
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const members = membersData?.collective?.members.edges
  const options = members?.map(x => x.node.user) || []

  useEffect(() => {
    onChange({
      type: ProposalCommandType.ADD_SIGNATOR,
      signatorAddress
    })
    // eslint-disable-next-line
  }, [signatorAddress])

  const isValid = useMemo(() => !!signatorAddress, [signatorAddress])
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        <StyledSelect<TCollectiveUser, false>
          height={50}
          options={options}
          value={options.find(x => x.address === signatorAddress)}
          placeholder={`Select Signator`}
          onChange={x => x && setSignatorAddress(x.address)}
          isDisabled={disabled}
          components={{
            Option: CollectiveUserSelectOption,
            SingleValue: CollectiveUserSelectSingleValue
          }}
          getOptionLabel={user =>
            getSearchableUser(user.platformUser, user.address)
          }
        />
      </div>
    </div>
  )
}

export default AddSignatorCommand
