import { ChangeEvent } from 'react'
import {
  Collective,
  useUploadCollectiveAvatarMutation
} from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { CollectiveQuery } from 'graphql/documents'
import useToasts from 'hooks/useToasts'

type TCollective = Pick<Collective, 'id'>
interface IProps {
  collective: TCollective
}

export function useUploadCollectiveThumbnail({ collective }: IProps) {
  const { addToast } = useToasts()
  const onError = useQueryErrorHandler(
    'Failed to save avatar. Please try again later.'
  )
  const [uploadAvatar] = useUploadCollectiveAvatarMutation({
    refetchQueries: [
      {
        query: CollectiveQuery,
        variables: {
          collectiveId: collective.id,
          proposalConnection: {
            first: 10
          }
        }
      }
    ],
    onError
  })

  const onFileSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    const origFiles = evt.target.files
    if (!origFiles || !origFiles.length) {
      return
    }

    const imageFile = Array.from(origFiles).find(x =>
      x.type.startsWith('image/')
    )
    if (!imageFile) {
      addToast('Selected file does not seem to be an image', {
        appearance: 'warning'
      })
      return
    }
    uploadAvatar({
      variables: {
        collectiveId: collective.id,
        file: imageFile
      }
    })
  }

  return { onFileSelect }
}
