import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from 'components/common'
import Button from 'components/Button'
import { QuestionCreationInput } from 'graphql/generated'
import { pullAt, without, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { newDefaultQuestion, SurveyQuestion } from './SurveyQuestion'

export interface IProps {
  questions: QuestionCreationInput[]
  onChange: CallbackWithParam<QuestionCreationInput[]>
  onValidityChange: CallbackWithParam<boolean>
}

export const SurveyQuestions: React.FC<IProps> = ({
  questions,
  onChange,
  onValidityChange
}) => {
  const [invalidIndices, setInvalidIndices] = useState<number[]>([])

  const areQuestionsValid = invalidIndices.length === 0 && questions.length > 0

  const foundAtleastOneNonRequiredQuestion =
    questions.length > 0 &&
    questions.some(question => question.options.required)

  useEffect(() => {
    onValidityChange(areQuestionsValid && foundAtleastOneNonRequiredQuestion)
  }, [areQuestionsValid, foundAtleastOneNonRequiredQuestion, onValidityChange])

  return (
    <div className="space-y-8">
      {questions.map((question, index) => (
        <div
          key={`command-${question.questionType}-${index}`}
          className="bg-gray-50 border-gray-100 border p-4 rounded-lg relative"
        >
          <Button
            icon={faTimes}
            onClick={() => {
              const currCopy = [...questions]
              pullAt(currCopy, [index])
              onChange(currCopy)
              setInvalidIndices(without(invalidIndices, index))
            }}
            disabled={questions.length === 1}
            rounded={'full'}
            className="absolute -top-4 -right-0"
            color={'white'}
          />

          <p className="text-sm font-light text-gray-800 pb-4">
            {`Question #${index + 1}`}:
          </p>

          <SurveyQuestion
            question={question}
            onChange={data => {
              const copy = [...questions]
              copy[index] = data
              onChange(copy)
            }}
            onValidityChange={valid => {
              if (valid) {
                setInvalidIndices(without(invalidIndices, index))
              } else {
                setInvalidIndices(uniq([...invalidIndices, index]))
              }
            }}
            makeRequired={questions.length === 1}
          />
        </div>
      ))}

      <Button
        label={'Add Another Question'}
        icon={faPlus}
        onClick={() => {
          // @ts-ignore
          onChange([...questions, ...newDefaultQuestion()])
          setInvalidIndices(uniq([...invalidIndices, questions.length]))
        }}
      />

      {!!questions.length && !foundAtleastOneNonRequiredQuestion && (
        <div className="bg-red-100 px-2 py-1 rounded-md text-center text-xs">
          <Typography fontWeight="light">
            {`At least one question must be set to required`}
          </Typography>
        </div>
      )}
    </div>
  )
}
