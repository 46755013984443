import { VoteDelegation } from '@upstreamapp/upstream-dao'
import { DelegationGrantedItem } from './DelegationGrantedItem'

export function DelegationsGrantedList({
  onChange,
  delegations
}: {
  delegations: VoteDelegation.DelegationStruct[]
  onChange: CallbackWithParam<VoteDelegation.DelegationStruct[]>
}) {
  return (
    <div
      className={
        'max-h-400 w-full overflow-auto border border-gray-300 rounded-md '
      }
    >
      {delegations.map(delegation => (
        <div
          className={'border-b border-gray-300 last:border-b-0'}
          key={delegation.command}
        >
          <DelegationGrantedItem
            value={delegation}
            onChange={item => {
              const currCopy = [...delegations]

              const currentItem: VoteDelegation.DelegationStruct | undefined =
                currCopy.find(
                  copyDelegation => copyDelegation.command === item?.command
                )
              if (currentItem) {
                currentItem.delegatee = item?.delegatee || ''
                currentItem.maxValue = item?.maxValue || BigInt(0)
              }

              onChange(currCopy)
            }}
          />
        </div>
      ))}
    </div>
  )
}
