import { uniqBy } from 'lodash'
import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnWalletUnlinked: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnWalletUnlinked'>
> = ({ bundledCollectiveLog, isLast }) => {
  const uniqueWallets = uniqBy(
    bundledCollectiveLog.logs,
    log => log.data.walletId
  )
  const hasMany = uniqueWallets.length === 1
  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={
        <>
          {!hasMany ? `Wallet ` : `${uniqueWallets.length} Wallets `}
          {!hasMany && (
            <LogEtherscanTxLink
              txHash={bundledCollectiveLog.logs[0].data.walletId}
              type="address"
            />
          )}
          {` ${hasMany ? 'were' : 'was'} unlinked`}
        </>
      }
      hideSummaryActors
      detailsActionText={log => (
        <>
          {`Wallet `}
          <LogEtherscanTxLink txHash={log.data.walletId} type="address" />
          {` was unlinked from DAO`}
        </>
      )}
    />
  )
}

export default CollectiveLogDataOnWalletUnlinked
