import TextField, {
  IProps as TextFieldProps
} from 'components/common/TextField'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { utils } from 'ethers'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { useWeb3 } from 'context/Web3Context'
import {
  faCheckCircle,
  faCircle,
  faTimesCircle
} from '@fortawesome/pro-regular-svg-icons'

interface IProps extends Omit<TextFieldProps, 'onChange'> {
  defaultValue?: string
  onChange: CallbackWithParam<string>
  onValidityChange?: CallbackWithParam<boolean>
  onBlur?: Callback
  placeholder?: string
  disableUnderline?: boolean
  disableLabel?: boolean
}

export interface IMethods {
  clear: Callback
}

export function isValidEthAddress(
  address: string,
  validDemoAddress?: Set<String>
) {
  try {
    if (!address) {
      return false
    } else if (validDemoAddress && validDemoAddress.has(address)) {
      return true
    }

    utils.getAddress(address)
    return true
  } catch (ex) {
    return false
  }
}

const WalletAddressInputField = forwardRef<IMethods, IProps>(
  (
    {
      defaultValue,
      onValidityChange,
      onChange,
      onBlur,
      placeholder = 'Send to Address',
      disableLabel,
      ...rest
    },
    ref
  ) => {
    const { web3 } = useWeb3()
    const [value, setValue] = useState<string>(defaultValue || '')
    const [error, setError] = useState<Maybe<string>>()
    const { validDemoAddress } = useCollectiveDemoContext()

    useEffect(() => {
      onValidityChange?.(isValidEthAddress(value, validDemoAddress) && !error)
    }, [onValidityChange, value, error, validDemoAddress])

    const validateValue = useCallback(
      async (value: string) => {
        if (!value) {
          setError(null)
          return
        } else if (value.endsWith('.eth') && !!web3) {
          const resolvedAddress = await web3.resolveName(value)
          if (resolvedAddress) {
            setValue(resolvedAddress)
            setError(null)
          } else {
            setError('Could not resolve ENS address')
          }
          return
        }

        setError(
          isValidEthAddress(value, validDemoAddress) ? null : `Invalid address`
        )
      },
      [validDemoAddress, web3]
    )

    const onChangeHandler = stringValue => {
      setValue(stringValue.trim())
      setError(null)
    }

    const onBlurHandler = () => {
      validateValue(value)
      onBlur?.()
    }

    useEffect(() => {
      onChange(value)
    }, [onChange, value])

    useImperativeHandle(ref, () => ({
      clear() {
        setValue('')
        setError(null)
      }
    }))

    return (
      <TextField
        horizantalFullWidth
        autoFocus
        label={disableLabel ? undefined : placeholder}
        placeholder={
          placeholder ?? '0x0000000000000000000000000000000000000000'
        }
        value={value}
        onChange={e => onChangeHandler(e.target.value)}
        onPaste={e => {
          e.preventDefault()
          // @ts-ignore
          const data = (e.clipboardData || window.clipboardData).getData('text')
          validateValue(data)
          onChangeHandler(data)
        }}
        onBlur={onBlurHandler}
        icon={
          error || rest.error
            ? faTimesCircle
            : isValidEthAddress(value)
            ? faCheckCircle
            : faCircle
        }
        iconDivider
        iconClassName={
          error || rest.error
            ? 'text-red-500'
            : isValidEthAddress(value)
            ? 'text-green-500'
            : 'text-gray-200'
        }
        error={error || rest.error || undefined}
        {...rest}
      />
    )
  }
)

WalletAddressInputField.displayName = 'WalletAddressInputField'

export default WalletAddressInputField
