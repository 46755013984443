import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalSigned: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnProposalSigned'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      isLast={isLast}
      bundle={bundledCollectiveLog}
      summaryActionText="signed"
      singleProposalText={' proposal'}
      detailsActionText={() => 'signed'}
      summaryManyProposalText={count => ` ${count} proposals`}
    />
  )
}

export default CollectiveLogDataOnProposalSigned
