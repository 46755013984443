import React, { cloneElement, useMemo } from 'react'
import { IProps as IActionButtonProps } from './ActionButton'

interface IProps {
  children: JSX.Element | JSX.Element[]
}

function ActionButtonGroup({ children }: IProps) {
  const memoizedChildren = useMemo(() => {
    const childrenArray = React.Children.toArray(children)
    return React.Children.map(childrenArray, (child, index) =>
      React.isValidElement(child)
        ? cloneElement(child as React.ReactElement<IActionButtonProps>, {
            first: index === 0,
            last: index === childrenArray.length - 1
          })
        : null
    )
  }, [children])

  return (
    <div className="flex flex-row shadow rounded-xl my-1">
      {memoizedChildren}
    </div>
  )
}

export default ActionButtonGroup
