import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import { QuestionChoiceInput, QuestionCreationInput } from 'graphql/generated'
import { without } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import ProposalDurationSelector from '../AddProposalModal/ProposalDurationSelector'
import TextField from 'components/common/TextField'
import { Typography } from 'components/common'

interface IProps {
  question: QuestionCreationInput
  onValidityChange: CallbackWithParam<boolean>
  onChange: CallbackWithParam<QuestionCreationInput>
  durationInHours: number
  setDurationInHours: CallbackWithParam<number>
}

export const QuickSurveyForm: React.FC<IProps> = ({
  onValidityChange,
  question,
  onChange,
  durationInHours,
  setDurationInHours
}) => {
  useEffect(() => {
    if (!question.options.required) {
      const newQuestionData = { ...question }
      newQuestionData.options.required = true
      onChange(newQuestionData)
    }
  }, [onChange, question])

  const { duplicateChoices, missingValues } = useMemo(() => {
    const duplicateChoices = question.choices?.length
      ? question.choices.reduce((val, choice, idx) => {
          if (!choice.stringChoice?.trim().length) {
            return val
          }
          const duplicate = question.choices?.some(
            (choice2, idx2) =>
              choice.stringChoice === choice2.stringChoice && idx > idx2
          )
          if (duplicate) {
            return [...val, idx]
          }
          return val
        }, [])
      : []

    const missingValues = question.choices?.length
      ? question.choices?.reduce((val, choice, idx) => {
          const length = choice.stringChoice?.trim().length
          if (!length) {
            return [...val, idx]
          }
          return val
        }, [])
      : []

    return {
      duplicateChoices,
      missingValues
    }
  }, [question])

  useEffect(() => {
    // no question or choices
    if (
      !question.question.length ||
      !question.choices?.length ||
      question.choices.length <= 1
    ) {
      onValidityChange(false)
      return
    }

    if (!!missingValues.length || !!duplicateChoices.length) {
      onValidityChange(false)
      return
    }

    onValidityChange(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateChoices.length, missingValues.length, question])

  // always update data
  const onStringChoiceChange = useCallback(
    (value: string, index: number) => {
      if (question.choices?.[index]) {
        const newQuestionData = { ...question }
        question.choices[index].stringChoice = value
        onChange(newQuestionData)
      }
    },
    [onChange, question]
  )

  return (
    <div className="flex-1 pb-4 overflow-auto">
      <div className="space-y-2 flex-1 bg-gray-50 p-4 mb-4 rounded-lg border border-gray-100">
        <p className="text-sm text-gray-500 font-light uppercase">Question</p>

        <TextField
          multiple
          horizantalFullWidth
          autoFocus
          label={'Ask a question...'}
          required
          value={question.question}
          onChange={e => {
            const newQuestionData = { ...question }
            newQuestionData.question = e.target.value
            onChange(newQuestionData)
          }}
        />

        <p className="text-sm text-gray-500 font-light uppercase pt-2">
          Choices
        </p>

        {question.choices?.map((choice: QuestionChoiceInput, i) => (
          <div
            key={`choice_${i}`}
            className="rounded-lg flex gap-2.5 items-center"
          >
            <Typography size="sm">{`${i + 1}.`}</Typography>

            <TextField
              horizantalFullWidth
              placeholder={`Choice ${i + 1}`}
              value={choice.stringChoice || undefined}
              onChange={e => onStringChoiceChange(e.target.value, i)}
              required
              error={
                duplicateChoices.includes(i) && 'This option already exists'
              }
              postContent={
                <Button
                  disabled={question.choices?.length === 1}
                  icon={faTimes}
                  color="white"
                  size="sm"
                  onClick={() => {
                    const newArr = without(question.choices, choice)
                    const newQuestionData = { ...question }
                    newQuestionData.choices = newArr
                    onChange(newQuestionData)
                  }}
                />
              }
            />
          </div>
        ))}

        <div className="flex justify-between pt-2 flex-col gap-4">
          <div className="flex items-center space-x-2 justify-between">
            <p className="text-sm text-gray-500 font-light uppercase">
              Duration
            </p>
            <ProposalDurationSelector
              value={durationInHours}
              onChange={setDurationInHours}
            />
          </div>

          <Button
            icon={faPlus}
            color={'lightblue'}
            label="Add an Option"
            className="max-sm:w-full"
            onClick={() => {
              const newQuestionData = { ...question }
              newQuestionData.choices?.push({ stringChoice: '' })
              onChange(newQuestionData)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default QuickSurveyForm
