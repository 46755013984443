import StyledTooltip from 'components/Tooltip/Tooltip'
import Link from 'next/link'
import {
  CollectiveQueryResult,
  CollectiveWallet,
  ERCAsset
} from 'graphql/generated'
import { BLACKHOLE } from 'hooks/collectives/useExecuteProposal'
import { getEtherscanUrl } from 'lib/collectives/helpers'

interface IProps {
  asset: Pick<
    ERCAsset,
    'name' | 'balance' | 'estimatedValueEth' | 'estimatedValueUSD' | 'address'
  > & { wallet?: Maybe<Pick<CollectiveWallet, 'id' | 'isMainWallet'>> }
  collective: Pick<
    NonNullable<CollectiveQueryResult['collective']>,
    'network' | 'wallets'
  >
  className: string
}

function HeldInHotWalletNotice({ asset, collective, className }: IProps) {
  const wallet = collective.wallets.find(x => x.id === asset.wallet?.id)
  const label = wallet ? wallet.nickname || wallet.address : `hot wallet`
  const address = wallet?.address || BLACKHOLE
  const url = getEtherscanUrl(collective.network, 'address', address)

  return (
    <Link target="_blank" href={url}>
      <StyledTooltip arrow title={`Open ${address} in Etherscan`}>
        <span className={className}>{`Held in ${label}`}</span>
      </StyledTooltip>
    </Link>
  )
}

export default HeldInHotWalletNotice
