import { UpstreamCollectiveToken__factory } from '@upstreamapp/upstream-dao'
import { CollectiveQueryResult } from 'graphql/generated'
import { useWeb3 } from 'context/Web3Context'
import { useMemo } from 'react'
import { BigNumber } from 'lib/BigInt'
import { TTxUiState } from 'lib/collectives/helpers'

type TCollective = Pick<
  NonNullable<CollectiveQueryResult['collective']>,
  'id' | 'address' | 'tokens'
>

function useWithdrawEther({
  collective,
  demoMode = false
}: {
  collective: TCollective
  demoMode?: boolean
}) {
  const { signer, signerAddress } = useWeb3()

  const dao = useMemo(
    () =>
      signer
        ? UpstreamCollectiveToken__factory.connect(collective.address, signer)
        : undefined,
    [signer, collective.address]
  )

  const withdraw: Web3Action<{ tokenAmount: BigNumber } & IWeb3ActionOptions> =
    async function ({ tokenAmount, onCall }) {
      if ((!dao || !signerAddress) && !demoMode) {
        throw new Error(`Please sign in with Web3 wallet to continue`)
      }

      if (!dao || !signerAddress) {
        throw 'No dao found'
      }

      const allowance = new BigNumber(
        (await dao.allowance(signerAddress, collective.address)).toString()
      )
      if (allowance.lt(tokenAmount)) {
        const approveTransaction = await dao.approve(
          collective.address,
          tokenAmount.toString()
        )
        onCall(TTxUiState.WAITING_FOR_APPROVAL)
        await approveTransaction.wait()
      }

      const transaction = await dao.withdraw(tokenAmount.toString())
      onCall(TTxUiState.WAITING_FOR_NETWORK)
      const { status: txStatus } = await transaction.wait()

      return { txStatus }
    }

  return {
    withdraw
  }
}

export default useWithdrawEther
