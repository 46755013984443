import EtherInputField from 'components/Collectives/Common/EtherInputField'
import { BigNumber } from 'lib/BigInt'
import { ERCAsset, ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import CollectiveAssetSelectOption from '../../../Common/SelectComponents/CollectiveAssetSelectOption'
import CollectiveAssetSelectSingleValue from '../../../Common/SelectComponents/CollectiveAssetSelectSingleValue'
import WalletAddressInputField from '../../../Common/WalletAddressInputField'
import { ONE_BN, ZERO_BN } from 'lib/collectives/helpers'
import { StyledSelect } from 'components/common/Select'
import useCollectiveAssets from 'hooks/collectives/useCollectiveAssets'
import { useDebouncedCallback } from 'use-debounce'
import { useControlledInput } from 'hooks'
import { WalletFilter } from '../../../CollectiveDetails/AssetsTable/AssetsTable'

function TransferAssetCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const [selectedAsset, setSelectedAsset] = useState<Maybe<ERCAsset>>(null)
  const [contractAddress, setContractAddress] = useState<Maybe<string>>(
    data.contractAddress || null
  )
  const [tokenId, setTokenId] = useState<Maybe<string>>(data.tokenId || null)
  const [target, setTarget] = useState<string>(data.target || '')
  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    data.value ? new BigNumber(data.value) : ZERO_BN
  )

  const [search, setSearch] = useState<string>()
  const [searchValue, onSearchValueChange] = useControlledInput()
  const onSearchValueChangeDebounced = useDebouncedCallback(e => {
    setSearch(e)
  }, 300)

  const { assets, loading, loadingMore, loadMore } = useCollectiveAssets({
    collectiveId: collective.id,
    search,
    walletFilter: WalletFilter.MAIN
  })

  useEffect(() => {
    onSearchValueChangeDebounced(searchValue)
  }, [onSearchValueChange, onSearchValueChangeDebounced, searchValue])

  const options = useMemo(
    () => [
      ...(assets
        .filter(edge => edge.node.__typename === 'ERCAsset')
        .filter(edge => !!edge.node)
        .map(edge => edge.node) as ERCAsset[])
    ],
    [assets]
  )

  const contractType = selectedAsset?.contractType
  const showValueInput = contractType === 'ERC20'
  const showAmountInput = contractType === 'ERC1155'
  const maxAmountToSend = useMemo(
    () => new BigNumber(selectedAsset?.balance || new BigNumber(0)),
    [selectedAsset]
  )

  useEffect(() => {
    function getCommandType() {
      switch (contractType) {
        case 'ERC20':
          return ProposalCommandType.TRANSFER_TOKENS
        case 'ERC721':
          return ProposalCommandType.TRANSFER_ERC721
        case 'ERC1155':
          return ProposalCommandType.TRANSFER_ERC1155
        default:
          return ProposalCommandType.TRANSFER_ERC721
      }
    }

    onChange({
      type: getCommandType(),
      contractAddress,
      tokenId: contractType === 'ERC20' ? undefined : tokenId,
      value:
        contractType === 'ERC20'
          ? new BigNumber(amountInWei).toString()
          : undefined,
      amount:
        contractType === 'ERC1155'
          ? new BigNumber(amountInWei).toString()
          : undefined,
      target
    })
    // eslint-disable-next-line
  }, [contractType, contractAddress, tokenId, amountInWei, target])

  const [isAddressValid, setIsAddresValid] = useState(false)
  const isValid = useMemo(() => {
    const commonValidity =
      isAddressValid && !!contractAddress && !!target.trim()
    const erc20Validity = amountInWei.gt(0)
    const nftValidity = !!tokenId

    return (
      commonValidity &&
      ((contractType === 'ERC20' && erc20Validity) ||
        (contractType === 'ERC721' && nftValidity) ||
        (contractType === 'ERC1155' && nftValidity))
    )
  }, [
    isAddressValid,
    contractAddress,
    target,
    tokenId,
    amountInWei,
    contractType
  ])
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        <StyledSelect<ERCAsset, false>
          height={50}
          options={options}
          value={selectedAsset}
          placeholder={`Select Asset`}
          onChange={x => {
            if (!x) {
              return
            }

            setSelectedAsset(x)
            setContractAddress(x.address)
            setTokenId(
              x.contractType === 'ERC721' || x.contractType === 'ERC1155'
                ? x.tokenId
                : null
            )
          }}
          isDisabled={disabled}
          components={{
            Option: CollectiveAssetSelectOption,
            SingleValue: CollectiveAssetSelectSingleValue
          }}
          filterOption={() => true}
          loadMore={loadMore}
          inputValue={searchValue}
          isLoading={loading || loadingMore}
          onInputChange={newVal => onSearchValueChange(newVal)}
        />
      </div>

      <div className="pt-4">
        <WalletAddressInputField
          onChange={setTarget}
          disabled={disabled}
          onValidityChange={setIsAddresValid}
        />
      </div>

      {showValueInput && (
        <div className="pt-4">
          <EtherInputField
            disabled={disabled}
            label={`Amount`}
            initialValueInWei={amountInWei}
            onChangeInWei={setAmountInWei}
            decimals={
              selectedAsset?.contractType === 'ERC20'
                ? selectedAsset.decimals || 0
                : undefined
            }
            minWei={ONE_BN}
            maxWei={maxAmountToSend}
            symbol={selectedAsset?.symbol || ''}
            type={'TOKEN'}
          />
        </div>
      )}

      {showAmountInput && (
        <div className="pt-4">
          <EtherInputField
            formatUnits={false}
            label={`Amount`}
            disabled={disabled}
            initialValueInWei={amountInWei}
            onChangeInWei={setAmountInWei}
            minWei={ONE_BN}
            maxWei={maxAmountToSend}
            symbol={selectedAsset?.symbol || ''}
            type={'TOKEN'}
          />
        </div>
      )}
    </div>
  )
}

export default TransferAssetCommand
