import dynamic from 'next/dynamic'
import { useCollectiveContext } from 'context/CollectiveContext'
import { ProposalCommandInput } from 'graphql/generated'
import MembershipRequiredModal from '../MembershipRequiredModal'
import Spinner from 'components/Spinner'
import CreateProposalForm from 'components/Collectives/CollectiveProposals/CreateProposal/CreateProposalForm'
import { CreateProposalProvider } from 'components/Collectives/CollectiveProposals/CreateProposal/CreateProposalContext'
import React from 'react'
import BaseModal from 'components/modals/BaseModal'
import Web3ActionModal from '../Web3ActionModal'

export const QuillNoSSRWrapper = dynamic(
  () => import('components/QuillNoSSR'),
  {
    ssr: false,
    // eslint-disable-next-line react/display-name
    loading: () => <Spinner />
  }
)

interface IProps {
  commands?: ProposalCommandInput[]
  onClose?: Callback
}

export const AddProposalModal: React.FC<IProps> = ({ onClose, commands }) => {
  const { collective, demoMode } = useCollectiveContext()

  return (
    <MembershipRequiredModal
      collective={collective}
      requiredRole={'approved'}
      onClose={onClose}
    >
      <Web3ActionModal
        title={'Create a New Proposal'}
        state={undefined}
        onClose={onClose}
        hideButtons={true}
        guardOnly={true}
      >
        <CreateProposalProvider
          demoMode={demoMode}
          commands={commands}
          onClose={onClose}
        >
          <BaseModal
            classNames="md:min-w-md"
            onClose={onClose}
            disableBackdropClick
          >
            <CreateProposalForm />
          </BaseModal>
        </CreateProposalProvider>
      </Web3ActionModal>
    </MembershipRequiredModal>
  )
}
