import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnMemberRemoved: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnMemberRemoved'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`${
      bundledCollectiveLog.collectiveUsers.length > 1
        ? 'members were'
        : 'member was'
    } removed from the DAO`}
    hideDetailActors
    detailsActionText={log => (
      <>
        {`Wallet `}
        <LogEtherscanTxLink txHash={log.data.targetMember} type="address" />
        {` was removed as a member`}
      </>
    )}
  />
)

export default CollectiveLogDataOnMemberRemoved
