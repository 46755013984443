import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { faCoins, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { BigNumber } from 'lib/BigInt'
import { ExternalAsset, CollectiveWallet } from 'graphql/generated'
import listItemClasses from './useListItemStyles'
import { BaseListItem } from './BaseListItem'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
  asset: Pick<
    ExternalAsset,
    | 'id'
    | 'thumb'
    | 'type'
    | 'formattedAmount'
    | 'symbol'
    | 'estimatedValueUSD'
    | 'externalUrl'
  > & { wallet?: Maybe<Pick<CollectiveWallet, 'id' | 'isMainWallet'>> }
}

function ExternalAssetListItem({ asset }: IProps) {
  const [isError, setIsError] = useState(!asset.thumb)
  const [isLoading, setIsLoading] = useState(!isError)

  return (
    <BaseListItem
      isError={isError}
      isLoading={isLoading}
      errorIcon={faCoins}
      topContent={
        asset.thumb &&
        !isError && (
          <img
            className={twMerge(
              listItemClasses.mediaImage,
              'object-cover hover:object-contain',
              isError && 'object-contain'
            )}
            src={asset.thumb}
            onLoad={() => {
              setIsLoading(false)
            }}
            onError={({ currentTarget }) => {
              setIsError(true)
              setIsLoading(false)
              currentTarget.onerror = null // prevents looping
            }}
          />
        )
      }
      bottomContent={
        <>
          <div className="flex justify-between items-center">
            <p className={listItemClasses.nameText}>{asset.type}</p>

            <DropdownMenu
              buttonClassName="-mr-1"
              items={[
                !!asset.externalUrl && {
                  label: 'View on Project Website',
                  icon: faExternalLink,
                  href: asset.externalUrl,
                  target: '_blank'
                }
              ]}
            />
          </div>

          <div className="flex justify-between">
            <div>
              <p className={listItemClasses.amountText}>
                {asset.formattedAmount} {asset.symbol}
              </p>

              <p className={listItemClasses.estimatedValueUSDText}>
                {asset.estimatedValueUSD
                  ? `(~$${new BigNumber(asset.estimatedValueUSD).toFormat(2)})`
                  : ``}
              </p>
            </div>

            <div />
          </div>
        </>
      }
    />
  )
}

export default ExternalAssetListItem
