import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnAddedOwner: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnAddedOwner'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={
      bundledCollectiveLog.logs.length > 1
        ? `signators were added`
        : `was added as signator`
    }
    hideDetailActors
    detailsActionText={log => (
      <>
        {`Signator `}
        <LogEtherscanTxLink txHash={log.data.owner} type="address" />
        {` was added `}
      </>
    )}
  />
)

export default CollectiveLogDataOnAddedOwner
