import { ProposalCommandType } from 'graphql/generated'
import {
  getCommandDescription,
  IProposalCommandInputProps
} from './ProposalCommand.types'
import { useEffect } from 'react'

function RemoveZeroBalanceMembersCommand({
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  useEffect(() => {
    onChange({
      type: ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS
    })
    // eslint-disable-next-line
  }, [])

  const isValid = true
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="flex pt-4 justify-center items-center">
        <p className="text-gray-700 font-light text-sm">
          {getCommandDescription(
            ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS,
            collective
          )}
        </p>
      </div>
    </div>
  )
}

export default RemoveZeroBalanceMembersCommand
