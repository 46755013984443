import React, { useCallback, useMemo, useState } from 'react'
import ProposalCommand, {
  IProposalCommand
} from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand'
import { ProposalCommandInput, ProposalCommandType } from 'graphql/generated'
import Button from 'components/Button'
import CommandSummaryTable from 'components/Collectives/CollectiveProposals/CreateProposal/CommandSummaryTable'
import {
  faArrowAltCircleRight,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'
import { useCreateProposalContext } from './CreateProposalContext'
import {
  COMMAND_OPTIONS,
  getCommandLabelByComponent
} from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import { useCollectiveContext } from 'context/CollectiveContext'
import Typography from 'components/common/Typography'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProposalStepsContainer } from './common'

type IProposalCommandsStep = Pick<IProposalCommand, 'collective'>

const ProposalCommandsStep: React.FC<IProposalCommandsStep> = ({
  collective
}) => {
  const { commands, component, goBack, goForward, setCommands } =
    useCreateProposalContext()
  const { demoMode } = useCollectiveContext()
  const option = component
    ? getCommandLabelByComponent(component, collective)
    : undefined
  const [editMode, setEditMode] = useState(!!component)
  const [command, setCommand] = useState<ProposalCommandInput>({
    type: ProposalCommandType.ADD_MEMBER
  })

  const [isValid, setIsValid] = useState(false)
  const handleChange = useCallback(
    (input: ProposalCommandInput) => {
      setCommand({ ...command, ...input })
    },
    [command]
  )

  const confirmSelection = useCallback(() => {
    setCommands([...commands, command])
    setEditMode(false)
  }, [command, commands, setCommands])

  const itemIsDuplicateError = useMemo(() => {
    if (!command) {
      return null
    }
    const option = COMMAND_OPTIONS.find(commandOption =>
      commandOption.renderForTypes.includes(command.type)
    )
    return option?.disableAddCommandOnDuplicateDataCheck?.(commands, command)
  }, [command, commands])

  return (
    <ProposalStepsContainer
      buttons={
        <>
          <Button
            label={'Back'}
            color="lightgray"
            onClick={() => goBack(true)}
          />
          <Button
            label="Next"
            className="ml-2"
            disabled={editMode}
            onClick={() => goForward(false)}
          />
        </>
      }
    >
      {editMode && component ? (
        <>
          {option && <Typography>{option}</Typography>}
          <ProposalCommand
            collective={collective}
            data={command}
            component={component}
            onChange={handleChange}
            onValidityChange={isSelectionValid => {
              setIsValid(isSelectionValid)
            }}
          />

          {!!itemIsDuplicateError && (
            <div className="flex w-full justify-end p-1 space-x-1 items-center">
              <Typography
                color={itemIsDuplicateError[1] ? 'error' : 'warning'}
                size="sm"
                component={'span'}
              >
                {itemIsDuplicateError[0]}{' '}
              </Typography>
              <StyledTooltip title="See all added commands" arrow>
                <span>
                  <Typography
                    color="gray"
                    className="cursor-pointer"
                    onClick={() => setEditMode(false)}
                  >
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </Typography>
                </span>
              </StyledTooltip>
            </div>
          )}
          <Button
            label="Enter"
            onClick={confirmSelection}
            size="lg"
            className="mt-4 w-full"
            disabled={!isValid || itemIsDuplicateError?.[1]}
          />
        </>
      ) : (
        <>
          <CommandSummaryTable
            commandsInput={commands}
            collective={collective}
          />

          <StyledTooltip
            title={demoMode && commands.length !== 0 ? DemoTooltipText : ''}
            arrow
          >
            <span>
              <Button
                icon={faPlus}
                iconPlacement="before"
                label="Add Command"
                onClick={() => goBack(true)}
                size="lg"
                color="transparent"
                className="my-6"
                disabled={demoMode && commands.length !== 0}
              />
            </span>
          </StyledTooltip>
        </>
      )}
    </ProposalStepsContainer>
  )
}

export default ProposalCommandsStep
