import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnAnnouncementEdited: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnAnnouncementChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`edited 
          ${
            bundledCollectiveLog.logs.length > 1
              ? `${bundledCollectiveLog.logs.length} announcements`
              : `an announcement`
          }`}
    detailsActionText={log =>
      `edited an announcement: "${log.data.announcement.bodyHtml?.replace(
        /(<([^>]+)>)/gi,
        ''
      )}"`
    }
  />
)

export default CollectiveLogDataOnAnnouncementEdited
