import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import Button from 'components/Button'
import { Toggle, Typography } from 'components/common'
import FieldLabel from 'components/common/FieldLabel'
import { StyledSelect } from 'components/common/Select'
import TextField from 'components/common/TextField'
import {
  QuestionChoiceInput,
  QuestionCreationInput,
  QuestionType
} from 'graphql/generated'
import { without } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { components } from 'react-select'

interface IOptionType {
  type: QuestionType
  label: string
  description: string
  allowMultipleOptions: boolean
}

const availableQuestions: IOptionType[] = [
  {
    type: QuestionType.MULTIPLE_CHOICE,
    label: 'Single Select',
    description: 'Participant can only pick one choice',
    allowMultipleOptions: true
  },
  {
    type: QuestionType.CHECKBOX,
    label: 'Multi-Select',
    description: 'Participant can pick multiple items',
    allowMultipleOptions: true
  },
  {
    type: QuestionType.DATETIME,
    label: 'Date',
    description: 'Participant can pick a date',
    allowMultipleOptions: false
  }
]

export const newDefaultQuestion = (): QuestionCreationInput[] => {
  return [
    {
      questionType: QuestionType.MULTIPLE_CHOICE,
      question: '',
      options: { required: false },
      choices: [{ stringChoice: '' }, { stringChoice: '' }]
    }
  ]
}

type TSurveyQuestionType = {
  question: QuestionCreationInput
  onValidityChange: CallbackWithParam<boolean>
  onChange: CallbackWithParam<QuestionCreationInput>
  makeRequired: boolean
}

export const SurveyQuestion: React.FC<TSurveyQuestionType> = ({
  onValidityChange,
  question,
  onChange,
  makeRequired
}) => {
  const updateQuestionType = useCallback(
    (option: IOptionType) => {
      const newQuestionData = { ...question }
      newQuestionData.questionType = option.type

      if (option.type !== QuestionType.DATETIME) {
        newQuestionData.choices =
          question.choices?.length &&
          question.choices.some(choice => choice.stringChoice?.length) // use existing
            ? question.choices
            : [{ stringChoice: '' }, { stringChoice: '' }]
      } else {
        newQuestionData.choices = undefined
      }
      onChange(newQuestionData)
    },
    [onChange, question]
  )

  useEffect(() => {
    if (makeRequired && !question.options.required) {
      const newQuestionData = { ...question }
      newQuestionData.options.required = true
      onChange(newQuestionData)
    }
  }, [makeRequired, onChange, question])

  const { duplicateChoices, missingValues } = useMemo(() => {
    return {
      duplicateChoices: question.choices?.length
        ? question.choices.reduce((val, choice, idx) => {
            if (!choice.stringChoice?.trim().length) {
              return val
            }
            const duplicate = question.choices?.some(
              (choice2, idx2) =>
                choice.stringChoice === choice2.stringChoice && idx > idx2
            )
            if (duplicate) {
              return [...val, idx]
            }
            return val
          }, [])
        : [],
      missingValues: question.choices?.length
        ? question.choices?.reduce((val, choice, idx) => {
            const length = choice.stringChoice?.trim().length
            if (!length) {
              return [...val, idx]
            }
            return val
          }, [])
        : []
    }
  }, [question])

  const questionType = availableQuestions.find(
    option => option.type === question.questionType
  )
  const dateType = question.questionType === QuestionType.DATETIME
  const checkboxType = question.questionType === QuestionType.CHECKBOX

  //update validity
  useEffect(() => {
    if (
      !question.question.length ||
      (!dateType &&
        (!question.choices?.length || // must have atleast one choice
          (!checkboxType && question.choices.length <= 1))) // must have more than one for single select
    ) {
      onValidityChange(false)
      return
    }

    if (!dateType && (!!missingValues.length || !!duplicateChoices.length)) {
      onValidityChange(false)
      return
    }

    onValidityChange(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkboxType,
    dateType,
    duplicateChoices.length,
    missingValues.length,
    question
  ])

  // always update data
  const onStringChoiceChange = useCallback(
    (value: string, index: number) => {
      if (question.choices?.[index]) {
        const newQuestionData = { ...question }
        question.choices[index].stringChoice = value
        onChange(newQuestionData)
      }
    },
    [onChange, question]
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col w-full">
          <FieldLabel label="Question Type:" />
          <StyledSelect<IOptionType, false>
            height={50}
            options={availableQuestions}
            value={questionType}
            placeholder={`Select Question Type`}
            onChange={v => v && updateQuestionType(v)}
            components={{
              Option: props => (
                <components.Option {...props} isSelected={false}>
                  <Typography size="sm">{props.data.label}</Typography>
                  <Typography size="sm" color="gray">
                    {props.data.description}
                  </Typography>
                </components.Option>
              ),
              SingleValue: props => (
                <components.SingleValue {...props}>
                  <Typography size="sm">{props.data.label}</Typography>
                  <Typography size="sm" color="gray">
                    {props.data.description}
                  </Typography>
                </components.SingleValue>
              )
            }}
          />
        </div>

        <div className="flex flex-col w-full">
          <div className="bg-white">
            <TextField
              multiple
              horizantalFullWidth
              label="Question: "
              placeholder={'Where should we host our next event?'}
              required
              value={question.question}
              onChange={e => {
                const newQuestionData = { ...question }
                newQuestionData.question = e.target.value
                onChange(newQuestionData)
              }}
            />
          </div>
        </div>
      </div>

      <div>
        {!dateType && <FieldLabel label="Options: " />}

        {!dateType &&
          question.choices?.map((choice: QuestionChoiceInput, i) => {
            return (
              <div
                className="my-2 rounded-lg flex gap-2 items-center"
                key={`choice_${i}`}
              >
                <Typography size="xs">{`${i + 1}.`}</Typography>
                <TextField
                  horizantalFullWidth
                  placeholder={`Option ${i + 1}`}
                  value={choice.stringChoice || undefined}
                  onChange={e => onStringChoiceChange(e.target.value, i)}
                  required
                  error={
                    duplicateChoices.includes(i) && 'This option already exists'
                  }
                  postContent={
                    <Button
                      disabled={question.choices?.length === 1}
                      icon={faTimes}
                      color="white"
                      size="sm"
                      onClick={() => {
                        const newArr = without(question.choices, choice)
                        const newQuestionData = { ...question }
                        newQuestionData.choices = newArr
                        onChange(newQuestionData)
                      }}
                    />
                  }
                />
              </div>
            )
          })}
      </div>

      <div className={classNames('ml-3 flex', 'justify-between')}>
        <Toggle
          onChange={checked => {
            const newQuestionData = { ...question }
            newQuestionData.options.required = checked
            onChange(newQuestionData)
          }}
          checked={question.options.required}
          disabled={makeRequired}
          className={question.options.required ? 'text-white' : undefined}
          label={`Required`}
        />

        {questionType?.allowMultipleOptions && (
          <Button
            label="Add an Option"
            onClick={() => {
              const newQuestionData = { ...question }
              newQuestionData.choices?.push({ stringChoice: '' })
              onChange(newQuestionData)
            }}
          />
        )}
      </div>
    </div>
  )
}
