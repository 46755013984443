import { Typography } from 'components/common'
import Thumbnail from 'components/Thumbnail'
import { CollectiveSignatorsQueryResult } from 'graphql/generated'
import { OptionProps, components } from 'react-select'

type TCollectiveUser = NonNullable<
  CollectiveSignatorsQueryResult['collective']
>['signators'][0]['user']

const CollectiveUserSelectOption = ({
  ...rest
}: OptionProps<TCollectiveUser, false, any>) => {
  const user = rest.data as TCollectiveUser

  return (
    <components.Option {...rest} isSelected={false}>
      <div className="flex flex-row">
        <div className="pr-[10px]">
          <Thumbnail
            src={user.platformUser?.thumb}
            className="h-12 w-12 rounded-full overflow-hidden"
          />
        </div>

        <div className="flex flex-col justify-center">
          <Typography className="text-sm font-normal">
            {user.platformUser?.displayName}
          </Typography>

          <Typography className="text-xs text-gray-500 font-normal">
            {user.address}
          </Typography>
        </div>
      </div>
    </components.Option>
  )
}

export default CollectiveUserSelectOption
