import { Typography } from 'components/common'
import { BoxHover } from 'components/common/StyledBoxes'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { useCollectiveContext } from 'context/CollectiveContext'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import Link from 'next/link'
import { useMemo } from 'react'

type IProps = {
  link?: string
  text: string
  newTab?: boolean
  icon?: React.ReactElement
  demoOnClick?: Callback
}

export const SummaryLink: React.FC<IProps> = ({
  link,
  text,
  newTab = false,
  icon,
  demoOnClick
}) => {
  const { demoMode } = useCollectiveContext()

  const el = useMemo(
    () => (
      <Typography
        className={'pt-2 hover:text-gray-500'}
        newTab={newTab}
        size="xs"
      >
        {icon} {text}
      </Typography>
    ),
    [icon, newTab, text]
  )

  if (demoMode) {
    if (demoOnClick) {
      return <BoxHover onClick={demoOnClick}>{el}</BoxHover>
    }
    return (
      <span>
        <StyledTooltip arrow title={DemoTooltipText}>
          {el}
        </StyledTooltip>
      </span>
    )
  }

  return link ? (
    <Link href={link} passHref>
      {el}
    </Link>
  ) : (
    el
  )
}
