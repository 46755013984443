import { BigNumber } from 'lib/BigInt'
import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import EtherInputField from 'components/Collectives/Common/EtherInputField'
import WalletAddressInputField from '../../../Common/WalletAddressInputField'
import { POINT_ONE_ETHER_BN } from 'lib/collectives/helpers'

function SendTokensCommand({
  disabled,
  data,
  onChange,
  collective,
  onValidityChange
}: IProposalCommandInputProps) {
  const [target, setTarget] = useState<string>(data.memberAddress || '')
  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    data.value ? new BigNumber(data.value) : POINT_ONE_ETHER_BN
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.SEND_TOKEN,
      value: new BigNumber(amountInWei).toString(),
      target
    })
    // eslint-disable-next-line
  }, [target, amountInWei])

  const [isAddressValid, setIsAddresValid] = useState(false)
  const isValid = useMemo(
    () => isAddressValid && !!target.trim() && amountInWei.gt(0),
    [isAddressValid, target, amountInWei]
  )
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        <WalletAddressInputField
          defaultValue={target}
          onChange={setTarget}
          disabled={disabled}
          onValidityChange={setIsAddresValid}
        />
      </div>

      <div className="pt-4">
        <EtherInputField
          label={`Enter the amount of ${collective.tokens[0].symbol} token you wish to Mint`}
          disabled={disabled}
          initialValueInWei={amountInWei}
          onChangeInWei={setAmountInWei}
          minWei={POINT_ONE_ETHER_BN}
          type={'TOKEN'}
          collective={collective}
          symbol={collective.tokens[0].symbol}
        />
      </div>
    </div>
  )
}

export default SendTokensCommand
