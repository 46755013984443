import Typography from 'components/common/Typography'
import { twMerge } from 'tailwind-merge'

const SummaryItem: React.FC<{
  heading: string
  value: React.ReactElement | string
  link?: React.ReactElement
  childrenVertical?: boolean
  children?: React.ReactNode
  widthClass?: string
}> = ({
  heading,
  value,
  link,
  children,
  childrenVertical = false,
  widthClass = 'min-w-[45%] md:min-w-[30%]'
}) => {
  return (
    <div className={twMerge('flex flex-col flex-1', widthClass)}>
      <Typography size="xs" color="gray" fontWeight="light">
        {heading}
      </Typography>

      <div
        className={twMerge(
          'flex',
          childrenVertical ? 'justify-center flex-col' : 'items-center'
        )}
      >
        <Typography
          fontWeight="bold"
          size="heading-xl"
          className="hidden sm:block"
        >
          {value}
        </Typography>

        <Typography fontWeight="bold" size="heading" className="sm:hidden">
          {value}
        </Typography>

        {children}
      </div>

      {link}
    </div>
  )
}

export default SummaryItem
