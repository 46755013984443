import cls from 'classnames'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FacePile from 'components/FacePile'
import { CollectiveLog, CollectiveProposalType } from 'graphql/generated'
import { isPresentAndTruthy } from 'lib/helpers'
import { noop, orderBy, uniqBy } from 'lodash'
import React, { useState } from 'react'
import Typography from 'components/common/Typography'
import moment from 'moment'
import { calendarTime, getTimeFormat } from 'lib/datetime'
import { TCollectiveBundledLog, TDataLog, TDataTypes } from './common'
import LogActors from './LogActors'
import { LogProposalLink } from './LogProposalLink'
import { LogCollectiveUserPopover } from './LogCollectiveUserPopover'
import { useCollectiveContext } from 'context/CollectiveContext'
import Thumbnail from 'components/Thumbnail'
import { twMerge } from 'tailwind-merge'
import EtherscanLink, {
  EtherscanLinkType
} from 'components/Collectives/Common/EtherscanLink'

interface IProps<T extends TDataTypes> {
  bundle: TCollectiveBundledLog<T>
  summaryActionText?: React.ReactNode
  detailsActionText?: Maybe<
    CallbackWithParams<TDataLog<T>, number, React.ReactNode>
  >
  hideSummaryActors?: boolean
  hideDetailActors?: boolean
  singleProposalText?: false | string
  summaryManyProposalText?: CallbackWithParam<number, string> | false

  isLast?: boolean
}

export function LogsBar({ endReached }: { endReached?: boolean }) {
  return (
    <span
      className={twMerge(
        'absolute top-5 left-6 sm:left-[27px] w-0.5 h-full bg-gray-200',
        endReached && 'h-[calc(100%-95px)]'
      )}
    />
  )
}

function BaseCollectiveLogListItem<T extends TDataTypes>({
  bundle,
  summaryActionText,
  detailsActionText,
  isLast,
  hideSummaryActors = false,
  hideDetailActors = false,
  singleProposalText = 'via proposal',
  summaryManyProposalText
}: IProps<T>) {
  const { collective } = useCollectiveContext()
  const [isOpen, setIsOpen] = useState(false)
  const actors = uniqBy(
    bundle.collectiveUsers.map(x => x.platformUser).filter(isPresentAndTruthy),
    'id'
  )
  const hasDetails = bundle.logs.length && !!detailsActionText

  return (
    <div
      className={cls(
        'group/log relative py-3 px-2 sm:px-3 my-2 rounded-lg hover:bg-gray-100',
        isOpen && 'bg-gray-100',
        hasDetails && 'cursor-pointer'
      )}
      onClick={() => (hasDetails ? setIsOpen(!isOpen) : noop())}
    >
      {!isLast && isOpen && <LogsBar />}

      <div className="relative flex space-x-3">
        {actors.length ? (
          <FacePile
            profiles={actors.slice(0, 2)}
            size={8}
            totalProfiles={actors.length}
            className={cls(
              'ring-8',
              isOpen
                ? 'ring-gray-100'
                : 'ring-gray-50 group-hover/log:ring-gray-100'
            )}
            spacing="space-y-[-6px] sm:space-y-0 sm:space-x-[-6px]"
          />
        ) : (
          <div className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-gray-50 group-hover/log:ring-gray-100 z-1000 bg-white">
            <Thumbnail
              src={collective.thumb}
              initials={collective.name.substring(0)}
              className="h-8 w-8 rounded-full overflow-hidden"
            />
          </div>
        )}

        <div className="group/summary flex-1 flex justify-between sm:items-center">
          <div className="flex-1 flex flex-row max-sm:justify-between">
            <SummaryText
              actors={bundle.collectiveUsers}
              showSummaryActors={!hideSummaryActors}
              bundle={bundle}
              singleProposalText={singleProposalText}
              summaryManyProposalText={summaryManyProposalText}
            >
              {summaryActionText}
            </SummaryText>

            {hasDetails && (
              <div
                className={
                  'ml-2 mr-1 sm:mr-4 h-5 w-5 flex items-center justify-center rounded-full'
                }
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                  className={
                    isOpen
                      ? 'text-gray-600 rotate-90 transition-all'
                      : 'text-gray-400 group-hover/summary:text-gray-600 transition-all'
                  }
                />
              </div>
            )}
          </div>

          <p className="hidden sm:block mt-0.5 text-right text-sm font-light text-gray-500 whitespace-nowrap">
            {calendarTime(moment(bundle.date).toDate())}
          </p>
        </div>
      </div>

      {isOpen && hasDetails && (
        <DetailsList<T>
          bundle={bundle}
          showDetailActors={!hideDetailActors}
          detailsActionText={detailsActionText}
          singleProposalText={singleProposalText}
        />
      )}

      <p className="sm:hidden mt-0.5 text-right text-xs font-light text-gray-500 whitespace-nowrap">
        {calendarTime(moment(bundle.date).toDate())}
      </p>
    </div>
  )
}

function SummaryText<T extends TDataTypes>({
  children,
  actors,
  showSummaryActors = true,
  bundle,
  singleProposalText,
  summaryManyProposalText
}: {
  actors: TCollectiveBundledLog<T>['collectiveUsers']
  showSummaryActors?: boolean
  children: React.ReactNode
  bundle: TCollectiveBundledLog<T>
  singleProposalText: false | string
  summaryManyProposalText?: false | CallbackWithParam<number, string>
}) {
  const logsWithPropsoal = (bundle.logs as any[]).filter(
    (log: any) => !!log.data.proposal
  )
  const uniqueProposals: any[] = uniqBy(
    logsWithPropsoal,
    log => log.data.proposal.id
  )
  const hasOneProposalOnly = uniqueProposals.length === 1
  function getProposalActionText() {
    if (
      hasOneProposalOnly &&
      typeof singleProposalText === 'string' &&
      singleProposalText.includes('proposal') &&
      uniqueProposals[0].data.proposal.type === CollectiveProposalType.SURVEY
    ) {
      return singleProposalText.replace('proposal', 'poll')
    }
    return singleProposalText
  }

  return (
    <Typography
      component="span"
      size="sm"
      color="darkGray"
      fontWeight="medium"
      className="break-all"
    >
      {showSummaryActors && <LogActors actors={actors} />}

      {children}

      {singleProposalText !== false && hasOneProposalOnly && (
        <>
          {` ${getProposalActionText()} `}
          <LogProposalLink
            proposal={uniqueProposals[0].data.proposal}
            disabled
          />
        </>
      )}

      {uniqueProposals.length > 1 &&
        summaryManyProposalText !== false &&
        (summaryManyProposalText?.(uniqueProposals.length) ||
          ` via ${uniqueProposals.length} proposals`)}
    </Typography>
  )
}

function DetailsList<T extends TDataTypes>({
  bundle,
  detailsActionText,
  singleProposalText,
  showDetailActors = true
}: {
  bundle: TCollectiveBundledLog<T>
  showDetailActors: boolean
  detailsActionText: CallbackWithParams<TDataLog<T>, number, React.ReactNode>
  singleProposalText: false | string
}) {
  const logsWithPropsoal: Set<String> = new Set(
    (bundle.logs as any[])
      .filter((log: any) => !!log.data.proposal)
      .map((log: TDataLog<T>) => log.id)
  )

  function getProposalActionText(proposal) {
    if (
      typeof singleProposalText === 'string' &&
      singleProposalText.includes('proposal') &&
      proposal.type === CollectiveProposalType.SURVEY
    ) {
      return singleProposalText.replace('proposal', 'poll')
    }
    return singleProposalText
  }

  return (
    <Typography
      color="gray"
      size="sm"
      fontWeight="light"
      component={'ol'}
      className="list-decimal pt-2 pl-12 sm:pl-16 pr-1 break-all cursor-default"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {orderBy(bundle.logs, ['date'], ['desc']).map((log, index) => (
        <DetailsListItem log={log} key={index}>
          {showDetailActors && log.collectiveUser && (
            <LogCollectiveUserPopover
              user={log.collectiveUser}
              withSpaceAfter
            />
          )}

          {detailsActionText(log, index)}

          {singleProposalText !== false && logsWithPropsoal.has(log.id) ? (
            <>
              {` ${getProposalActionText((log.data as any).proposal)} `}
              <LogProposalLink proposal={(log.data as any).proposal} />
            </>
          ) : log.txHash ? (
            <>
              {` - via transaction `}
              <LogEtherscanTxLink txHash={log.txHash} />
            </>
          ) : null}
        </DetailsListItem>
      ))}
    </Typography>
  )
}

export const LogEtherscanTxLink = ({
  txHash,
  type = 'tx'
}: {
  txHash: string
  type?: EtherscanLinkType
}) => {
  const { collective } = useCollectiveContext()
  return (
    <EtherscanLink
      shortAddress
      inline
      type={type}
      network={collective.network}
      address={txHash}
      style="dotted"
      className="text-gray-500 font-light"
    />
  )
}

const DetailsListItem: React.FC<{
  log: Pick<CollectiveLog, 'date' | 'txHash'>
  children?: React.ReactNode
}> = ({ log, children }) => {
  return (
    <li className="py-2 sm:py-1.5 group/log-item">
      <div className="flex items-center">
        <div className="flex-1">
          {children}
          <Typography
            size="sm"
            color="lightGray"
            className="md:hidden"
            component={'span'}
            fontWeight="light"
          >
            {` \u00b7 ${moment(log.date).format(getTimeFormat())}`}
          </Typography>
        </div>

        <Typography
          size="sm"
          color="lightGray"
          className="md:min-w-[65px] text-right"
          fontWeight="light"
        >
          <div className="hidden md:group-hover/log-item:inline">
            {`${moment(log.date).format(getTimeFormat())}`}
          </div>
        </Typography>
      </div>
    </li>
  )
}

export default BaseCollectiveLogListItem
