import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnMemberAdded: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnMemberAdded'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`${
      bundledCollectiveLog.collectiveUsers.length > 1 ? 'members were' : 'was'
    }  added to DAO membership`}
    hideDetailActors
    detailsActionText={log => (
      <>
        {`Wallet `}
        <LogEtherscanTxLink txHash={log.data.targetMember} type="address" />
        {` was added as a member`}
      </>
    )}
  />
)

export default CollectiveLogDataOnMemberAdded
