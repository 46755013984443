import { useCollectiveContext } from 'context/CollectiveContext'
import { CollectiveLogEventType } from 'graphql/generated'
import { useEffect, useState } from 'react'
import CollectiveLogListItem from './CollectiveLogListItem'
import CollectiveLogsFilter from './CollectiveLogsFilter'
import Button from 'components/Button'
import { useControlledInput } from 'hooks'
import { useDebouncedCallback } from 'use-debounce'
import useCollectiveLogs from 'hooks/collectives/useCollectiveLogs'
import Spinner from 'components/Spinner'
import Typography from 'components/common/Typography'
import { LogsBar } from './BaseCollectiveLogListItem'
import SearchInput from 'components/Collectives/Common/SearchInput'
import CollectiveDetailsSectionWrapper from '../CollectiveDetailsSectionWrapper'

export const CollectiveLogList: React.FC = () => {
  const [show, setShow] = useState(false)

  const [search, setSearch] = useState<string>()
  const [searchValue, onSearchValueChange, clearSearchValue] =
    useControlledInput()
  const onSearchValueChangeDebounced = useDebouncedCallback(e => {
    setSearch(e)
  }, 300)

  useEffect(() => {
    onSearchValueChangeDebounced(searchValue)
  }, [onSearchValueChange, onSearchValueChangeDebounced, searchValue])

  const { collective, demoMode } = useCollectiveContext()
  const collectiveId = collective.id

  const [types, setTypes] = useState<CollectiveLogEventType[]>([])

  const { logs, loadMore, loading, loadingMore, pageInfo, refetch } =
    useCollectiveLogs(collectiveId, types, search, search ? 10 : 25)

  return (
    <div className={'bg-gray-50 p-2 sm:p-4 rounded-md'}>
      <CollectiveDetailsSectionWrapper
        title="Activity Feed"
        description="DAO logs & activity feed"
        show={show}
        setShow={show => {
          setShow(show)
          if (show) {
            refetch()
          }
        }}
      />

      {show && (
        <div className={'mt-6 overflow-hidden'}>
          <div
            className={
              'flex flex-col-reverse sm:flex-row justify-between sm:items-center group gap-6'
            }
          >
            <div className={'flex flex-1 item-center'}>
              {!loading && (
                <Typography fontWeight="medium" size="sm">
                  {search ? 'Showing search results' : `Showing all events`}
                </Typography>
              )}
            </div>

            <div className="flex flex-col sm:flex-row items-end gap-2">
              <div className="max-w-lg sm:max-w-xs">
                <CollectiveLogsFilter types={types} setTypes={setTypes} />
              </div>

              {!demoMode && (
                <SearchInput
                  clearSearchString={clearSearchValue}
                  onSearchStringChange={onSearchValueChange}
                  searchString={searchValue}
                  contextText={'User or Activity'}
                />
              )}
            </div>
          </div>

          {loading ? (
            <div className="w-full flex justify-center p-4">
              <Spinner />
            </div>
          ) : (
            <div className="pb-6 relative">
              {logs.length > 0 ? (
                <>
                  <LogsBar endReached={!pageInfo?.hasNextPage} />
                  <ul role="list" className="overflow-hidden">
                    {logs.map(({ node }, eventIdx) => {
                      const isLast =
                        eventIdx === logs.length - 1 && !pageInfo?.hasNextPage

                      return (
                        <li
                          className="relative"
                          key={node.eventType + node.date + eventIdx}
                        >
                          <CollectiveLogListItem
                            bundledCollectiveLog={node}
                            collective={collective}
                            isLast={isLast}
                          />

                          {isLast && (
                            <div className="flex w-full justify-center items-center mt-4">
                              <Typography color="gray">End of Items</Typography>
                            </div>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </>
              ) : (
                <div className="w-full flex justify-center p-4">
                  <Typography color="gray">
                    No Events found{search ? ` for '${search}'` : ''}
                  </Typography>
                </div>
              )}

              {loadingMore && (
                <div className="w-full flex justify-center p-4">
                  <Spinner />
                </div>
              )}
            </div>
          )}

          {!loading && !!pageInfo?.hasNextPage && (
            <div className="pl-2 z-50 mb-2 relative bg-gray-50">
              <Button
                label={loadingMore ? 'Loading...' : 'Load more'}
                size="sm"
                onClick={loadMore}
                disabled={loadingMore}
                loading={loadingMore}
                color={'gray'}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
