import { formatToken } from 'lib/collectives/helpers'

import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnMemberDepositedFunds: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnMemberDepositedFunds'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`contributed Ether to the DAO`}
    detailsActionText={log =>
      `contributed ${formatToken(log.data.etherAmount)} ether to the DAO  `
    }
  />
)

export default CollectiveLogDataOnMemberDepositedFunds
