import { StyledSelect } from 'components/common/Select'
import { useCollectiveContext } from 'context/CollectiveContext'
import { inflect } from 'inflection'
import { useMemo } from 'react'

interface IProps {
  value: number
  onChange: CallbackWithParam<number>
}

type TDuration = { value: number; label: string }
const Durations = [12, 1 * 24, 3 * 24, 5 * 24, 7 * 24, 10 * 24, 12 * 24]
const DurationOptions = Durations.map(hours => ({
  value: hours,
  label: `${hours} hours`,
  isDisabled: false
}))

function parseLabel(hours: number) {
  const h = hours % 24
  const d = Math.floor(hours / 24)

  if (d && !h) {
    return `${d} ${inflect('days', d)}`
  } else if (!d && h) {
    return `${h} ${inflect('hours', h)}`
  }

  return `${d} ${inflect('days', d)}, ${h} ${inflect('hours', h)}`
}

function ProposalDurationSelector({ value, onChange }: IProps) {
  const { demoMode } = useCollectiveContext()

  const options = useMemo(() => {
    if (!demoMode) {
      return DurationOptions
    }

    const options = [
      { value: 1, label: '1 hour', isDisabled: false },
      ...DurationOptions.map(op => ({ ...op, isDisabled: true }))
    ]
    return options
  }, [demoMode])

  return (
    <StyledSelect<TDuration, false>
      height={47}
      fontSize={14}
      options={options}
      value={options.find(x => x.value === value)}
      placeholder={`Duration Hours`}
      onChange={val => val && onChange(val.value)}
      getOptionLabel={duration => parseLabel(duration.value)}
      // getOptionValue={wallet => wallet.id}
    />
  )
}

export default ProposalDurationSelector
