import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalCanceled: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnProposalCanceled'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`canceled`}
    detailsActionText={() => `canceled`}
    singleProposalText={'proposal'}
    summaryManyProposalText={count => ` ${count} proposals`}
  />
)

export default CollectiveLogDataOnProposalCanceled
