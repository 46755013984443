import { BigNumber } from 'lib/BigInt'
import { useState, useCallback, useEffect, useRef } from 'react'
import WalletConnect from '@walletconnect/client'
import { IClientMeta, IWalletConnectSession } from '@walletconnect/legacy-types'
import { useWeb3 } from 'context/Web3Context'
import { useCollectiveContext } from 'context/CollectiveContext'
import { getChainIdForNetwork } from 'lib/collectives/helpers'
import { IEthTx } from './useWalletConnect'

export interface IProps {
  onEthTransaction: CallbackWithParam<IEthTx>
}

export const areStringsEqual = (string1: string, string2: string): boolean =>
  string1.toLowerCase() === string2.toLowerCase()

const rejectWithMessage = (
  connector: WalletConnect,
  id: number | undefined,
  message: string
) => {
  connector.rejectRequest({ id, error: { message } })
}

const useWalletConnectV1 = ({ onEthTransaction }: IProps) => {
  const { collective } = useCollectiveContext()
  const [wcClientData, setWcClientData] = useState<IClientMeta | null>(null)
  const [connector, setConnector] = useState<WalletConnect | undefined>()
  const { signer, signerAddress } = useWeb3()
  const provider = signer?.provider

  const localStorageSessionKey = useRef(`session_${collective.address}`)

  const wcDisconnect = useCallback(async () => {
    try {
      await connector?.killSession()
      setConnector(undefined)
      setWcClientData(null)
    } catch (error) {
      console.log('Error trying to close WC session: ', error)
    }
  }, [connector])

  const wcConnect = useCallback(
    async ({
      uri,
      session
    }: {
      uri?: string
      session?: IWalletConnectSession
    }) => {
      const wcConnector = new WalletConnect({
        uri,
        session,
        storageId: localStorageSessionKey.current
      })
      setConnector(wcConnector)
      setWcClientData(wcConnector.peerMeta)

      wcConnector.on('session_request', (error, payload) => {
        if (error) {
          throw error
        }

        wcConnector.approveSession({
          accounts: [collective.address],
          chainId: Number(
            getChainIdForNetwork(collective.network).replace('0x', '')
          )
        })

        setWcClientData(payload.params[0].peerMeta)
      })

      wcConnector.on('call_request', async (error, payload) => {
        if (error) {
          throw error
        }

        try {
          let result = '0x'

          switch (payload.method) {
            case 'eth_sendTransaction': {
              const txInfo = payload.params[0]
              onEthTransaction({
                to: txInfo.to,
                data: txInfo.data || '0x',
                value: txInfo.value
                  ? new BigNumber(txInfo.value)
                  : new BigNumber(0)
              })
              result = '0x'
              break
            }
            case 'gs_multi_send': {
              result = '0x'
              break
            }

            case 'personal_sign': {
              const [message, address] = payload.params
              if (!areStringsEqual(address, collective.address)) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('personal_sign', [
                message,
                signerAddress
              ])

              result = signature
              break
            }

            case 'eth_sign': {
              const [address, messageHash] = payload.params
              if (
                !areStringsEqual(address, collective.address) ||
                !messageHash.startsWith('0x')
              ) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('personal_sign', [
                messageHash,
                signerAddress
              ])

              result = signature
              break
            }

            case 'eth_signTypedData':
            case 'eth_signTypedData_v1':
            case 'eth_signTypedData_v3':
            case 'eth_signTypedData_v4': {
              const [address, typedDataJson] = payload.params
              if (!areStringsEqual(address, collective.address)) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('eth_signTypedData_v4', [
                signerAddress,
                typedDataJson
              ])

              result = signature
              break
            }

            default: {
              rejectWithMessage(wcConnector, payload.id, 'METHOD_NOT_SUPPORTED')
              break
            }
          }

          wcConnector.approveRequest({
            id: payload.id,
            result
          })
        } catch (err) {
          rejectWithMessage(wcConnector, payload.id, (err as Error).message)
        }
      })

      wcConnector.on('disconnect', error => {
        if (error) {
          throw error
        }
        wcDisconnect()
      })
    },
    [
      collective.address,
      collective.network,
      wcDisconnect,
      provider,
      onEthTransaction,
      signerAddress
    ]
  )

  useEffect(() => {
    if (!connector) {
      const session = localStorage.getItem(localStorageSessionKey.current)
      if (session) {
        wcConnect({ session: JSON.parse(session) })
      }
    }
  }, [connector, wcConnect])

  return { wcClientData, wcConnect, wcDisconnect }
}

export default useWalletConnectV1
