import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import TextField from 'components/common/TextField'
import { Typography } from 'components/common'

function ChangeVoteQuorumCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const [voteQuorum, setVoteQuorum] = useState<number>(
    data.quorumNeededInPercentage
      ? data.quorumNeededInPercentage
      : collective.parameters.quorumNeededInPercentage
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CHANGE_QUORUM,
      quorumNeededInPercentage: voteQuorum
    })
    // eslint-disable-next-line
  }, [voteQuorum])

  const isValid = useMemo(
    () =>
      voteQuorum != collective.parameters.quorumNeededInPercentage &&
      voteQuorum > 0,
    [voteQuorum, collective.parameters.quorumNeededInPercentage]
  )

  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="flex pt-4 justify-center items-center">
        <TextField
          horizantalFullWidth
          type={'number'}
          label={`Percentage of total members required to vote`}
          placeholder={'1'}
          value={voteQuorum}
          onChange={e =>
            setVoteQuorum(+e.target.value < 0 ? 0 : +e.target.value)
          }
          disabled={disabled}
          postContent={<Typography>%</Typography>}
        />
      </div>
    </div>
  )
}

export default ChangeVoteQuorumCommand
