import { useCallback, useState } from 'react'

import { useChat } from 'components/Chats/ChatProvider'
import { useCurrentUser } from 'hooks'
import { CustomChannelType } from 'components/Chats/ChatTypes'

export function useSelectChannel() {
  const { chatClient, channel, setChannel, reconnectChat } = useChat()
  const { id: userId } = useCurrentUser()

  const [loading, setLoading] = useState(false)

  const selectChannel = useCallback(
    async (channelId: string) => {
      if (!(await reconnectChat())) {
        return
      }
      setLoading(true)
      // filter cache by channel ID or channel name
      let foundChannels: CustomChannelType[] = []
      // query remote by channel ID
      if (!foundChannels.length) {
        foundChannels = await chatClient.queryChannels({
          id: {
            $eq: channelId
          },
          members: { $in: [userId] }
        })
      }
      // query remote by channel name
      if (!foundChannels.length) {
        foundChannels = await chatClient.queryChannels({
          name: {
            $eq: channelId
          },
          members: { $in: [userId] }
        })
      }

      if (!foundChannels.length) {
        setLoading(false)
        return
      }
      const matchedChannel = foundChannels[0]
      setChannel(matchedChannel)

      setLoading(false)
    },
    [chatClient, reconnectChat, setChannel, userId]
  )

  return {
    channel,
    selectChannel,
    loading,
    setChannel
  }
}
