import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnRemovedOwner: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnRemovedOwner'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={
      bundledCollectiveLog.collectiveUsers.length > 1
        ? `signators were removed from the DAO`
        : `signators was removed from from the DAO`
    }
    detailsActionText={log => (
      <>
        {`Signator `}
        <LogEtherscanTxLink txHash={log.data.owner} type="address" />
        {` was removed`}
      </>
    )}
  />
)

export default CollectiveLogDataOnRemovedOwner
