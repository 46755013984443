import { BigNumber } from 'lib/BigInt'
import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import EtherInputField from 'components/Collectives/Common/EtherInputField'
import WalletAddressInputField from '../../../Common/WalletAddressInputField'
import { ZERO_BN, POINT_ONE_ETHER_BN } from 'lib/collectives/helpers'

function SendEtherCommand({
  disabled,
  data,
  onChange,
  collective,
  onValidityChange,
  isDisbursement
}: IProposalCommandInputProps & { isDisbursement?: boolean }) {
  const [target, setTarget] = useState<string>(data.memberAddress || '')
  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    data.value ? new BigNumber(data.value) : ZERO_BN
  )

  useEffect(() => {
    const update = isDisbursement
      ? {
          type: ProposalCommandType.SEND_DISBURSEMENT,
          value: new BigNumber(amountInWei).toString()
        }
      : {
          type: ProposalCommandType.SEND_ETHER,
          value: new BigNumber(amountInWei).toString(),
          target
        }
    onChange(update)
    // eslint-disable-next-line
  }, [target, amountInWei, isDisbursement])

  const [isAddressValid, setIsAddresValid] = useState(false)
  const isValid = useMemo(() => {
    if (isDisbursement) {
      return amountInWei.gt(0)
    }

    return isAddressValid && !!target.trim() && amountInWei.gt(0)
  }, [isAddressValid, target, amountInWei, isDisbursement])
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      {!isDisbursement && (
        <div className="pt-4">
          <WalletAddressInputField
            defaultValue={target}
            onChange={setTarget}
            disabled={disabled}
            onValidityChange={setIsAddresValid}
          />
        </div>
      )}

      <div className="pt-4">
        <EtherInputField
          label={`Amount`}
          disabled={disabled}
          initialValueInWei={amountInWei}
          onChangeInWei={setAmountInWei}
          minWei={POINT_ONE_ETHER_BN}
          maxWei={new BigNumber(collective.treasuryBalance)}
        />
      </div>
    </div>
  )
}

export default SendEtherCommand
