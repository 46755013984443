import { CollectiveLogDataType } from 'graphql/generated'
import React from 'react'
import { ICollectiveLogListItemProps, TCollectiveBundledLog } from './common'
import CollectiveLogDataOnVoteCast from './CollectiveLogDataOnVoteCast'
import CollectiveLogDataOnVoteChanged from './CollectiveLogDataOnVoteChanged'
import CollectiveLogDataOnCollectiveMembershipRequested from './CollectiveLogDataOnCollectiveMembershipRequested'
import CollectiveLogDataOnCollectiveMembershipProposed from './CollectiveLogDataOnCollectiveMembershipProposed'
import CollectiveLogDataOnMemberAdded from './CollectiveLogDataOnMemberAdded'
import CollectiveLogDataOnMemberRemoved from './CollectiveLogDataOnMemberRemoved'
import CollectiveLogDataOnCollectiveSynced from './CollectiveLogDataOnCollectiveSynced'
import CollectiveLogDataOnCollectiveSyncRequested from './CollectiveLogDataOnCollectiveSyncRequested'
import CollectiveLogDataOnProposalExecuted from './CollectiveLogDataOnProposalExecuted'
import CollectiveLogDataOnProposalCanceled from './CollectiveLogDataOnProposalCanceled'
import CollectiveLogDataOnMemberDepositedFunds from './CollectiveLogDataOnMemberDepositedFunds'
import CollectiveLogDataOnNonDepositEtherReceived from './CollectiveLogDataOnNonDepositEtherReceived'
import CollectiveLogDataOnMemberWithdrewFunds from './CollectiveLogDataOnMemberWithdrewFunds'
import CollectiveLogDataOnDelegationsGranted from './CollectiveLogDataOnDelegationsGranted'
import CollectiveLogDataOnDelegationsRevoked from './CollectiveLogDataOnDelegationsRevoked'
import CollectiveLogDataOnTransfer from './CollectiveLogDataOnTransfer'
import CollectiveLogDataOnChangedThreshold from './CollectiveLogDataOnChangedThreshold'
import CollectiveLogDataOnExchangeRateChanged from './CollectiveLogDataOnExchangeRateChanged'
import CollectiveLogDataOnProposalSigned from './CollectiveLogDataOnProposalSigned'
import CollectiveLogDataOnGateDepositsChanged from './CollectiveLogDataOnGateDepositsChanged'
import CollectiveLogDataOnProposalsBatched from './CollectiveLogDataOnProposalsBatched'
import CollectiveLogDataOnAddedOwner from './CollectiveLogDataOnAddedOwner'
import CollectiveLogDataOnAnnouncementCreated from './CollectiveLogDataOnAnnouncementCreated'
import CollectiveLogDataOnAnnouncementEdited from './CollectiveLogDataOnAnnouncementEdited'
import CollectiveLogDataOnAnnouncementDeleted from './CollectiveLogDataOnAnnouncementDeleted'
import CollectiveLogDataOnRemovedOwner from './CollectiveLogDataOnRemovedOwner'
import CollectiveLogDataOnExternalCollectiveJoined from './CollectiveLogDataOnExternalCollectiveJoined'
import CollectiveLogDataOnProposalRequireVoteSignatureChanged from './CollectiveLogDataOnProposalRequireVoteSignatureChanged'
import CollectiveLogDataOnWalletLinked from './CollectiveLogDataOnWalletLinked'
import CollectiveLogDataOnWalletUnlinked from './CollectiveLogDataOnWalletUnlinked'
import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import Typography from 'components/common/Typography'
import CollectiveLogDataOnEnableDepositsChanged from './CollectiveLogDataOnEnableDepositsChanged'
import CollectiveLogDataOnEnableNewMembershipRequestsChanged from './CollectiveLogDataOnEnableNewMembershipRequestsChanged'
import CollectiveLogDataOnEnableWithdrawsChanged from './CollectiveLogDataOnEnableWithdrawsChanged'
import CollectiveLogDataOnMinEthContributionChanged from './CollectiveLogDataOnMinEthContributionChanged'
import CollectiveLogDataOnQuorumNeededInPercentageChanged from './CollectiveLogDataOnQuorumNeededInPercentageChanged'
import CollectiveLogDataOnTransferBatch from './CollectiveLogDataOnTransferBatch'
import CollectiveLogDataOnDisableTokenTransfersChanged from './CollectiveLogDataOnDisableTokenTransfersChanged'
import CollectiveLogDataOnVoteInvalidated from './CollectiveLogDataOnVoteInvalidated'
import CollectiveLogDataOnVoteWeightUpdated from './CollectiveLogDataOnVoteWeightUpdated'

const IS_PROD = process.env.NODE_ENV === 'production'

export const CollectiveLogListItem: React.FC<
  ICollectiveLogListItemProps<CollectiveLogDataType['__typename']>
> = ({ bundledCollectiveLog, ...rest }) => {
  const firstData = bundledCollectiveLog.logs[0].data
  switch (firstData.__typename) {
    case 'CollectiveLogDataOnVoteCast':
      return (
        <CollectiveLogDataOnVoteCast
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnVoteCast'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnVoteChanged':
      return (
        <CollectiveLogDataOnVoteChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnVoteChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnDelegationsRevoked':
      return (
        <CollectiveLogDataOnDelegationsRevoked
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnDelegationsRevoked'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnGateDepositsChanged':
      return (
        <CollectiveLogDataOnGateDepositsChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnGateDepositsChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnDelegationsGranted':
      return (
        <CollectiveLogDataOnDelegationsGranted
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnDelegationsGranted'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnTransferSingle':
    case 'CollectiveLogDataOnTransfer':
      return (
        <CollectiveLogDataOnTransfer
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnTransfer'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnCollectiveMembershipRequested':
      return (
        <CollectiveLogDataOnCollectiveMembershipRequested
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnCollectiveMembershipRequested'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnCollectiveMembershipProposed':
      return (
        <CollectiveLogDataOnCollectiveMembershipProposed
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnCollectiveMembershipProposed'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnExternalCollectiveJoined':
      return (
        <CollectiveLogDataOnExternalCollectiveJoined
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnExternalCollectiveJoined'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnMemberAdded': {
      return (
        <CollectiveLogDataOnMemberAdded
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnMemberAdded'>
          }
          {...rest}
        />
      )
    }

    case 'CollectiveLogDataOnMemberRemoved':
      return (
        <CollectiveLogDataOnMemberRemoved
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnMemberRemoved'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnChangedThreshold':
      return (
        <CollectiveLogDataOnChangedThreshold
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnChangedThreshold'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnExchangeRateChanged':
      return (
        <CollectiveLogDataOnExchangeRateChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnExchangeRateChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnCollectiveSynced':
      return (
        <CollectiveLogDataOnCollectiveSynced
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnCollectiveSynced'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnCollectiveSyncRequested':
      return (
        <CollectiveLogDataOnCollectiveSyncRequested
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnCollectiveSyncRequested'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnProposalExecuted':
      return (
        <CollectiveLogDataOnProposalExecuted
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnProposalExecuted'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnProposalCanceled':
      return (
        <CollectiveLogDataOnProposalCanceled
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnProposalCanceled'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnMemberDepositedFunds':
      return (
        <CollectiveLogDataOnMemberDepositedFunds
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnMemberDepositedFunds'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnNonDepositEtherReceived':
      return (
        <CollectiveLogDataOnNonDepositEtherReceived
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnNonDepositEtherReceived'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnMemberWithdrewFunds':
      return (
        <CollectiveLogDataOnMemberWithdrewFunds
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnMemberWithdrewFunds'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnProposalSigned':
      return (
        <CollectiveLogDataOnProposalSigned
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnProposalSigned'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnProposalsBatched':
      return (
        <CollectiveLogDataOnProposalsBatched
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnProposalsBatched'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnAddedOwner':
      return (
        <CollectiveLogDataOnAddedOwner
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnAddedOwner'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnRemovedOwner':
      return (
        <CollectiveLogDataOnRemovedOwner
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnRemovedOwner'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnAnnouncementCreated':
      return (
        <CollectiveLogDataOnAnnouncementCreated
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnAnnouncementCreated'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnAnnouncementChanged':
      return (
        <CollectiveLogDataOnAnnouncementEdited
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnAnnouncementChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnAnnouncementDeleted':
      return (
        <CollectiveLogDataOnAnnouncementDeleted
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnAnnouncementDeleted'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnProposalRequireVoteSignatureChanged':
      return (
        <CollectiveLogDataOnProposalRequireVoteSignatureChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnProposalRequireVoteSignatureChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnMinEthContributionChanged':
      return (
        <CollectiveLogDataOnMinEthContributionChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnMinEthContributionChanged'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnEnableDepositsChanged':
      return (
        <CollectiveLogDataOnEnableDepositsChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnEnableDepositsChanged'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnQuorumNeededInPercentageChanged':
      return (
        <CollectiveLogDataOnQuorumNeededInPercentageChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnQuorumNeededInPercentageChanged'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnEnableWithdrawsChanged':
      return (
        <CollectiveLogDataOnEnableWithdrawsChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnEnableWithdrawsChanged'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnEnableNewMembershipRequestsChanged':
      return (
        <CollectiveLogDataOnEnableNewMembershipRequestsChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnEnableNewMembershipRequestsChanged'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnWalletLinked':
      return (
        <CollectiveLogDataOnWalletLinked
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnWalletLinked'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnWalletUnlinked':
      return (
        <CollectiveLogDataOnWalletUnlinked
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnWalletUnlinked'>
          }
          {...rest}
        />
      )

    case 'CollectiveLogDataOnTransferBatch':
      return (
        <CollectiveLogDataOnTransferBatch
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnTransferBatch'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnDisableTokenTransfersChanged':
      return (
        <CollectiveLogDataOnDisableTokenTransfersChanged
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnDisableTokenTransfersChanged'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnVoteWeightUpdated':
      return (
        <CollectiveLogDataOnVoteWeightUpdated
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnVoteWeightUpdated'>
          }
          {...rest}
        />
      )
    case 'CollectiveLogDataOnVoteInvalidated':
      return (
        <CollectiveLogDataOnVoteInvalidated
          bundledCollectiveLog={
            bundledCollectiveLog as TCollectiveBundledLog<'CollectiveLogDataOnVoteInvalidated'>
          }
          {...rest}
        />
      )
    // do not show
    case 'CollectiveLogDataOnProposalExecutionReset':
    case 'CollectiveLogDataOnFundsDeposited':
    default:
      return !IS_PROD ? (
        <BaseCollectiveLogListItem
          isLast={rest.isLast}
          bundle={bundledCollectiveLog}
          singleProposalText={false}
          summaryActionText={
            <>
              {bundledCollectiveLog.logs[0].data.__typename.replace(
                /([A-Z])/g,
                ' $1'
              )}{' '}
              <Typography color="error" component={'span'} size="sm">
                [Wont show in production]
              </Typography>
            </>
          }
        />
      ) : null
  }
}

export default CollectiveLogListItem
