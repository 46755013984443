import cls from 'classnames'
import { CollectiveSyncStatus } from 'graphql/generated'
import { useMemo } from 'react'

function SyncStatusBadge({ status }: { status: CollectiveSyncStatus }) {
  const label = useMemo(() => {
    switch (status) {
      case CollectiveSyncStatus.PENDING:
      case CollectiveSyncStatus.IN_PROGRESS:
        return 'Sync In Progress'
      case CollectiveSyncStatus.FAILED:
        return 'Failed'
      case CollectiveSyncStatus.SUCCEEDED:
        return 'Completed'
      default:
        return null
    }
  }, [status])

  if (!label) {
    return null
  }

  return (
    <div
      className={cls(
        'py-[2px] px-2 text-xs font-medium rounded-[30px] text-center inline-flex',
        (status === CollectiveSyncStatus.PENDING ||
          status === CollectiveSyncStatus.IN_PROGRESS) &&
          'text-white bg-[0C5FE3]',
        status === CollectiveSyncStatus.FAILED &&
          'border-[1px] text-[#D5193D] bg-[#D5193D]',
        status === CollectiveSyncStatus.SUCCEEDED &&
          'text-white bg-[#48980E] border-[1px]'
      )}
    >
      {label}
    </div>
  )
}

export default SyncStatusBadge
