import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnChangedThreshold: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnChangedThreshold'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    hideSummaryActors
    summaryActionText={`Setting "Minimum Signators" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? `to ${bundledCollectiveLog.logs[0].data.threshold}`
        : ''
    }`}
    detailsActionText={log =>
      `Minimum Signators changed to ${log.data.threshold}`
    }
  />
)

export default CollectiveLogDataOnChangedThreshold
