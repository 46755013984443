import { useCollectiveContext } from 'context/CollectiveContext'
import { useState } from 'react'
import MembershipRequiredModal from '../MembershipRequiredModal'
import Tabs from '../../../common/Tabs'
import YourDelegations from './YourDelegations'
import DelegationsGrantedToViewerList from './DelegationsGrantedToViewerList'
import BaseModal from 'components/modals/BaseModal'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import WalletCheckInfo from 'components/Collectives/Common/WalletCheckInfo'
import { twMerge } from 'tailwind-merge'

enum CurrentTab {
  YOUR_DELEGATIONS,
  DELEGATED_TO_YOU
}

export function ManageDelegationsModal({ onClose }: { onClose?: Callback }) {
  const { collective, demoMode } = useCollectiveContext()
  const [tabView, setTabView] = useState(CurrentTab.YOUR_DELEGATIONS)
  const [wildCard, setWildCardMode] = useState(false)

  const { showWalletNotice } = useWalletCheck(collective)
  return (
    <MembershipRequiredModal
      collective={collective}
      requiredRole={'approved'}
      onClose={onClose}
    >
      <BaseModal
        classNames={twMerge(
          'py-4 px-5',
          wildCard ? 'sm:max-w-lg' : 'sm:max-w-2xl '
        )}
        onClose={onClose}
        disableBackdropClick
      >
        {showWalletNotice && !demoMode ? (
          <WalletCheckInfo
            collective={collective}
            className="bg-red-50 hover:bg-red-100 px-5 py-3 rounded-lg my-3 mx-3"
          />
        ) : (
          <div className="flex-1 flex flex-col overflow-hidden">
            <p className="text-gray-800 font-semibold text-lg mb-3">
              Manage Delegations
            </p>

            <Tabs
              selectedIndex={tabView}
              onChange={(_tab, idx) => setTabView(idx)}
              className="mt-4 mx-8"
              tabs={[
                {
                  label: 'Your Delegations',
                  onSelectDisplay: (
                    <YourDelegations
                      onWildcardModeChange={setWildCardMode}
                      onClose={onClose}
                    />
                  )
                },
                {
                  label: 'Delegated to You',
                  onSelectDisplay: (
                    <DelegationsGrantedToViewerList
                      collectiveId={collective.id}
                      onClose={onClose}
                    />
                  )
                }
              ]}
            />
          </div>
        )}
      </BaseModal>
    </MembershipRequiredModal>
  )
}
