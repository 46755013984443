import { useCollectiveContext } from 'context/CollectiveContext'
import { useIsLoggedIn } from 'hooks'
import { UnauthedView } from './UnauthedView'
import { GuestView } from './GuestView'
import { MemberView } from './MemberView'

function CollectiveSummaryCard() {
  const { collective, demoMode } = useCollectiveContext()
  const isLoggedIn = useIsLoggedIn()

  if (demoMode) {
    return (
      <div className="shadow-none bg-gray-50 flex flex-1 flex-col">
        {!collective.membership?.approved ? (
          <GuestView />
        ) : collective.membership?.approved ? (
          <MemberView />
        ) : (
          <UnauthedView />
        )}
      </div>
    )
  }

  return (
    <div className="shadow-none bg-gray-50 flex flex-1 flex-col">
      {isLoggedIn ? (
        collective.membership?.approved ? (
          <MemberView />
        ) : (
          <GuestView />
        )
      ) : (
        <UnauthedView />
      )}
    </div>
  )
}

export default CollectiveSummaryCard
