import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import Image from 'next/image'
import PillButton from 'components/common/PillButton'

type IProps = {
  address: string
  symbol: string
  decimals: number
  image: string
}

const AddToMetamaskButton: React.FC<IProps> = ({
  address,
  symbol,
  decimals,
  image
}) => {
  const imageWidth = 16
  const imageHeight = 16

  const addToken = useCallback(async () => {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image
        }
      }
    })
  }, [address, decimals, image, symbol])

  if (!window.ethereum) {
    return null
  }

  return (
    <PillButton onClick={addToken} color="gray" className="ml-2">
      <FontAwesomeIcon icon={faPlus} className="mr-1" />
      <Image
        alt="MetaMask logo"
        src={'/static/collective/metaMaskLogo.png'}
        width={imageWidth}
        height={imageHeight}
      />
    </PillButton>
  )
}

export default AddToMetamaskButton
