import {
  VoteDelegation__factory,
  VoteDelegation
} from '@upstreamapp/upstream-dao'
import { Collective } from 'graphql/generated'
import { useWeb3 } from 'context/Web3Context'
import { useMemo, useState } from 'react'
import { TTxUiState } from 'lib/collectives/helpers'
import { BigNumberish } from 'ethers'

type TCollective = Pick<Collective, 'id' | 'address' | 'delegationAddress'>

function useDelegateVotes({ collective }: { collective: TCollective }) {
  const [txHash, setTxHash] = useState<Maybe<string>>()
  const { signer } = useWeb3()
  const dao = useMemo(
    () =>
      signer && collective.delegationAddress
        ? VoteDelegation__factory.connect(collective.delegationAddress, signer)
        : undefined,
    [signer, collective.delegationAddress]
  )

  const delegateVotes: Web3Action<
    { value: VoteDelegation.DelegationStruct[] } & IWeb3ActionOptions
  > = async function ({ value, onCall }) {
    if (!dao) {
      throw new Error(`Please sign in with Web3 wallet to continue`)
    }

    const parsedResults = value.reduce(
      (total, current) => {
        total.addresses.push(current.delegatee)
        total.maxValues.push(current.maxValue)
        total.commands.push(current.command)

        return total
      },
      {
        addresses: [] as string[],
        commands: [] as string[],
        maxValues: [] as BigNumberish[]
      }
    )

    const transaction =
      value.length > 0
        ? await dao.grantDelegates(
            parsedResults.addresses,
            parsedResults.commands,
            parsedResults.maxValues
          )
        : await dao.removeDelegates()

    onCall(TTxUiState.WAITING_FOR_NETWORK)
    setTxHash(transaction.hash)
    const { status: txStatus } = await transaction.wait()

    window.analytics.trackAll('Delegated Vote', {
      collectiveId: collective.id
    })

    return { txStatus }
  }

  return {
    delegateVotes,
    txHash
  }
}

export default useDelegateVotes
