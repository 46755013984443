import { BigNumber } from 'lib/BigInt'
import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import { getWeiBN, ONE_BN } from 'lib/collectives/helpers'
import EtherInputField from '../../../Common/EtherInputField'

function ChangeMinEthContributionCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const minEthContributionInWeiBN = getWeiBN(
    data.minEthContribution
      ? data.minEthContribution
      : collective.parameters.minEthContribution
  )

  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    minEthContributionInWeiBN
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION,
      minEthContribution: new BigNumber(amountInWei).toString()
    })
    // eslint-disable-next-line
  }, [amountInWei])

  const isValid = useMemo(() => amountInWei.gt(0), [amountInWei])

  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        <EtherInputField
          label={`Minimum Ether contribution required to contribute`}
          disabled={disabled}
          initialValueInWei={minEthContributionInWeiBN}
          onChangeInWei={setAmountInWei}
          minWei={ONE_BN}
        />
      </div>
    </div>
  )
}

export default ChangeMinEthContributionCommand
