import { BigNumber } from 'lib/BigInt'
import { useEffect, useState } from 'react'
import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from '../ProposalCommand.types'
import { ZERO_BN } from 'lib/collectives/helpers'
import Tabs from 'components/common/Tabs'
import { RemoteContractAbi } from './RemoteContractAbi'
import { RemoteWalletConnect } from './RemoteWalletConnect'
import { useBetaFeatureFlag } from 'hooks'

function CallRemoteCommand({
  collective,
  data,
  onChange,
  onValidityChange
}: IProposalCommandInputProps) {
  const [target, setTarget] = useState<string>(data.target || '')
  const [abiData, setAbiData] = useState<string>(data.data ?? '0x')
  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    data.value ? new BigNumber(data.value) : ZERO_BN
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CALL_REMOTE,
      data: abiData,
      target,
      value: new BigNumber(amountInWei).toString()
    })
    // eslint-disable-next-line
  }, [target, abiData, amountInWei])

  const { allowedAccess: abiContractEnabled, isBeta } = useBetaFeatureFlag(
    'ENABLE_REMOTE_CONTRACT_ABI_COMMAND'
  )

  if (!abiContractEnabled) {
    return (
      <div className="mb-1 mt-4">
        <RemoteWalletConnect
          data={data}
          collective={collective}
          onChange={onChange}
          onValidityChange={onValidityChange}
          target={target}
          setTarget={setTarget}
          abiData={abiData}
          setAbiData={setAbiData}
          amountInWei={amountInWei}
          setAmountInWei={setAmountInWei}
        />
      </div>
    )
  }
  return (
    <div className="mb-1 mt-4">
      <Tabs
        selectedIndex={0}
        onChange={() => {
          setTarget('')
          setAbiData('0x')
          setAmountInWei(ZERO_BN)
        }}
        className="mx-8"
        tabs={[
          {
            label: 'Wallet Connect',
            onSelectDisplay: (
              <RemoteWalletConnect
                data={data}
                collective={collective}
                onChange={onChange}
                onValidityChange={onValidityChange}
                target={target}
                setTarget={setTarget}
                abiData={abiData}
                setAbiData={setAbiData}
                amountInWei={amountInWei}
                setAmountInWei={setAmountInWei}
              />
            )
          },
          {
            label: `Contract ABI ${isBeta ? '(beta)' : ''}`,
            onSelectDisplay: (
              <RemoteContractAbi
                data={data}
                collective={collective}
                onChange={onChange}
                onValidityChange={onValidityChange}
                target={target}
                setTarget={setTarget}
                abiData={abiData}
                setAbiData={setAbiData}
                amountInWei={amountInWei}
                setAmountInWei={setAmountInWei}
              />
            )
          }
        ]}
      />
    </div>
  )
}

export default CallRemoteCommand
