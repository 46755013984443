import { humanTime } from 'lib/datetime'
import { CollectiveSyncStatus } from 'graphql/generated'
import { useMemo } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'
import useSyncCollectiveToBlockchain from 'hooks/collectives/useSyncCollectiveToBlockchain'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import SyncStatusBadge from './SyncStatusBadge'
import moment from 'moment'
import Button from 'components/Button'
import { useFlag } from 'hooks'

function SyncStatus() {
  const { syncCollective, loading } = useSyncCollectiveToBlockchain()
  const { collective, demoMode } = useCollectiveContext()

  const cooldownPeriodMinutes = useFlag(
    'COLLECTIVE_SYNC_COOLDOWN_PERIOD_MINS',
    15
  )
  const isPastCooldownPeriod = moment().isAfter(
    moment(collective.syncStatus.lastSyncedAt).add(
      cooldownPeriodMinutes,
      'minutes'
    )
  )
  const startedUnderAnHour = useMemo(
    () =>
      moment().isBefore(
        moment(collective.syncStatus.syncStartedAt).add(1, 'hour')
      ),
    [collective.syncStatus.syncStartedAt]
  )

  const syncInProgress =
    collective.syncStatus.status === CollectiveSyncStatus.PENDING ||
    collective.syncStatus.status === CollectiveSyncStatus.IN_PROGRESS
  const syncFailed =
    collective.syncStatus.status === CollectiveSyncStatus.FAILED
  const syncPossiblyStuck = syncInProgress && !startedUnderAnHour
  const canResync = isPastCooldownPeriod || syncFailed

  return (
    <span
      className={'flex flex-col flex-1 text-right justify-center items-end'}
    >
      {collective.syncStatus.lastSyncedAt &&
        (!syncInProgress || syncPossiblyStuck) && (
          <span>{`(Last Synced ${humanTime(
            collective.syncStatus.lastSyncedAt
          )})`}</span>
        )}

      {(!syncInProgress || syncPossiblyStuck) && (
        <StyledTooltip arrow title={demoMode ? DemoTooltipText : ''}>
          <Button
            className="-mr-2"
            color="link"
            onClick={() => syncCollective(collective)}
            size="xs"
            disabled={!canResync || demoMode}
            loading={loading}
            showFocusRing={false}
          >
            {loading ? '' : 'Sync Now'}
          </Button>
        </StyledTooltip>
      )}

      {((syncInProgress && !syncPossiblyStuck) || syncFailed) && (
        <SyncStatusBadge status={collective.syncStatus.status} />
      )}
    </span>
  )
}

export default SyncStatus
