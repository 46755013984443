import React, { useMemo } from 'react'
import TextField from 'components/common/TextField'
import ProposalDurationSelector from 'components/Collectives/modals/AddProposalModal/ProposalDurationSelector'
import Spinner from 'components/Spinner'
import dynamic from 'next/dynamic'
import Button from 'components/Button'
import { TCollective } from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import FieldLabel from 'components/common/FieldLabel'
import { useCreateProposalContext } from './CreateProposalContext'
import { ProposalStepsContainer } from './common'

interface Props {
  collective: TCollective
}

const QuillNoSSRWrapper = dynamic(() => import('components/QuillNoSSR'), {
  ssr: false,
  // eslint-disable-next-line react/display-name
  loading: () => <Spinner />
})

const ProposalDetailsStep: React.FC<Props> = ({ collective }) => {
  const {
    goBack,
    saving,
    doCreateProposal,
    title,
    setTitle,
    description,
    setDescription,
    durationInHours,
    setDurationInHours
  } = useCreateProposalContext()

  const canSubmit = useMemo(
    () => !saving && !!title && !!description,
    [saving, title, description]
  )

  return (
    <ProposalStepsContainer
      buttons={
        <>
          <Button
            label={'Back'}
            color="lightgray"
            onClick={() => goBack(true)}
            disabled={saving}
          />
          <Button
            label="Create Proposal"
            className="ml-2"
            disabled={!canSubmit}
            loading={saving}
            onClick={() =>
              doCreateProposal({
                title,
                description,
                collectiveId: collective.id,
                durationInHours
              })
            }
          />
        </>
      }
    >
      <div className="flex flex-row max-sm:flex-col gap-4">
        <div className="w-[448px] max-sm:w-full">
          <TextField
            label="Title"
            color="white"
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
            placeholder="Enter a title for the proposal"
          />
        </div>

        <div>
          <FieldLabel label="Voting Duration" required />

          <ProposalDurationSelector
            value={durationInHours}
            onChange={setDurationInHours}
          />
        </div>
      </div>

      <div className="mt-4">
        <FieldLabel label="Proposal Description" required />
        <QuillNoSSRWrapper
          detailsHtml={description}
          setDetailsHtml={setDescription}
          placeholder={`Describe your proposal in more detail here`}
        />
      </div>
    </ProposalStepsContainer>
  )
}

export default ProposalDetailsStep
