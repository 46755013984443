import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalRequireVoteSignatureChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnEnableDepositsChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    summaryActionText={`Setting "Enable Ether Contributions?" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? bundledCollectiveLog.logs[0].data.enableDeposits
          ? `to 'Yes'`
          : `to 'No'`
        : ''
    }`}
    hideSummaryActors
    detailsActionText={log => (
      <>
        {`Enable Ether Contributions was changed to `}
        {log.data.enableDeposits ? `'Yes'` : `'No'`}
      </>
    )}
  />
)

export default CollectiveLogDataOnProposalRequireVoteSignatureChanged
