import { formatToken } from 'lib/collectives/helpers'
import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnNonDepositEtherReceived: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnNonDepositEtherReceived'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`deposited Ether`}
    detailsActionText={log =>
      `sent ${formatToken(log.data.etherAmount)} ether to the DAO `
    }
  />
)

export default CollectiveLogDataOnNonDepositEtherReceived
