import {
  CollectiveMembersQueryResult,
  useCollectiveMembersQuery
} from 'graphql/generated'
import { useState, useCallback, useMemo, useEffect } from 'react'
import CollectiveUserSelectOption from './SelectComponents/CollectiveUserSelectOption'
import CollectiveUserSelectSingleValue from './SelectComponents/CollectiveUserSelectSingleValue'
import { useCurrentUser } from 'hooks'
import { StyledSelect } from 'components/common/Select'
import { useCollectiveContext } from 'context/CollectiveContext'
import { getSearchableUser } from 'components/Collectives/Common/SelectComponents/common'

type TCollectiveUser = NonNullable<
  CollectiveMembersQueryResult['collective']
>['members']['edges'][0]['node']['user']

export interface ICollectiveMembersDropdown {
  value: string
  disabled?: boolean
  onChange: CallbackWithParam<string>
  collectiveId: string
  placeholder?: string
  excludeCurrentMember?: boolean
}
export function CollectiveMembersDropdown({
  disabled,
  value,
  onChange,
  collectiveId,
  excludeCurrentMember,
  placeholder
}: ICollectiveMembersDropdown) {
  const { collectiveUser } = useCurrentUser()
  const [signatorAddress, setSignatorAddress] = useState<string>(value || '')

  const { demoMode } = useCollectiveContext()

  const { data: membersData } = useCollectiveMembersQuery({
    variables: {
      collectiveId: collectiveId,
      memberConnection: {
        first: 5000
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: demoMode
  })

  const options = useMemo(() => {
    if (demoMode) {
      return []
    }
    let members = (membersData?.collective?.members.edges || []).map(
      x => x.node.user
    )
    if (excludeCurrentMember) {
      const addressesBlacklist = collectiveUser.map(v => v.address)

      members = members.filter(v => !addressesBlacklist.includes(v.address))
    }
    return members
  }, [
    collectiveUser,
    demoMode,
    excludeCurrentMember,
    membersData?.collective?.members.edges
  ])

  const handleChange = useCallback(
    address => {
      onChange(address)
      setSignatorAddress(address)
    },
    [onChange]
  )

  useEffect(() => {
    setSignatorAddress(value)
  }, [value])

  return (
    <div className="pt-2 max-w-[400px]">
      <StyledSelect<TCollectiveUser, false>
        height={50}
        options={options}
        isClearable={true}
        value={options.find(x => x.address === signatorAddress)}
        placeholder={placeholder || `Select Signator`}
        onChange={x => handleChange(x ? x.address : null)}
        isDisabled={disabled}
        components={{
          Option: CollectiveUserSelectOption,
          SingleValue: CollectiveUserSelectSingleValue
        }}
        getOptionLabel={user =>
          getSearchableUser(user.platformUser, user.address)
        }
      />
    </div>
  )
}

export default CollectiveMembersDropdown
