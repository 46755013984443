import { useState } from 'react'
import SendEtherCommand from './SendEtherCommand'
import {
  IProposalCommandInputProps,
  ICommandOption,
  TCommandComponent,
  COMMAND_OPTIONS
} from './ProposalCommand.types'
import ChangeExchangeRateCommand from './ChangeExchangeRateCommand'
import ChangeEnableWithdrawsCommand from './ChangeEnableWithdrawsCommand'
import ChangeEnableDepositsCommand from './ChangeEnableDepositsCommand'
import ChangeGateDepositsCommand from './ChangeGateDepositsCommand'
import AddMemberCommand from './AddMemberCommand'
import RemoveMemberCommand from './RemoveMemberCommand'
import AddSignatorCommand from './AddSignatorCommand'
import RemoveSignatorCommand from './RemoveSignatorCommand'
import ChangeSafeThresholdCommand from './ChangeSafeThresholdCommand'
import TransferAssetCommand from './TransferAssetCommand'
import LinkWalletCommand from './LinkWalletCommand'
import UnlinkWalletCommand from './UnlinkWalletCommand'
import SendTokensCommand from './SendTokensCommand'
import { ProposalCommandType } from 'graphql/generated'
import ChangeVoteQuorumCommand from './ChangeVoteQuorumCommand'
import CallRemoteCommand from './CallRemoteCommand'
import ChangeEnableNewMembershipRequestsCommand from './ChangeEnableNewMembershipRequestsCommand'
import ChangeMinEthContributionCommand from './ChangeMinEthContributionCommand'
import ChangeRequireVoteSignatureCommand from './ChangeRequireVoteSignatureCommand'
import RemoveZeroBalanceMembersCommand from './RemoveZeroBalanceMembersCommand'
import ChangeVotingAnonymityCommand from './ChangeVotingAnonymityCommand'
import ChangeDisableTokenTransfersCommand from './ChangeDisableTokenTransfersCommand'

export function getOptionForCommandType(
  type: ProposalCommandType
): Maybe<ICommandOption> {
  return COMMAND_OPTIONS.find(option => option.renderForTypes.includes(type))
}

export interface IProposalCommand extends IProposalCommandInputProps {
  component?: TCommandComponent
}

function ProposalCommand({
  component: selectedComponent,
  ...props
}: IProposalCommand) {
  const [component] = useState<Maybe<TCommandComponent>>(
    selectedComponent ?? getOptionForCommandType(props.data.type)?.component
  )

  return (
    <div>
      {component === 'SendEtherCommand' && <SendEtherCommand {...props} />}

      {component === 'SendEtherDisbursementCommand' && (
        <SendEtherCommand {...props} isDisbursement={true} />
      )}

      {component === 'SendTokensCommand' && <SendTokensCommand {...props} />}

      {component === 'TransferAssetCommand' && (
        <TransferAssetCommand {...props} />
      )}

      {component === 'ChangeExchangeRateCommand' && (
        <ChangeExchangeRateCommand {...props} />
      )}

      {component === 'ChangeEnableWithdrawsCommand' && (
        <ChangeEnableWithdrawsCommand {...props} />
      )}

      {component === 'ChangeEnableDepositsCommand' && (
        <ChangeEnableDepositsCommand {...props} />
      )}

      {component === 'ChangeGateDepositsCommand' && (
        <ChangeGateDepositsCommand {...props} />
      )}

      {component === 'AddMemberCommand' && <AddMemberCommand {...props} />}

      {component === 'RemoveMemberCommand' && (
        <RemoveMemberCommand {...props} />
      )}

      {component === 'ChangeEnableNewMembershipRequests' && (
        <ChangeEnableNewMembershipRequestsCommand {...props} />
      )}

      {component === 'RemoveZeroBalanceMemberCommand' && (
        <RemoveZeroBalanceMembersCommand {...props} />
      )}

      {component === 'ChangeRequireVoteSignature' && (
        <ChangeRequireVoteSignatureCommand {...props} />
      )}

      {component === 'ChangeVotingAnonymity' && (
        <ChangeVotingAnonymityCommand {...props} />
      )}

      {component === 'AddSignatorCommand' && <AddSignatorCommand {...props} />}

      {component === 'RemoveSignatorCommand' && (
        <RemoveSignatorCommand {...props} />
      )}

      {component === 'ChangeSafeThresholdCommand' && (
        <ChangeSafeThresholdCommand {...props} />
      )}

      {component === 'ChangeQuorumCommand' && (
        <ChangeVoteQuorumCommand {...props} />
      )}

      {component === 'ChangeMinEthContributionCommand' && (
        <ChangeMinEthContributionCommand {...props} />
      )}

      {component === 'CallRemoteCommand' && <CallRemoteCommand {...props} />}

      {component === 'LinkWalletCommand' && <LinkWalletCommand {...props} />}

      {component === 'UnlinkWalletCommand' && (
        <UnlinkWalletCommand {...props} />
      )}

      {component === 'ChangeDisableTokenTransfersCommand' && (
        <ChangeDisableTokenTransfersCommand {...props} />
      )}
    </div>
  )
}

export default ProposalCommand
