import { ProposalCommandType } from 'graphql/generated'
import { VoteDelegation } from '@upstreamapp/upstream-dao'
import CollectiveMembersDropdown from './CollectiveMembersDropdown'
import { useCollectiveContext } from 'context/CollectiveContext'
import EtherInputField from './EtherInputField'
import { BigNumber } from 'lib/BigInt'
import { ZERO_BN } from 'lib/collectives/helpers'
import {
  getCommandDescription,
  getDelegationLabel
} from '../modals/AddProposalModal/AddProposal/ProposalCommand.types'

export enum CustomDelegationValues {
  SURVEYS = 'SURVEYS'
}

type TCustomDelegationDetails = {
  [key in CustomDelegationValues]: Maybe<{
    label: string
    description: string
    selectedDelegationDescription: string
  }>
}

export const CustomDelegationDropdownDetails: TCustomDelegationDetails = {
  SURVEYS: {
    label: 'Polls',
    description: 'Select who can vote on polls for you',
    selectedDelegationDescription: 'Polls: You can vote on polls for the user'
  }
}

interface IDelegationGrantedItem {
  disabled?: boolean
  value: VoteDelegation.DelegationStruct
  onChange: CallbackWithParam<VoteDelegation.DelegationStruct | null>
}

const maxValueWhitelist = {
  [ProposalCommandType.SEND_ETHER]: 'ETH',
  [ProposalCommandType.SEND_TOKEN]: 'TOKEN'
}

function isCustomType(obj: any): obj is CustomDelegationValues {
  return Object.values(CustomDelegationValues).includes(obj)
}

export function DelegationGrantedItem({
  onChange,
  value
}: IDelegationGrantedItem) {
  const { collective } = useCollectiveContext()
  const commandType = value.command as
    | ProposalCommandType
    | CustomDelegationValues

  if (!commandType) {
    return null
  }

  const label = isCustomType(commandType)
    ? CustomDelegationDropdownDetails[commandType]?.label
    : getDelegationLabel(commandType)

  const description = isCustomType(commandType)
    ? CustomDelegationDropdownDetails[commandType]?.description
    : getCommandDescription(commandType, collective)

  if (!label) {
    return null
  }

  return (
    <div className="flex max-sm:flex-col hover:bg-gray-50 px-3 ">
      <div className="space-y-2 mt-3 mb-1 sm:mb-3 flex-1">
        <p className="text-gray-700 font-semibold text-sm">{label}</p>
        <p className="text-gray-500 font-light text-sm">{description}</p>
      </div>

      <div className="space-y-2 mb-3 mb-1 sm:mb-3 flex-1">
        <CollectiveMembersDropdown
          value={value.delegatee}
          onChange={delegatee => {
            onChange({ ...value, delegatee })
          }}
          collectiveId={collective.id}
          excludeCurrentMember={true}
          placeholder="Select a delegatee"
        />

        {maxValueWhitelist[value.command as ProposalCommandType] && (
          <div>
            <EtherInputField
              label={`Max Amount per proposal`}
              initialValueInWei={
                new BigNumber(value.maxValue ? value.maxValue.toString() : 0)
              }
              onChangeInWei={changeEvent => {
                onChange({
                  ...value,
                  maxValue: changeEvent.toEthersBigNumber()
                })
              }}
              minWei={ZERO_BN}
              type={maxValueWhitelist[value.command as ProposalCommandType]}
              collective={collective}
              symbol={
                value.command != ProposalCommandType.SEND_ETHER
                  ? collective.tokens[0].symbol
                  : ''
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}
