import EtherInputField from 'components/Collectives/Common/EtherInputField'
import { BigNumber } from 'lib/BigInt'
import { useCallback, useEffect, useState } from 'react'
import { ZERO_BN } from 'lib/collectives/helpers'
import { twMerge } from 'tailwind-merge'
import WalletAddressInputField from 'components/Collectives/Common/WalletAddressInputField'
import Typography from 'components/common/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { StyledSelect } from 'components/common/Select'
import { isPresent } from 'lib/helpers'
import { ethers } from 'ethers'
import FieldLabel from 'components/common/FieldLabel'
import { TAbiMethod, TAbiMethodInputOutput, TRemoteConnect } from './types'
import Spinner from 'components/Spinner'
import { SelectOption, SelectSingleValue } from './AbiSelectComponents'
import AbiInputFields from './AbiInputFields'
import useRemoteContractAbiHook from './useRemoteContractAbiHook'
import { Toggle } from 'components/common'
import TextField from 'components/common/TextField'

export function RemoteContractAbi({
  onValidityChange,
  setTarget,
  setAbiData,
  amountInWei,
  setAmountInWei
}: TRemoteConnect) {
  const [isProxy, setIsProxy] = useState(false)

  const {
    selectedAbiMethod,
    proxyAddress,
    setProxyAddress,
    contractAddress,
    proxyValid,
    setProxyValid,
    addressValid,
    abiInputValues,
    inputFieldErrors,
    abiLoading,
    setContractAddress,
    setAbiInputValues,
    contractAbi,
    setSelectedAbiMethod,
    showAbiTextField,
    setShowAbiTextField,
    loadingAbiError,
    setAddressValid,
    foundValidImpl
  } = useRemoteContractAbiHook()

  const clearSubmitData = useCallback(() => {
    onValidityChange(false)
    setAbiData('')
    setTarget('')
  }, [onValidityChange, setAbiData, setTarget])

  // check to update submit data if needed when inputs change
  useEffect(() => {
    // contract check
    if (
      (isProxy && !proxyAddress) ||
      (isProxy && !proxyValid) ||
      !contractAddress ||
      !addressValid
    ) {
      clearSubmitData()
      return
    }

    // data check
    if (
      !selectedAbiMethod ||
      !abiInputValues ||
      abiInputValues?.filter(
        val => isPresent(val) && (typeof val === 'string' ? !!val.length : true)
      ).length !== selectedAbiMethod.inputs?.length ||
      !!inputFieldErrors.filter(input =>
        Array.isArray(input)
          ? !!input.filter(subInput => !!subInput).length //array inputs
          : !!input
      ).length
      // || (selectedAbiMethod.stateMutability === 'payable' && amountInWei.eq(0)) //TODO: confirm this validation
    ) {
      clearSubmitData()
      return
    }

    const ContractInterface = new ethers.utils.Interface([selectedAbiMethod])
    try {
      const inputValues = abiInputValues.map(inputValue =>
        typeof inputValue === 'string' && inputValue.startsWith('[')
          ? JSON.parse(inputValue)
          : inputValue
      )

      const encodedAbiData = ContractInterface.encodeFunctionData(
        selectedAbiMethod.name,
        inputValues
      )
      setAbiData(encodedAbiData)
      if (isProxy && !!proxyAddress) {
        setTarget(proxyAddress)
      } else {
        setTarget(contractAddress)
      }
      onValidityChange(true)
    } catch (e) {
      clearSubmitData()
    }
  }, [
    selectedAbiMethod,
    abiInputValues,
    setAbiData,
    setTarget,
    contractAddress,
    onValidityChange,
    clearSubmitData,
    addressValid,
    inputFieldErrors,
    isProxy,
    proxyAddress,
    proxyValid
  ])

  const abiFailed =
    addressValid && !abiLoading && (!contractAbi || !!loadingAbiError)
  const abiLoaded = addressValid && !abiLoading && !!contractAbi

  return (
    <div className="space-y-4">
      <Toggle
        checked={isProxy}
        onChange={setIsProxy}
        label="Is Contract a Proxy?"
      />

      {isProxy && (
        <>
          <WalletAddressInputField
            label="Proxy Address:"
            defaultValue={contractAddress || undefined}
            onChange={address => {
              setProxyAddress(address)
            }}
            onValidityChange={setProxyValid}
            horizantalFullWidth
          />

          <TextField
            label="Found Implementation Address:"
            value={foundValidImpl && contractAddress ? contractAddress : ''}
            disabled
            horizantalFullWidth
            error={
              !foundValidImpl
                ? 'Could not find valid address, enter address manually below'
                : loadingAbiError || undefined
            }
          />

          {!foundValidImpl && (
            <WalletAddressInputField
              placeholder="Enter contract implementation address manually"
              label="Contract Address:"
              defaultValue={contractAddress || undefined}
              onChange={address => {
                setContractAddress(address)
              }}
              onValidityChange={setAddressValid}
              horizantalFullWidth
              error={loadingAbiError || undefined}
            />
          )}
        </>
      )}

      {!isProxy && (
        <WalletAddressInputField
          placeholder="Enter contract implementation address manually"
          label="Contract Address:"
          defaultValue={contractAddress || undefined}
          onChange={address => {
            setContractAddress(address)
          }}
          onValidityChange={setAddressValid}
          horizantalFullWidth
          error={loadingAbiError || undefined}
        />
      )}

      <div className="mt-4">
        <div className="w-full flex items-center justify-between">
          <FieldLabel label="Contract Function:" />

          <Typography
            className={twMerge(
              'flex justify-end items-center ',
              abiLoaded ? 'cursor-pointer' : 'cursor-not-allowed'
            )}
            size="xs"
            color={abiLoaded ? 'gray' : abiFailed ? 'error' : 'lightGray'}
            onClick={
              abiLoaded
                ? () => setShowAbiTextField(!showAbiTextField)
                : undefined
            }
          >
            {abiLoading ? (
              <Spinner />
            ) : abiLoaded ? (
              <>
                {`${
                  showAbiTextField ? 'Hide' : 'Show'
                } Raw Contract ABI Functions`}
                &nbsp;
                <FontAwesomeIcon icon={faInfoCircle} />
                &nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={twMerge(showAbiTextField ? 'rotate-90' : '')}
                />
              </>
            ) : null}
          </Typography>
        </div>

        <Typography
          size="sm"
          color="gray"
          component={'div'}
          className={twMerge(
            'px-2 rounded-md bg-gray-200 overflow-auto transition-all h-0 break-all',
            showAbiTextField && 'mb-2 h-36'
          )}
        >
          <Typography size="sm">Shows only: </Typography>
          <div>{`- 'function' type interfaces`}</div>
          <div>{`- non 'pure' and 'view' type functions`}</div>

          {contractAbi?.map((input: TAbiMethod) => (
            <div key={input.name} className="py-2">
              {JSON.stringify(input)}
            </div>
          ))}
        </Typography>

        <StyledSelect<TAbiMethod, false>
          height={47}
          options={contractAbi || []}
          value={contractAbi?.find(x => x.name === selectedAbiMethod?.name)}
          containerClassName={twMerge(
            'p-0.5',
            abiLoaded ? 'cursor-pointer' : 'cursor-not-allowed'
          )}
          placeholder={
            <Typography size="sm" fontWeight="light">
              {addressValid && contractAbi
                ? `Select ABI Method to call ( type to search )`
                : 'Enter a valid contract address above first'}
            </Typography>
          }
          onChange={x => {
            if (!x) {
              setSelectedAbiMethod(undefined)
              return
            }
            setSelectedAbiMethod(x)
            setAbiInputValues(new Array(x.inputs.length))
          }}
          isDisabled={!contractAbi?.length}
          components={{
            Option: SelectOption,
            SingleValue: SelectSingleValue
          }}
          getOptionLabel={method => method.name || method.stateMutability}
        />
      </div>

      {selectedAbiMethod &&
        (!!abiInputValues?.length ||
          selectedAbiMethod.stateMutability === 'payable') && (
          <div className="mt-4">
            <FieldLabel label="Method Inputs:" />

            <div className="space-y-3 px-4 pt-3 pb-4 border border-gray-200 rounded-md sm:max-h-[275px] overflow-auto bg-gray-50">
              {selectedAbiMethod.stateMutability === 'payable' && (
                <EtherInputField
                  label={`Payable Amount`}
                  disabled={selectedAbiMethod.stateMutability !== 'payable'}
                  initialValueInWei={
                    amountInWei.gt(0) ? amountInWei : BigNumber(0)
                  }
                  onChangeInWei={setAmountInWei}
                  minWei={ZERO_BN}
                  // error={!amountInWei.gt(0)}
                />
              )}

              {!!abiInputValues?.length &&
                selectedAbiMethod.inputs.map(
                  (input: TAbiMethodInputOutput, inputIndex) => (
                    <div key={inputIndex}>
                      <AbiInputFields
                        name={input.name}
                        type={input.type}
                        value={abiInputValues[inputIndex]}
                        onChange={(value: any) => {
                          const newData = [...abiInputValues]
                          newData[inputIndex] = value
                          setAbiInputValues(newData)
                        }}
                        index={inputIndex}
                        errors={inputFieldErrors[inputIndex]}
                      />
                    </div>
                  )
                )}
            </div>
          </div>
        )}
    </div>
  )
}
