import Thumbnail from 'components/Thumbnail'
import Link from 'next/link'
import { faCoins } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollectiveContext } from 'context/CollectiveContext'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import {
  formatToken,
  getEtherscanUrlForCollective
} from 'lib/collectives/helpers'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { noop } from 'lodash'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import { formatNumber } from 'lib/BigInt'
import useAuthWalletAndSignUp from 'hooks/useAuthWalletAndSignUp'
import React from 'react'
import TrackableClick from 'components/common/TrackableClick'
import SummaryItem from './SummaryItem'
import Typography from 'components/common/Typography'
import Button from 'components/Button'

export function UnauthedView() {
  const { collective, demoMode } = useCollectiveContext()

  const authWalletAndSignup = useAuthWalletAndSignUp()

  return (
    <>
      <div className={'p-4 flex flex-1 max-sm:flex-col max-sm:items-center'}>
        <div className={'mr-4 mb-4'}>
          <Thumbnail
            src={collective.thumb}
            initials={collective.name.substring(0)}
            className="h-20 w-20 rounded-full overflow-hidden"
          />
        </div>

        <div className={'flex-1 flex flex-wrap gap-4 sm:gap-8 px-4'}>
          <SummaryItem
            widthClass={'min-w-[45%] md:min-w-[20%]'}
            heading="Token"
            value={collective.tokens[0].symbol}
            link={
              <Link
                target="_blank"
                href={getEtherscanUrlForCollective(collective, 'token')}
              >
                <Typography size="xs" className={'pt-1 hover:text-blue'}>
                  <FontAwesomeIcon
                    icon={faCoins}
                    className="mr-1 text-gray-500"
                    size="1x"
                  />
                  {`${formatToken(collective.tokens[0].totalSupply, {
                    decimals: collective.tokens[0].decimals,
                    roundToDecimals: 2
                  })} supply >`}
                </Typography>
              </Link>
            }
          />
          <SummaryItem
            widthClass={'min-w-[45%] md:min-w-[20%]'}
            heading="DAO Wallet"
            value={
              <>
                <FontAwesomeIcon
                  icon={faEthereum}
                  className="mr-1 text-gray-500"
                  size="sm"
                />
                {formatToken(collective.treasuryBalance, {
                  roundToDecimals: 2
                })}
              </>
            }
          />

          <SummaryItem
            widthClass={'min-w-[45%] md:min-w-[20%]'}
            heading="Members"
            value={formatNumber(collective.totalMembers)}
          />

          <SummaryItem
            widthClass={'min-w-[45%] md:min-w-[20%]'}
            heading="Proposals"
            value={formatNumber(collective.totalProposals)}
          />
        </div>
      </div>

      <div className="p-[25px] pb-[30px] border-t border-t-gray-200 flex flex-row justify-around items-start">
        <div className="flex flex-col items-center">
          <Typography className="pb-3">
            {`Join this DAO by signing up for Upstream today.`}
          </Typography>

          <StyledTooltip arrow title={demoMode ? DemoTooltipText : ''}>
            <TrackableClick
              event={`Join Collective Button Clicked ${
                demoMode ? '-demo' : ''
              }`}
              props={{
                collectiveId: collective.id,
                collectiveName: collective.name
              }}
            >
              <Button
                size="lg"
                color="blue"
                label={`Join the ${collective.name}${
                  !collective.name.toLowerCase().endsWith('dao') ? ' DAO' : ''
                }`}
                onClick={demoMode ? noop : authWalletAndSignup}
              />
            </TrackableClick>
          </StyledTooltip>
        </div>
      </div>
    </>
  )
}
