import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { OptionProps, components } from 'react-select'
import {
  getCommandDescription,
  getCommandLabel,
  ICommandOption,
  TCollective
} from '../../modals/AddProposalModal/AddProposal/ProposalCommand.types'

interface IProps extends OptionProps<ICommandOption, false, any> {
  collective: TCollective
}

const ProposalCommandSelectOption = ({ collective, ...rest }: IProps) => {
  const command = rest.data as ICommandOption
  const label = getCommandLabel(command.renderForTypes[0], collective)
  const description = getCommandDescription(
    command.renderForTypes[0],
    collective
  )

  return (
    <components.Option {...rest} isSelected={false}>
      <div className="flex flex-row">
        <div className="relative pr-5 flex flex-col justify-center">
          <FontAwesomeIcon icon={command.icon} size={'lg'} />

          {!!command.modifierIcon && (
            <FontAwesomeIcon
              icon={command.modifierIcon}
              className={
                rest.isDisabled
                  ? 'absolute top-[5px] right-[5px] text-gray-300'
                  : 'absolute top-[5px] right-[5px] text-gray-500'
              }
              size={'lg'}
            />
          )}
        </div>

        <div className="flex flex-col justify-center">
          <Typography
            className={
              rest.isDisabled
                ? 'text-sm font-normal text-gray-500'
                : 'text-sm font-normal'
            }
          >
            {label}
          </Typography>

          <Typography className="text-xs font-normal text-gray-500">
            {description}
          </Typography>
        </div>
      </div>
    </components.Option>
  )
}

export default ProposalCommandSelectOption
