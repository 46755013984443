import Typography from 'components/common/Typography'
import Image from 'next/image'
import React, { useCallback, useMemo, useState } from 'react'
import cls from 'classnames'
import { useCollectiveContext } from 'context/CollectiveContext'
import {
  CategoryType,
  getCommandDescription,
  getCommandLabel,
  getCommandsForCategory,
  ICommandOption,
  TCommandComponent
} from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import Button from 'components/Button'
import { useConfirmPromptModal } from 'components/modals/ConfirmPromptModal'
import { useCreateProposalContext } from './CreateProposalContext'
import { startCase, toLower, uniq } from 'lodash'
import { inflect } from 'inflection'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { ProposalCommandInput } from 'graphql/generated'
import { usePreference } from 'hooks'
import { ProposalStepsContainer } from './common'

type CategoriesType = {
  label: string
  img: string
  type: CategoryType
}

const membersCategory = {
  label: 'Members',
  type: CategoryType.Members,
  img: '/static/commands/members.svg'
}

const categories: CategoriesType[] = [
  {
    label: 'Assets',
    type: CategoryType.Monetary,
    img: '/static/commands/money.svg'
  },
  membersCategory,
  {
    label: 'Settings',
    type: CategoryType.CollectiveSettings,
    img: '/static/commands/settings.svg'
  },
  {
    label: 'Signators',
    type: CategoryType.Signators,
    img: '/static/commands/signators.svg'
  },
  {
    label: 'Wallets',
    type: CategoryType.Wallet,
    img: '/static/commands/wallet.svg'
  }
]

const SelectCommand: React.FC = () => {
  const { setComponent, component, goBack, goForward, commands } =
    useCreateProposalContext()
  const confirm = useConfirmPromptModal()
  const { collective, demoMode } = useCollectiveContext()
  const [selectedCategory, setSelectedCategory] = useState<
    Maybe<CategoriesType>
  >(demoMode ? membersCategory : categories[0])

  const isSuperAdmin = usePreference('isSuperAdmin', false)
  const commandOptionsByCategory = useMemo(
    () =>
      selectedCategory
        ? getCommandsForCategory(
            collective.governanceType,
            selectedCategory.type
          ).filter(command => !command.beta || isSuperAdmin)
        : [],
    [collective.governanceType, selectedCategory, isSuperAdmin]
  )

  const confirmCancel = useCallback(() => {
    confirm({
      title: 'Cancel adding a new command?',
      body: 'You have not finished selecting the new command to add. Did you mean to return to the command summary step?',
      onClick: () => {
        goForward(true)
      }
    })
  }, [confirm, goForward])

  return (
    <ProposalStepsContainer
      buttons={
        <>
          <Button
            label={'Cancel'}
            color="lightgray"
            onClick={() => (commands?.length ? confirmCancel() : goBack(true))}
            className=""
          />
          <Button
            label="Next"
            className="ml-2"
            disabled={!component}
            onClick={() => goForward(false)}
          />
        </>
      }
    >
      <Typography>Select a category:</Typography>
      <div className="flex w-full flex-wrap rounded-lg mt-4  gap-4">
        {categories.map(category => {
          const isSelected = category.type === selectedCategory?.type

          return (
            <div
              className={cls('flex flex-col text-center cursor-pointer w-24')}
              key={category.label}
              onClick={() => {
                setSelectedCategory(category)
              }}
            >
              <div
                className={cls(
                  'mb-2 w-24 h-18 bg-[#f3f4f6] rounded-md items-center flex justify-center',
                  isSelected && 'ring-2 ring-viz-yesActive'
                )}
              >
                <Image
                  src={category.img}
                  height={40}
                  width={40}
                  alt="Upstream Collective Vote"
                />
              </div>

              <Typography size="sm" className="break-normal">
                {category.label}
              </Typography>
            </div>
          )
        })}
      </div>

      {!!selectedCategory && (
        <div className="mt-8">
          <Typography>
            Select a command that your proposal should execute:
          </Typography>

          <Typography
            className="break-normal mt-8 max-sm:mt-3"
            color="gray"
            fontWeight="light"
            size="sm"
          >
            {selectedCategory?.label}
          </Typography>

          <div className="flex flex-wrap mt-4 gap-4">
            {commandOptionsByCategory.map((option, index) => (
              <SelectCommandOptionButton
                key={`option_${index}`}
                option={option}
                commands={commands}
                component={component}
                setComponent={setComponent}
              />
            ))}
          </div>
        </div>
      )}
    </ProposalStepsContainer>
  )
}

function SelectCommandOptionButton({
  option,
  setComponent,
  commands,
  component
}: {
  option: ICommandOption
  setComponent: CallbackWithParam<TCommandComponent>
  commands: ProposalCommandInput[]
  component: Maybe<TCommandComponent>
}) {
  const { collective, demoMode } = useCollectiveContext()

  const label = getCommandLabel(option.renderForTypes[0], collective)
  const description = getCommandDescription(
    option.renderForTypes[0],
    collective
  )

  const disableDueToOtherExistingCommands = uniq(
    commands
      .filter(({ type }) =>
        option.disableSelectIfOtherExistingCommandTypes?.includes(type)
      )
      .map(t => t.type)
  )

  const commandNames = `'${disableDueToOtherExistingCommands
    ?.map(t => startCase(toLower(t.replace(/_/g, ' '))))
    .join(`', '`)}'`

  const tooltip = disableDueToOtherExistingCommands.length
    ? `${description}. - Disabled due to the already added ${inflect(
        'commands',
        disableDueToOtherExistingCommands.length
      )} ${commandNames}`
    : description

  const isDisabled =
    (demoMode && option.component !== 'AddMemberCommand') ||
    !!disableDueToOtherExistingCommands.length

  return (
    <StyledTooltip arrow key={label} title={tooltip}>
      <Button
        label={label}
        onClick={() => !isDisabled && setComponent(option.component)}
        color={component === option.component ? undefined : 'lightgray'}
        className={'min-w-[132px] h-[45px]'}
        disabled={isDisabled}
      />
    </StyledTooltip>
  )
}

export default SelectCommand
