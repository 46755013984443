import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CollectiveMemberFieldsFragment,
  ProposalCommandType,
  useDelegatesGrantedToViewerQuery
} from 'graphql/generated'
import { StateNotice } from 'components/Collectives/modals/Web3ActionModal'
import DelegationsGrantedToViewerItem from './DelegationsGrantedToViewerItem'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'

interface IProps {
  children?: React.ReactNode
  onClose?: Callback
  collectiveId: string
}

export type DelegatorCommand = {
  command: Maybe<ProposalCommandType>
  maxValue: string | bigint
  wildcard: Maybe<boolean>
  surveys: Maybe<boolean>
}

export type DelegatorGranted = {
  commands: DelegatorCommand[]
  collectiveMember: CollectiveMemberFieldsFragment
}

export default function DelegationsGrantedToViewerList({
  collectiveId,
  onClose
}: IProps) {
  const { data, loading } = useDelegatesGrantedToViewerQuery({
    variables: {
      collectiveId
    },
    fetchPolicy: 'cache-and-network'
  })

  const delegationsByAddress: { [key: string]: DelegatorGranted } =
    useMemo(() => {
      if (!data?.collective?.delegatesGrantedToViewer) {
        return {}
      }

      return data.collective.delegatesGrantedToViewer.reduce((acc, val) => {
        const address = val.collectiveMember.user.address

        if (!acc[address]) {
          acc[address] = {
            commands: [],
            collectiveMember: val.collectiveMember
          }
        }

        acc[address].commands.push({
          command: val.command,
          maxValue: val.maxValue || BigInt(0),
          wildcard: val.wildcard,
          surveys: val.surveys
        })

        return acc
      }, {})
    }, [data])

  if (loading) {
    return (
      <div className="py-4">
        <StateNotice label={`Loading the delegations granted to you`}>
          <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
        </StateNotice>
      </div>
    )
  }

  return (
    <div className="flex-1 flex flex-col">
      {Object.keys(delegationsByAddress).length > 0 ? (
        <div className="flex-1 sm:max-h-[400px] overflow-auto border border-gray-200 rounded divide-y divide-gray-200">
          {Object.entries(delegationsByAddress).map(([address, delegator]) => (
            <DelegationsGrantedToViewerItem
              key={address}
              address={address}
              delegator={delegator}
            />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500 text-sm font-light py-4">
          No one has delegated their votes to you, yet
        </p>
      )}

      <div className="flex justify-end pt-6">
        <Button onClick={onClose} color="outline" label="Close" />
      </div>
    </div>
  )
}
