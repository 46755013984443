import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnCollectiveMembershipRequested: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnCollectiveMembershipRequested'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`requested to join the DAO`}
    detailsActionText={() => `requested to join the DAO`}
  />
)

export default CollectiveLogDataOnCollectiveMembershipRequested
