import dynamic from 'next/dynamic'
import { useEventEmitterContext } from 'context/EventEmitterContext'
import cls from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'
import WalletListItem from './WalletListItem'
import CombinedWalletsListItem from './CombinedWalletsListItem'
import { without } from 'lodash'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import Spinner from 'components/Spinner'
import Typography from 'components/common/Typography'
import { useBreakpoint } from 'hooks/useBreakpoint'
import CollectiveDetailsSectionWrapper from '../CollectiveDetailsSectionWrapper'

const Chart = dynamic(() => import('./Chart'), {
  ssr: false,
  loading: () => <Spinner />
})

export const SHOW_WALLETS = 'show_wallets'

function CollectiveWallets() {
  const { collective, demoMode } = useCollectiveContext()

  const { currentStep } = useCollectiveDemoContext()

  const [show, setShow] = useState(false)

  const [alreadyShown, setAlreadyShown] = useState(demoMode ? false : true)

  useEffect(() => {
    if (demoMode && currentStep === 5 && !alreadyShown) {
      if (!show) {
        setShow(true)
        setAlreadyShown(true)
      }
    }
  }, [currentStep, demoMode, alreadyShown, show])

  const hasMultipleWallets = collective.wallets.length > 1

  const rootElement = useRef<HTMLDivElement>(null!)
  const { emitter } = useEventEmitterContext()

  useEffect(() => {
    // emitter doesn't exist in SSR
    if (typeof window === 'undefined' || !emitter) {
      return
    }

    function showWalletsCard() {
      setShow(true)
      rootElement.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }

    emitter.on(SHOW_WALLETS, showWalletsCard)

    return () => {
      emitter.off(SHOW_WALLETS, showWalletsCard)
    }
  }, [emitter])

  const [chartCombined, setChartCombined] = useState(hasMultipleWallets)
  const [chartWalletIds, setChartWalletIds] = useState(
    collective.wallets.map(x => x.id)
  )

  const { isSm } = useBreakpoint()
  return (
    <div className={'bg-gray-50 p-2 sm:p-4 rounded-md'} ref={rootElement}>
      <CollectiveDetailsSectionWrapper
        title="DAO Wallets"
        description="Treasury and Linked Wallets Value."
        show={show}
        setShow={setShow}
        badge={collective.wallets.length > 1 ? collective.wallets.length : 0}
      />

      {show && (
        <div className="mt-5">
          {(chartWalletIds.length > 0 ||
            (hasMultipleWallets && chartCombined)) && (
            <>
              {demoMode && (
                <div className="p-2 text-center border-red-600 rounded-lg mb-2 border">
                  <Typography color="error">
                    For demo purposes, the chart and stats are not live
                  </Typography>
                </div>
              )}
              <div className="relative">
                <Chart
                  walletIds={chartWalletIds}
                  combined={hasMultipleWallets && chartCombined}
                />
              </div>
            </>
          )}

          <div className="sm:hidden py-2">
            <Typography size="lg">{`Wallets:`}</Typography>
          </div>

          <div className="mt-[10px] rounded-[10px] overflow-hidden border-[1px] border-gray-200">
            <table className="w-full">
              <thead className="">
                {isSm && (
                  <tr>
                    <th className="p-0 m-0">&nbsp;</th>
                    <th className="p-[10px]">
                      <p
                        className={cls(
                          'font-sans text-xs font-medium text-gray-500 uppercase text-right break-keep whitespace-nowrap',
                          'text-left'
                        )}
                      >{`Wallet`}</p>
                    </th>
                    <th className="p-[10px]">
                      <p className="font-sans text-xs font-medium text-gray-500 uppercase text-right break-keep whitespace-nowrap">{`Balance`}</p>
                    </th>
                    <th className="p-[10px]">
                      <p className="font-sans text-xs font-medium text-gray-500 uppercase text-right break-keep whitespace-nowrap">{`Tokens`}</p>
                    </th>
                    <th className="p-[10px]">
                      <p className="font-sans text-xs font-medium text-gray-500 uppercase text-right break-keep whitespace-nowrap">{`NFTs`}</p>
                    </th>
                    <th className="p-[10px]">
                      <p className="font-sans text-xs font-medium text-gray-500 uppercase text-right break-keep whitespace-nowrap">{`Total Value`}</p>
                    </th>
                    <th className="p-[10px]">&nbsp;</th>
                  </tr>
                )}
              </thead>

              <tbody className="bg-white border-b-[1px] border-b-gray-200">
                {collective.wallets.map((wallet, walletIndex) => (
                  <WalletListItem
                    key={`wallet-${wallet.id}`}
                    wallet={wallet}
                    walletIndex={walletIndex}
                    charted={chartWalletIds.includes(wallet.id)}
                    onToggleChart={() =>
                      setChartWalletIds(curr =>
                        curr.includes(wallet.id)
                          ? without(curr, wallet.id)
                          : [...curr, wallet.id]
                      )
                    }
                  />
                ))}
              </tbody>

              {hasMultipleWallets && (
                <tfoot className="border-t-gray-200 border-t-4">
                  <CombinedWalletsListItem
                    charted={chartCombined}
                    onToggleChart={() => setChartCombined(!chartCombined)}
                  />
                </tfoot>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default CollectiveWallets
