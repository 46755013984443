import { BigNumber } from 'lib/BigInt'
import { useState, useCallback, useEffect } from 'react'
import { SignClientTypes, SessionTypes } from '@walletconnect/types'
import { Core } from '@walletconnect/core'
import Web3WalletType, { Web3Wallet } from '@walletconnect/web3wallet'
import { buildApprovedNamespaces } from '@walletconnect/utils'
import { useWeb3 } from 'context/Web3Context'
import { useCollectiveContext } from 'context/CollectiveContext'
import { getChainIdForNetwork } from 'lib/collectives/helpers'
import { IEthTx } from './useWalletConnect'

const IS_PROD = process.env.NODE_ENV === 'production'
const WALLETCONNECT_V2_PROJECT_ID = 'ae6ebf59941207ec1c2af8e7ab537cb0'
const EVMBasedNamespaces = 'eip155'

// see full list here: https://github.com/safe-global/safe-apps-sdk/blob/main/packages/safe-apps-provider/src/provider.ts#L35
export const compatibleCollectiveMethods: string[] = [
  'eth_accounts',
  'net_version',
  'eth_chainId',
  'personal_sign',
  'eth_sign',
  'eth_signTypedData',
  'eth_signTypedData_v4',
  'eth_sendTransaction',
  'eth_blockNumber',
  'eth_getBalance',
  'eth_getCode',
  'eth_getTransactionCount',
  'eth_getStorageAt',
  'eth_getBlockByNumber',
  'eth_getBlockByHash',
  'eth_getTransactionByHash',
  'eth_getTransactionReceipt',
  'eth_estimateGas',
  'eth_call',
  'eth_getLogs',
  'eth_gasPrice',
  'wallet_getPermissions',
  'wallet_requestPermissions',
  'safe_setSettings'
]

// see https://docs.walletconnect.com/2.0/specs/sign/error-codes
const UNSUPPORTED_CHAIN_ERROR_CODE = 5100
const INVALID_METHOD_ERROR_CODE = 1001
const USER_REJECTED_REQUEST_CODE = 4001
const USER_DISCONNECTED_CODE = 6000

const logger = IS_PROD ? undefined : 'debug'

export type wcConnectType = (uri: string) => Promise<void>
export type wcDisconnectType = () => Promise<void>
export interface IProps {
  onEthTransaction: CallbackWithParam<IEthTx>
}

type useWalletConnectType = {
  wcClientData: SignClientTypes.Metadata | undefined
  wcConnect: wcConnectType
  wcDisconnect: wcDisconnectType
  isWallectConnectInitialized: boolean
  error: string | undefined
}

export const areStringsEqual = (string1: string, string2: string): boolean =>
  string1.toLowerCase() === string2.toLowerCase()

const useWalletConnectV2 = ({
  onEthTransaction
}: IProps): useWalletConnectType => {
  const [web3wallet, setWeb3wallet] = useState<Web3WalletType>()

  const [wcSession, setWcSession] = useState<SessionTypes.Struct>()
  const [isWallectConnectInitialized, setIsWallectConnectInitialized] =
    useState<boolean>(false)
  const [error, setError] = useState<string>()
  // const [chainInfo, setChainInfo] = useState<ChainInfo>()

  const { collective } = useCollectiveContext()
  const { signer, signerAddress } = useWeb3()
  const provider = signer?.provider
  const chainId = getChainIdForNetwork(collective.network).replace('0x', '')

  // Initializing v2, see https://docs.walletconnect.com/2.0/javascript/web3wallet/wallet-usage
  useEffect(() => {
    const initializeWalletConnectV2Client = async () => {
      const core = new Core({
        projectId: WALLETCONNECT_V2_PROJECT_ID,
        logger
      })

      const web3wallet = await Web3Wallet.init({
        core,
        metadata: {
          name: 'Upstream',
          description: 'The most trusted platform to manage DAOs',
          url: 'https://upstreamapp.com',
          icons: [
            // 'https://app.safe.global/favicons/logo_120x120.png'
          ]
        }
      })

      setWeb3wallet(web3wallet)
    }

    try {
      initializeWalletConnectV2Client()
    } catch (error) {
      console.log('Error on walletconnect version 2 initialization: ', error)
      setIsWallectConnectInitialized(true)
    }
  }, [])

  // session_request needs to be a separate Effect because a valid wcSession should be present
  useEffect(() => {
    if (isWallectConnectInitialized && web3wallet && wcSession) {
      web3wallet.on('session_request', async event => {
        const { topic, id } = event
        const { request, chainId: transactionChainId } = event.params
        const { method, params } = request

        const isCollectiveChainId =
          transactionChainId === `${EVMBasedNamespaces}:${chainId}`

        if (!isCollectiveChainId) {
          const errorMessage = `Transaction rejected: the connected Dapp is not set to the correct chain. Make sure the Dapp only uses ${collective.network} to interact with this Collective.`
          setError(errorMessage)
          await web3wallet.respondSessionRequest({
            topic,
            response: rejectResponse(
              id,
              UNSUPPORTED_CHAIN_ERROR_CODE,
              errorMessage
            )
          })
          return
        }

        try {
          setError(undefined)

          let result = '0x'

          switch (method) {
            case 'eth_sendTransaction': {
              const txInfo = params[0]
              onEthTransaction({
                to: txInfo.to,
                data: txInfo.data || '0x',
                value: txInfo.value
                  ? new BigNumber(txInfo.value)
                  : new BigNumber(0)
              })
              result = '0x'
              break
            }
            case 'gs_multi_send': {
              result = '0x'
              break
            }

            case 'personal_sign': {
              const [message, address] = params
              if (!areStringsEqual(address, collective.address)) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('personal_sign', [
                message,
                signerAddress
              ])

              result = signature
              break
            }

            case 'eth_sign': {
              const [address, messageHash] = params
              if (
                !areStringsEqual(address, collective.address) ||
                !messageHash.startsWith('0x')
              ) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('personal_sign', [
                messageHash,
                signerAddress
              ])

              result = signature
              break
            }

            case 'eth_signTypedData':
            case 'eth_signTypedData_v1':
            case 'eth_signTypedData_v3':
            case 'eth_signTypedData_v4': {
              const [address, typedDataJson] = params
              if (!areStringsEqual(address, collective.address)) {
                console.log('The address or message hash is invalid', {
                  address,
                  collectiveAddress: collective.address
                })
                throw new Error('The address or message hash is invalid')
              }

              const signature = await provider?.send('eth_signTypedData_v4', [
                signerAddress,
                typedDataJson
              ])

              result = signature
              break
            }

            default: {
              throw new Error(`METHOD_NOT_SUPPORTED`)
            }
          }

          await web3wallet.respondSessionRequest({
            topic,
            response: {
              id,
              jsonrpc: '2.0',
              result
            }
          })
        } catch (error: any) {
          setError(error?.message)
          const isUserRejection = error?.message?.includes?.(
            'Transaction was rejected'
          )
          const code = isUserRejection
            ? USER_REJECTED_REQUEST_CODE
            : INVALID_METHOD_ERROR_CODE
          await web3wallet.respondSessionRequest({
            topic,
            response: rejectResponse(id, code, error.message)
          })
        }
      })
    }
  }, [
    chainId,
    collective.network,
    collective.address,
    onEthTransaction,
    signerAddress,
    wcSession,
    isWallectConnectInitialized,
    web3wallet,
    provider
  ])

  // we set here the events & restore an active previous session
  useEffect(() => {
    if (!isWallectConnectInitialized && web3wallet) {
      // we try to find a compatible active session
      const activeSessions = web3wallet.getActiveSessions()
      const compatibleSession = Object.keys(activeSessions)
        .map(topic => activeSessions[topic])
        .find(
          session =>
            session.namespaces[EVMBasedNamespaces].accounts[0] ===
            `${EVMBasedNamespaces}:${chainId}:${collective.address}`
        )

      if (compatibleSession) {
        setWcSession(compatibleSession)
      }

      // events
      web3wallet.on('session_proposal', async proposal => {
        const { id, params } = proposal
        const { requiredNamespaces } = params

        const requiredEIP155Namespace = requiredNamespaces[EVMBasedNamespaces]

        console.log('Session proposal: ', proposal)

        // EVM-based (eip155) namespace should be present
        const isEIP155NamespacePresent = !!requiredEIP155Namespace

        if (!isEIP155NamespacePresent) {
          const errorMessage = getConnectionErrorMessage(
            'chains error',
            collective.network
          )
          setError(errorMessage)

          await web3wallet.rejectSession({
            id: proposal.id,
            reason: {
              code: UNSUPPORTED_CHAIN_ERROR_CODE,
              message: `Unsupported chains. No EVM-based (${EVMBasedNamespaces}) namespace present in the session proposal`
            }
          })
          return
        }

        const isCollectiveChainIdPresent = requiredEIP155Namespace.chains?.some(
          chain => chain === `${EVMBasedNamespaces}:${chainId}`
        )
        if (!isCollectiveChainIdPresent) {
          const errorMessage = getConnectionErrorMessage(
            'chains error',
            collective.network
          )
          setError(errorMessage)

          await web3wallet.rejectSession({
            id: proposal.id,
            reason: {
              code: UNSUPPORTED_CHAIN_ERROR_CODE,
              message: `Unsupported chains. No ${collective.network} (${EVMBasedNamespaces}:${chainId}) namespace present in the session proposal`
            }
          })
          return
        }

        const collectiveChain = `${EVMBasedNamespaces}:${chainId}`
        const collectiveAccount = `${EVMBasedNamespaces}:${chainId}:${collective.address}`
        const collectiveEvents = requiredEIP155Namespace.events // we accept all events like chainChanged & accountsChanged (even if they are not compatible with the Safe)

        try {
          const approvedCollectiveNamespaces = buildApprovedNamespaces({
            proposal: params,
            supportedNamespaces: {
              eip155: {
                chains: [collectiveChain],
                methods: compatibleCollectiveMethods,
                events: collectiveEvents,
                accounts: [collectiveAccount]
              }
            }
          })

          const wcSession = await web3wallet.approveSession({
            id,
            namespaces: approvedCollectiveNamespaces
          })

          setWcSession(wcSession)
          setError(undefined)
        } catch (error: any) {
          console.log('error: ', error)
          console.log('error: ', error.message)
          const errorMessage = getConnectionErrorMessage(
            error.message,
            collective.network
          )
          setError(errorMessage)
        }
      })

      web3wallet.on('session_delete', async () => {
        setWcSession(undefined)
        setError(undefined)
      })

      setIsWallectConnectInitialized(true)
    }
  }, [
    web3wallet,
    isWallectConnectInitialized,
    chainId,
    collective.network,
    collective.address
  ])

  const wcConnect = useCallback<wcConnectType>(
    async (uri: string) => {
      const isValidWalletConnectUri = uri && uri.startsWith('wc')

      if (isValidWalletConnectUri && web3wallet) {
        await web3wallet.core.pairing.pair({ uri })
      }
    },
    [web3wallet]
  )

  const wcDisconnect = useCallback<wcDisconnectType>(async () => {
    if (wcSession && web3wallet) {
      await web3wallet.disconnectSession({
        topic: wcSession.topic,
        reason: {
          code: USER_DISCONNECTED_CODE,
          message:
            'User disconnected. Collective Wallet Session ended by the user'
        }
      })
      setWcSession(undefined)
      setError(undefined)
    }
  }, [web3wallet, wcSession])

  const wcClientData = wcSession?.peer.metadata

  return {
    wcConnect,
    wcClientData,
    wcDisconnect,
    isWallectConnectInitialized,
    error
  }
}

export default useWalletConnectV2

const rejectResponse = (id: number, code: number, message: string) => {
  return {
    id,
    jsonrpc: '2.0',
    error: {
      code,
      message
    }
  }
}

const getConnectionErrorMessage = (
  errorMessage = '',
  chainName = ''
): string => {
  const isChainError = errorMessage.includes('chains')

  if (isChainError) {
    return `Connection refused: Incompatible chain detected. Make sure the Dapp only uses ${chainName} to interact with this Collective.`
  }

  const isMethodError = errorMessage.includes('methods')

  if (isMethodError) {
    return 'Connection refused: Incompatible methods between the Dapp and the Collective Account detected.'
  }

  return errorMessage
}
