import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnCollectiveSyncRequested: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnCollectiveSyncRequested'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`queued the DAO to be force synced to the Blockchain`}
    detailsActionText={() =>
      `queued the DAO to be force synced to the Blockchain`
    }
  />
)

export default CollectiveLogDataOnCollectiveSyncRequested
