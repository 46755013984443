import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { useCollectiveContext } from 'context/CollectiveContext'
import { BigNumber } from 'lib/BigInt'
import { ERCAsset, CollectiveWallet } from 'graphql/generated'
import { faExpand, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { formatToken, getEtherscanUrl } from 'lib/collectives/helpers'
import listItemClasses from './useListItemStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeldInHotWalletNotice from './HeldInHotWalletNotice'
import { useEffect, useMemo, useState } from 'react'
import { BaseListItem } from './BaseListItem'
import { twMerge } from 'tailwind-merge'
import BaseModal from 'components/modals/BaseModal'
import ModalTrigger from 'components/common/ModalTrigger'
import RoundedIconButton from 'components/common/RoundedIconButton'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'

interface IProps {
  asset: Pick<
    ERCAsset,
    | 'name'
    | 'balance'
    | 'estimatedValueEth'
    | 'estimatedValueUSD'
    | 'address'
    | 'mediaUrls'
    | 'tokenId'
    | 'externalUrl'
  > & { wallet?: Maybe<Pick<CollectiveWallet, 'id' | 'isMainWallet'>> }
}

const VideoFormats = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf'
]

const ImageFormats = [
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'bmp',
  'ico',
  'cur',
  'tif',
  'tiff',
  'webp',
  'apng',
  'avif'
]

function NFTAssetListItem({ asset }: IProps) {
  const { collective } = useCollectiveContext()

  const [isError, setIsError] = useState(!asset.mediaUrls.length)
  const [isLoading, setIsLoading] = useState(!isError)
  const [triedMediaIndex, setTriedMedia] = useState(0)

  const currentUrl =
    !!asset.mediaUrls.length && parseMediaUrl(asset.mediaUrls[triedMediaIndex])
  const isTypeVideo = useMemo(
    () =>
      VideoFormats.some(
        format =>
          typeof currentUrl === 'string' &&
          currentUrl.toLowerCase().endsWith(format)
      ),
    [currentUrl]
  )
  const isImageType = useMemo(
    () =>
      ImageFormats.some(
        format =>
          typeof currentUrl === 'string' &&
          currentUrl.toLowerCase().endsWith(format)
      ),
    [currentUrl]
  )
  const noExtension = !isTypeVideo && !isImageType
  const [noExtensionTryAsImage, setNoExtensionTryAsImage] =
    useState(noExtension)

  useEffect(() => {
    setNoExtensionTryAsImage(noExtension)
  }, [noExtension]) //if media url changes, this can change too

  const totalAssetUrls = asset.mediaUrls.length - 1

  const content = currentUrl ? (
    isImageType || noExtensionTryAsImage ? (
      // image or no extension
      <img
        className={twMerge(
          listItemClasses.mediaImage,
          'object-cover hover:object-contain',
          isError && 'object-contain'
        )}
        src={currentUrl}
        onLoad={() => {
          setIsLoading(false)
        }}
        onError={({ currentTarget }) => {
          if (noExtensionTryAsImage) {
            setNoExtensionTryAsImage(false)
          } else if (triedMediaIndex !== totalAssetUrls) {
            setTriedMedia(triedMediaIndex + 1)
          } else if (!isError) {
            setIsError(true)
            setIsLoading(false)
            currentTarget.onerror = null // prevents looping
          }
        }}
      />
    ) : (
      <video
        autoPlay={false}
        controls
        loop
        playsInline
        muted
        controlsList="nodownload"
        preload="metadata"
        className={twMerge(
          listItemClasses.mediaImage,
          'object-cover hover:object-contain',
          isError && 'object-contain'
        )}
        onCanPlay={() => {
          setIsLoading(false)
        }}
        onError={({ currentTarget }) => {
          if (!noExtensionTryAsImage && noExtension) {
            setNoExtensionTryAsImage(noExtension)
          }
          if (triedMediaIndex !== totalAssetUrls) {
            setTriedMedia(triedMediaIndex + 1)
          } else if (!isError) {
            setIsError(true)
            setIsLoading(false)
            currentTarget.onerror = null // prevents looping
          }
        }}
      >
        <source src={currentUrl} />
      </video>
    )
  ) : null

  return (
    <BaseListItem
      isError={isError}
      isLoading={isLoading}
      topContent={
        asset.mediaUrls.length > 0 &&
        !isError && (
          <ModalTrigger
            trigger={onClick => (
              <div
                className={twMerge(
                  'w-full h-full relative group/content ',
                  !isError && 'cursor-pointer'
                )}
                onClick={e => !isError && onClick(e)}
              >
                {content}
                {!isError && (
                  <div className="absolute right-2 bottom-2 opacity-60 group-hover/content:opacity-80">
                    <RoundedIconButton size="xs" icon={faExpand} />
                  </div>
                )}
              </div>
            )}
          >
            <BaseModal disableBackdropClick>{content}</BaseModal>
          </ModalTrigger>
        )
      }
      bottomContent={
        <>
          <div className="flex justify-between items-center">
            <div className="flex-1 overflow-hidden">
              <div className={listItemClasses.nameText}>
                {asset.tokenId
                  ? asset.name?.replace(`#${asset.tokenId}`, '')
                  : asset.name}
              </div>

              <p className="max-w-[120px] text-sm font-light text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                #{asset.tokenId}
              </p>
            </div>

            <DropdownMenu
              buttonClassName="-mr-1"
              items={[
                {
                  label: 'View on OpenSea',
                  icon: faExternalLink,
                  href: `https://opensea.io/assets/${asset.address}/${asset.tokenId}`,
                  target: '_blank'
                },
                {
                  label: 'View on Etherscan',
                  icon: faExternalLink,
                  href: getEtherscanUrl(
                    collective.network,
                    'token',
                    asset.address
                  ),
                  target: '_blank'
                },
                !!asset.externalUrl && {
                  label: 'View Project Website',
                  icon: faExternalLink,
                  href: asset.externalUrl,
                  target: '_blank'
                }
              ]}
            />
          </div>

          {!asset.wallet?.isMainWallet && (
            <HeldInHotWalletNotice
              className={listItemClasses.walletNoticeText}
              asset={asset}
              collective={collective}
            />
          )}

          <div className="flex justify-between items-end">
            <div>
              {!!asset.estimatedValueEth && (
                <p className={listItemClasses.estimatedValueEthText}>
                  <FontAwesomeIcon
                    icon={faEthereum}
                    className={listItemClasses.estimatedValueEthIcon}
                  />
                  {formatToken(asset.estimatedValueEth)}
                </p>
              )}
              <p className={listItemClasses.estimatedValueUSDText}>
                {asset.estimatedValueUSD
                  ? `(~$${new BigNumber(asset.estimatedValueUSD).toFormat(2)})`
                  : ``}
              </p>
            </div>

            <div className="text-right">
              <p
                className={listItemClasses.amountText}
              >{`${asset.balance}x`}</p>
              <p className={listItemClasses.estimatedValueUSDText}>QTY</p>
            </div>
          </div>
        </>
      }
    />
  )
}

function parseMediaUrl(url: string) {
  if (url.startsWith('ipfs://')) {
    const hash = url.replace('ipfs://', '')
    return `https://cloudflare-ipfs.com/ipfs/${hash}`
  }

  return url
}

export default NFTAssetListItem
