import { Collective, useSyncCollectiveMutation } from 'graphql/generated'

type TCollective = Pick<Collective, 'id'>

function useSyncCollectiveToBlockchain() {
  const [doSyncCollective, { loading }] = useSyncCollectiveMutation()

  async function syncCollective(collective: TCollective) {
    return doSyncCollective({
      variables: {
        collectiveId: collective.id,
        proposalConnection: {
          first: 5
        }
      }
    })
  }

  return {
    syncCollective,
    loading
  }
}

export default useSyncCollectiveToBlockchain
