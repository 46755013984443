import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect } from 'react'
import WalletAddressInputField from '../../../Common/WalletAddressInputField'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'

function AddMemberCommand({
  disabled,
  data,
  onChange,
  onValidityChange
}: IProposalCommandInputProps) {
  const { demoMode } = useCollectiveContext()
  const { currentStep, possibleMembers } = useCollectiveDemoContext()

  const [memberAddress, setMemberAddress] = useState<string>(
    demoMode && currentStep === 2
      ? possibleMembers[2].address
      : data.memberAddress || ''
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.ADD_MEMBER,
      memberAddress
    })
    // eslint-disable-next-line
  }, [memberAddress])

  const [isAddressValid, setIsAddresValid] = useState(false)
  const isValid = !!memberAddress && isAddressValid
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="flex pt-4">
        <WalletAddressInputField
          label={`Member Address`}
          defaultValue={memberAddress}
          onChange={setMemberAddress}
          onValidityChange={setIsAddresValid}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default AddMemberCommand
