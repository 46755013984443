import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'
import { uniqBy } from 'lodash'
import { LogCollectiveUserPopover } from './LogCollectiveUserPopover'

const CollectiveLogDataOnDelegationsRevoked: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnDelegationsRevoked'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`revoked their voting delegations`}
    detailsActionText={log => {
      const uniqueDelegations = uniqBy(log.data.delegations, 'delegatee')
      return (
        <>
          {`revoked voting delegations from `}
          {uniqueDelegations.map((delegation, i) => (
            <span key={`delegation_${i}`}>
              {i > 0 &&
                (i === uniqueDelegations.length - 1 ? (
                  <>&nbsp;&&nbsp;</>
                ) : (
                  <>,&nbsp;</>
                ))}
              <LogCollectiveUserPopover user={delegation.delegatee} />
            </span>
          ))}
        </>
      )
    }}
  />
)

export default CollectiveLogDataOnDelegationsRevoked
