import BigNumber from 'bignumber.js'
import Thumbnail from 'components/Thumbnail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { OptionProps, components } from 'react-select'
import { faPhotoVideo } from '@fortawesome/pro-regular-svg-icons'
import { ERCAsset } from 'graphql/generated'

const nameContainerClass = 'flex flex-col justify-center flex-1 overflow-hidden'

const CollectiveNFTSelectOption = ({
  ...rest
}: OptionProps<ERCAsset, false, any>) => {
  const nft = rest.data
  const contractType =
    nft.contractType === 'ERC721' || nft.contractType === 'ERC1155'
      ? 'NFTAsset'
      : 'ERC20Asset'

  return (
    <components.Option {...rest} isSelected={false}>
      <div className="flex flex-row">
        <div className="min-w-[50px] w-[50px] flex flex-row justify-center items-center">
          {contractType === 'NFTAsset' && !!nft.mediaUrls[0] ? (
            <Thumbnail
              useImgTag
              src={nft.mediaUrls[0]}
              initials={nft?.name?.charAt(0)}
              className={'h-8 w-8 rounded-full overflow-hidden'}
            />
          ) : contractType === 'ERC20Asset' && !!nft.thumb ? (
            <Thumbnail
              useImgTag
              src={nft.thumb}
              initials={nft?.name?.charAt(0)}
              className={'h-8 w-8 rounded-full overflow-hidden'}
            />
          ) : (
            <FontAwesomeIcon
              icon={faPhotoVideo}
              className="h-12 w-12 rounded-full overflow-hidden"
            />
          )}
        </div>

        {contractType === 'NFTAsset' && (
          <div className={nameContainerClass}>
            <Typography size="sm">
              {nft.name} {nft.symbol !== '(n/a)' && `(${nft.symbol})`}
            </Typography>

            <Typography color="gray">Token ID: {nft.tokenId}</Typography>

            <Typography
              color="gray"
              size="xs"
              className={'overflow-hidden text-ellipsis'}
            >
              NFT ({nft.contractType}) / {nft.address}
            </Typography>
          </div>
        )}
        {contractType === 'ERC20Asset' && (
          <div className={nameContainerClass}>
            <Typography size="sm">
              {nft.name === nft.symbol
                ? `$${nft.symbol} (${new BigNumber(nft.balance)
                    .shiftedBy(-(nft.decimals || 0))
                    .toFormat(2)})`
                : `${nft.name} (${new BigNumber(nft.balance)
                    .shiftedBy(-(nft.decimals || 0))
                    .toFormat(2)} ${nft.symbol})`}
            </Typography>

            <Typography
              color="gray"
              size="xs"
              className={'overflow-hidden text-ellipsis'}
            >
              ERC20 / {nft.address}
            </Typography>
          </div>
        )}
      </div>
    </components.Option>
  )
}

export default CollectiveNFTSelectOption
