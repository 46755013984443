import { uniqBy } from 'lodash'
import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnWalletLinked: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnWalletLinked'>
> = ({ bundledCollectiveLog, isLast }) => {
  const uniqueWallets = uniqBy(
    bundledCollectiveLog.logs,
    log => log.data.address
  )
  const hasMany = uniqueWallets.length === 1
  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={
        <>
          {!hasMany ? `Wallet ` : `${uniqueWallets.length} Wallets `}
          {!hasMany && (
            <LogEtherscanTxLink
              txHash={bundledCollectiveLog.logs[0].data.address}
              type="address"
            />
          )}
          {` ${!hasMany ? 'was' : 'were'} linked`}
        </>
      }
      hideSummaryActors
      detailsActionText={log => (
        <>
          {`Wallet ${log.data.nickname ? log.data.nickname : ''} `}
          <LogEtherscanTxLink txHash={log.data.address} type="address" />
          {` was linked to the DAO`}
        </>
      )}
    />
  )
}

export default CollectiveLogDataOnWalletLinked
