import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalExecuted: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnProposalExecuted'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      isLast={isLast}
      bundle={bundledCollectiveLog}
      summaryActionText={`executed `}
      singleProposalText={'proposal'}
      detailsActionText={() => `executed `}
      summaryManyProposalText={count => ` ${count} proposals`}
    />
  )
}

export default CollectiveLogDataOnProposalExecuted
