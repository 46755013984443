import { cloneElement, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Typography from './Typography'

type Tab = {
  label: string
  onSelectDisplay?: React.ReactElement
  onClick?: CallbackWithParams<Tab, number>
  customButton?: React.ReactElement
}

interface BasicTabsProps {
  tabs: Tab[]
  style?: 'roundedCenter' | 'leftAligned'
  selectedIndex: number
  onChange: CallbackWithParams<Tab, number>
  className?: string
  wrapperClassName?: string
  tabClassName?: string
}

export default function Tabs({
  tabs,
  style = 'roundedCenter',
  selectedIndex,
  onChange,
  className,
  wrapperClassName,
  tabClassName
}: BasicTabsProps) {
  const [selected, setSelected] = useState<number>(selectedIndex)

  const handleChange = (newValueIndex: number) => {
    setSelected(newValueIndex)
    onChange(tabs[newValueIndex], newValueIndex)
  }

  return (
    <div className={twMerge('flex flex-col overflow-hidden', wrapperClassName)}>
      <div
        className={twMerge(
          style === 'roundedCenter' &&
            'shrink-0 flex justify-center items-center border border-gray-200 rounded-xl mb-4 overflow-hidden',
          style === 'leftAligned' &&
            'shrink-0 flex items-center border-b border-gray-200 overflow-hidden',
          className
        )}
      >
        {tabs.map((tab, i) =>
          tab.customButton ? (
            cloneElement(tab.customButton, {
              key: `tab_${i}`,
              onClick: () => {
                handleChange(i)
                tab.customButton?.props.onClick?.(i)
              }
            })
          ) : (
            <div
              key={`tab_${i}`}
              className={twMerge(
                'flex-1 bg-gray-200 flex justify-center items-center py-2 cursor-pointer h-full',
                style === 'roundedCenter'
                  ? selected !== i
                    ? 'hover:bg-gray-100'
                    : 'bg-gray-50'
                  : '',
                style === 'leftAligned' && 'py-1',
                style === 'leftAligned'
                  ? selected !== i
                    ? 'hover:bg-gray-100'
                    : 'bg-gray-50'
                  : '',
                tabClassName
              )}
              onClick={() => handleChange(i)}
            >
              <Typography color={selected === i ? 'default' : 'gray'} size="sm">
                {tab.label}
              </Typography>
            </div>
          )
        )}
      </div>

      {tabs[selected].onSelectDisplay}
    </div>
  )
}
