import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalsBatched: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnProposalsBatched'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`batched ${
      bundledCollectiveLog.logs.length === 1
        ? bundledCollectiveLog.logs[0].data.proposal?.batchedProposals.length
        : ''
    } proposals`}
    detailsActionText={log =>
      `batched ${log.data.proposal?.batchedProposals.length || 0} proposals`
    }
  />
)

export default CollectiveLogDataOnProposalsBatched
