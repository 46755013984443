import { useCallback, useEffect, useMemo, useState } from 'react'
import { useContractAbiQueryLazyQuery } from 'graphql/generated'
import { ethers } from 'ethers'
import { TAbiMethod } from './types'
import { useCollectiveContext } from 'context/CollectiveContext'
import { isValidEthAddress } from 'components/Collectives/Common/WalletAddressInputField'
import { useWeb3 } from 'context/Web3Context'

export default function useRemoteContractAbiHook() {
  const { collective } = useCollectiveContext()

  const [contractAddress, setContractAddress] = useState<Maybe<string>>()
  const [addressValid, setAddressValid] = useState<Maybe<boolean>>()
  const [proxyAddress, setProxyAddress] = useState<Maybe<string>>()
  const [proxyValid, setProxyValid] = useState<Maybe<boolean>>()
  const [loadingAbiError, setLoadingAbiError] = useState<Maybe<string>>()

  // once address is valid and abi is loaded
  const [contractAbi, setContractAbi] = useState<Maybe<TAbiMethod[]>>()
  const [showAbiTextField, setShowAbiTextField] =
    useState<Maybe<boolean>>(false)

  // once abi method is selected and inputs are entered
  const [selectedAbiMethod, setSelectedAbiMethod] =
    useState<Maybe<TAbiMethod>>()
  const [abiInputValues, setAbiInputValues] = useState<Maybe<any[]>>()

  const clearAbiDataStates = useCallback(() => {
    setLoadingAbiError(null)
    setShowAbiTextField(false)
    setContractAbi(null)
    setSelectedAbiMethod(null)
    setAbiInputValues(null)
  }, [])

  const [getContractAbi, { loading: abiLoading }] =
    useContractAbiQueryLazyQuery({
      nextFetchPolicy: 'network-only',
      onError: err => {
        setLoadingAbiError(err.message)
      },
      onCompleted: data => {
        setContractAbi(
          data.contractAbi.abi.filter(
            (abiItem: TAbiMethod) =>
              abiItem.type === 'function' &&
              !['view', 'pure'].includes(abiItem.stateMutability)
          )
        )
      }
    })

  // on address change, try loading abi else clear
  useEffect(() => {
    const fetchAbi = async () => {
      if (addressValid && contractAddress) {
        clearAbiDataStates()
        await getContractAbi({
          variables: {
            address: contractAddress,
            network: collective.network
          }
        })
      } else {
        clearAbiDataStates()
      }
    }
    fetchAbi()
  }, [
    addressValid,
    clearAbiDataStates,
    collective.network,
    contractAddress,
    getContractAbi
  ])

  // inidividual field errors
  const inputFieldErrors: Array<boolean | boolean[]> = useMemo(() => {
    if (!selectedAbiMethod || !addressValid) {
      return []
    }
    if (!abiInputValues?.length) {
      return new Array(selectedAbiMethod.inputs.length)
    }

    const testAbiPerDataInput = abiInputValues.map((inputValue, i) => {
      if (!inputValue) {
        return false // no error
      }
      const ContractInterface = new ethers.utils.Interface([
        {
          ...selectedAbiMethod,
          inputs: [{ ...selectedAbiMethod.inputs[i] }]
        }
      ])

      //array input types
      if (Array.isArray(inputValue)) {
        return inputValue.map(subInputValue => {
          try {
            if (!subInputValue) {
              return false
            }
            ContractInterface.encodeFunctionData(selectedAbiMethod.name, [
              [subInputValue]
            ])
            return false
          } catch (e) {
            return true // some error for that field only
          }
        })
      }

      try {
        ContractInterface.encodeFunctionData(selectedAbiMethod.name, [
          inputValue
        ])
        return false
      } catch (e) {
        return true // some error for that field only
      }
    })
    return testAbiPerDataInput
  }, [abiInputValues, addressValid, selectedAbiMethod])

  const { web3 } = useWeb3()
  const [foundValidImpl, setFoundValidImpl] = useState(false)
  useEffect(() => {
    if (!web3) {
      return
    }
    if (!proxyAddress || !proxyValid) {
      return
    }
    const getContractImplFromProxy = async () => {
      setContractAddress(undefined)
      setAddressValid(false)
      setFoundValidImpl(false)

      if (proxyAddress) {
        let implAddress = await web3.getStorageAt(
          proxyAddress,
          '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc'
        )
        if (
          !implAddress ||
          !isValidEthAddress(
            `0x${implAddress.substring(implAddress.length - 40)}`
          )
        ) {
          implAddress = await web3.getStorageAt(
            proxyAddress,
            '0x7050c9e0f4ca769c69bd3a8ef740bc37934f8e2c036e5a723fd8ee048ed3f8c3'
          )
        }
        if (
          implAddress &&
          isValidEthAddress(
            `0x${implAddress.substring(implAddress.length - 40)}`
          )
        ) {
          const address = `0x${implAddress.substring(implAddress.length - 40)}`
          setContractAddress(address)
          setAddressValid(true)
          setFoundValidImpl(true)
        }
      }
    }
    getContractImplFromProxy()
  }, [proxyAddress, proxyValid, setAddressValid, setContractAddress, web3])

  return {
    clearAbiDataStates,
    contractAddress,
    setContractAddress,
    setProxyAddress,
    foundValidImpl,
    setProxyValid,
    proxyValid,
    proxyAddress,
    addressValid,
    setAddressValid,
    loadingAbiError,
    setLoadingAbiError,
    contractAbi,
    setContractAbi,
    showAbiTextField,
    setShowAbiTextField,
    abiLoading,
    inputFieldErrors,
    selectedAbiMethod,
    setSelectedAbiMethod,
    abiInputValues,
    setAbiInputValues
  }
}
