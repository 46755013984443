import { BigNumber } from 'lib/BigInt'
import Typography from 'components/common/Typography'
import PanelStepper from 'components/common/PanelStepper'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCreateProposalContext } from './CreateProposalContext'
import ProposalCommandsStep from './ProposalCommandsStep'
import ProposalDetailsStep from './ProposalDetailsStep'
import SelectCommand from './SelectCommandStep'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import WalletCheckInfo from 'components/Collectives/Common/WalletCheckInfo'

const CreateProposalForm: React.FC = () => {
  const { collective, demoMode } = useCollectiveContext()
  const { showWalletNotice } = useWalletCheck(collective)
  const {
    commands = [],
    setCurrentStep,
    currentStep,
    setComponent,
    saving
  } = useCreateProposalContext()

  const steps = [
    {
      label: 'Select Command',
      disabled: saving
    },
    {
      label: 'Command Data',
      count: commands.length,
      disabled: saving || !commands.length
    },
    {
      label: 'Proposal Info',
      disabled: saving || !commands.length
    }
  ]

  const hasMinimumBalance =
    collective.membership &&
    new BigNumber(collective.parameters.proposalThreshold).lte(
      collective.membership.totalTokens
    )

  const canCreate = hasMinimumBalance && (!showWalletNotice || !!demoMode)

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="p-5 mb-4 max-sm:mb-2">
        <div className="mb-6 pt-2 ">
          <Typography size="xl" fontWeight="bold">
            Create a New Proposal
          </Typography>
        </div>

        <PanelStepper
          steps={steps}
          setCurrentStep={index => {
            setCurrentStep(index)
            setComponent(undefined)
          }}
          currentStep={currentStep}
        />
      </div>

      {!hasMinimumBalance && (
        <div className="flex w-full justify-center items-center pt-2 pb-10">
          <div className="bg-red-200 px-5 py-1.5 rounded-full">
            <p>{`You don't have enough tokens to create a proposal`}</p>
          </div>
        </div>
      )}

      {!demoMode && (
        <WalletCheckInfo
          collective={collective}
          className="bg-red-50 hover:bg-red-100 px-5 py-3 rounded-lg my-3 mx-3"
        />
      )}

      {canCreate && (
        <>
          {currentStep === 0 && <SelectCommand />}

          {currentStep === 1 && (
            <ProposalCommandsStep collective={collective} />
          )}

          {currentStep === 2 && <ProposalDetailsStep collective={collective} />}
        </>
      )}
    </div>
  )
}

export default CreateProposalForm
