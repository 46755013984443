import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import WalletAddressInputField from '../../../Common/WalletAddressInputField'
import TextField from 'components/common/TextField'

function LinkWalletCommand({
  disabled,
  data,
  onChange,
  onValidityChange
}: IProposalCommandInputProps) {
  const [target, setTarget] = useState<string>(data.target || '')
  const [walletNickname, setWalletNickname] = useState<string>(
    data.walletNickname || ''
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.LINK_WALLET,
      target,
      walletNickname
    })
    // eslint-disable-next-line
  }, [target, walletNickname])

  const [isAddressValid, setIsAddresValid] = useState(false)
  const isValid = useMemo(
    () => !!target && !!walletNickname && isAddressValid,
    [target, walletNickname, isAddressValid]
  )
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        <WalletAddressInputField
          label={`Wallet Address`}
          defaultValue={target}
          onChange={setTarget}
          disabled={disabled}
          onValidityChange={setIsAddresValid}
        />
      </div>

      <div className="pt-4">
        <TextField
          horizantalFullWidth
          label={`Wallet Nickname`}
          placeholder={`Hot Wallet #1`}
          defaultValue={walletNickname}
          onChange={e => setWalletNickname(e.target.value)}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default LinkWalletCommand
