import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, ChangeEvent, useMemo } from 'react'
import TextField from 'components/common/TextField'
import { Typography } from 'components/common'

const MIN = 1
const MAX = 100_000_000

function ChangeExchangeRateCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const defaultValue =
    data.exchangeRate || collective.parameters.exchangeRate || 1000
  const [exchangeRate, setExchangeRate] = useState<number>(defaultValue)

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      let value = Math.ceil(+event.target.value)
      if (value < MIN) {
        value = MIN
      } else if (value > MAX) {
        value = MAX
      } else if (!Number.isFinite(value)) {
        value = defaultValue
      }

      setExchangeRate(value)
    } catch (ex) {
      setExchangeRate(defaultValue)
    }
  }

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CHANGE_EXCHANGE_RATE,
      exchangeRate
    })
    // eslint-disable-next-line
  }, [exchangeRate])

  const isValid = useMemo(
    () =>
      exchangeRate > 0 && exchangeRate !== collective.parameters.exchangeRate,
    [exchangeRate, collective.parameters.exchangeRate]
  )
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="flex pt-4 justify-center items-center">
        <div>
          <Typography>{`1 ETH = `}</Typography>
        </div>

        <div>
          <TextField
            horizantalFullWidth
            placeholder={'1000'}
            value={exchangeRate}
            onChange={onTextChange}
            disabled={disabled}
            postContent={<Typography>{collective.tokens[0].symbol}</Typography>}
          />
        </div>
      </div>
    </div>
  )
}

export default ChangeExchangeRateCommand
