import { MAIN_CHART_COLORS } from './WalletColors'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Typography } from 'components/common'
import { useCollectiveContext } from 'context/CollectiveContext'
import { ISummary, useWalletSummary } from 'hooks/collectives/useWalletSummary'
import { useBreakpoint } from 'hooks/useBreakpoint'

interface IProps {
  charted: boolean
  onToggleChart: Callback
}

const narrowCellClasses = 'py-0 px-[5px] bg-gray-100 '
const mobileNarrowCellClasses =
  'p-5 px-0 text-center bg-gray-100 align-baseline'
const cellClasses = 'py-[15px] px-[10px] bg-gray-100'
const ethIcon = 'text-gray-500 mr-[2px]'
const usdTextClasses = 'pt-[5px]'

type TListItem = {
  charted: boolean
  onToggleChart: Callback
  summary: ISummary
}

const MobileItem: React.FC<TListItem> = ({
  charted,
  onToggleChart,
  summary
}) => {
  return (
    <tr className="wallet-mobile-row">
      <td className={mobileNarrowCellClasses}>
        <input
          type={'checkbox'}
          color="primary"
          checked={charted}
          onChange={onToggleChart}
          style={{
            color: MAIN_CHART_COLORS[0][0],
            width: 24,
            height: 24
          }}
        />
      </td>

      <td>
        <table width={'100%'}>
          <tbody>
            <tr>
              <td className={cellClasses} colSpan={4}>
                <Typography
                  size="sm"
                  className={'break-keep whitespace-nowrap'}
                >{`Combined Value`}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <table width={'100%'}>
                  <tbody>
                    <tr>
                      <td className={cellClasses}>
                        <p
                          className={
                            'font-medium text-xs text-gray-500 uppercase text-left break-keep whitespace-nowrap'
                          }
                        >{`Balance`}</p>
                        <Typography
                          className={'pt-[10px]'}
                          fontWeight="light"
                          size="sm"
                          color="darkGray"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={ethIcon}
                          />
                          {summary.balance.ethFormatted}
                        </Typography>
                        <Typography
                          fontWeight="light"
                          color="gray"
                          size="sm"
                          className={usdTextClasses}
                        >{`$${summary.balance.usd}`}</Typography>
                      </td>

                      <td className={cellClasses}>
                        <p
                          className={
                            'font-medium text-xs text-gray-500 uppercase text-left break-keep whitespace-nowrap'
                          }
                        >{`Tokens`}</p>
                        <Typography
                          className={'pt-[10px]'}
                          fontWeight="light"
                          size="sm"
                          color="darkGray"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={ethIcon}
                          />
                          {summary.tokens.ethFormatted}
                        </Typography>
                        <Typography
                          fontWeight="light"
                          color="gray"
                          size="sm"
                          className={usdTextClasses}
                        >{`$${summary.tokens.usd}`}</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td className={cellClasses}>
                        <p
                          className={
                            'font-medium text-xs text-gray-500 uppercase text-left break-keep whitespace-nowrap'
                          }
                        >{`NFTs`}</p>
                        <Typography
                          className={'pt-[10px]'}
                          fontWeight="light"
                          size="sm"
                          color="darkGray"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={ethIcon}
                          />
                          {summary.nfts.ethFormatted}
                        </Typography>
                        <Typography
                          fontWeight="light"
                          color="gray"
                          size="sm"
                          className={usdTextClasses}
                        >{`$${summary.nfts.usd}`}</Typography>
                      </td>

                      <td className={cellClasses}>
                        <p
                          className={
                            'font-medium text-xs text-gray-500 uppercase text-left break-keep whitespace-nowrap'
                          }
                        >{`Total Value`}</p>
                        <Typography
                          className={'pt-[10px]'}
                          fontWeight="light"
                          size="sm"
                          color="darkGray"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={ethIcon}
                          />
                          {summary.total.ethFormatted}
                        </Typography>
                        <Typography
                          fontWeight="light"
                          color="gray"
                          size="sm"
                          className={usdTextClasses}
                        >{`$${summary.total.usd}`}</Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>

      <td align="right" className={mobileNarrowCellClasses} />
    </tr>
  )
}

const FullListItem: React.FC<TListItem> = ({
  charted,
  onToggleChart,
  summary
}) => {
  return (
    <tr>
      <td className={narrowCellClasses}>
        <div className="ml-3">
          <Checkbox
            customColor={MAIN_CHART_COLORS[0][0]}
            checked={charted}
            size={'lg'}
            onChange={onToggleChart}
          />
        </div>
      </td>

      <td className={cellClasses}>
        <Typography
          className={'break-keep whitespace-nowrap'}
          size="sm"
        >{`Combined Value`}</Typography>
      </td>

      <td align="right" className={cellClasses}>
        <Typography
          className={'pt-[10px]'}
          fontWeight="light"
          size="sm"
          color="darkGray"
        >
          <FontAwesomeIcon icon={faEthereum} className={ethIcon} />
          {summary.balance.ethFormatted}
        </Typography>
        <Typography
          fontWeight="light"
          color="gray"
          size="sm"
          className={usdTextClasses}
        >{`$${summary.balance.usd}`}</Typography>
      </td>

      <td align="right" className={cellClasses}>
        <Typography
          className={'pt-[10px]'}
          fontWeight="light"
          size="sm"
          color="darkGray"
        >
          <FontAwesomeIcon icon={faEthereum} className={ethIcon} />
          {summary.tokens.ethFormatted}
        </Typography>
        <Typography
          fontWeight="light"
          color="gray"
          size="sm"
          className={usdTextClasses}
        >{`$${summary.tokens.usd}`}</Typography>
      </td>

      <td align="right" className={cellClasses}>
        <Typography
          className={'pt-[10px]'}
          fontWeight="light"
          size="sm"
          color="darkGray"
        >
          <FontAwesomeIcon icon={faEthereum} className={ethIcon} />
          {summary.nfts.ethFormatted}
        </Typography>
        <Typography
          fontWeight="light"
          color="gray"
          size="sm"
          className={usdTextClasses}
        >{`$${summary.nfts.usd}`}</Typography>
      </td>

      <td align="right" className={cellClasses}>
        <Typography
          className={'pt-[10px]'}
          fontWeight="light"
          size="sm"
          color="darkGray"
        >
          <FontAwesomeIcon icon={faEthereum} className={ethIcon} />
          {summary.total.ethFormatted}
        </Typography>
        <Typography
          fontWeight="light"
          color="gray"
          size="sm"
          className={usdTextClasses}
        >{`$${summary.total.usd}`}</Typography>
      </td>

      <td align="right" className={narrowCellClasses}>
        <div />
      </td>
    </tr>
  )
}

function CombinedWalletsListItem({ charted, onToggleChart }: IProps) {
  const { collective } = useCollectiveContext()
  const summary = useWalletSummary(collective, collective.wallets, {
    ethFormattedDecimals: 2
  })

  const { isSm } = useBreakpoint()
  return isSm ? (
    <FullListItem
      charted={charted}
      onToggleChart={onToggleChart}
      summary={summary}
    />
  ) : (
    <MobileItem
      charted={charted}
      onToggleChart={onToggleChart}
      summary={summary}
    />
  )
}

export default CombinedWalletsListItem
