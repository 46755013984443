import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnProposalRequireVoteSignatureChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnDisableTokenTransfersChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    summaryActionText={`Setting "Token Transferability" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? bundledCollectiveLog.logs[0].data.disableTokenTransfers
          ? `to 'disabled'`
          : `to 'enabled'`
        : ''
    }`}
    hideSummaryActors
    detailsActionText={log => (
      <>
        {`Token Transferability was changed to `}
        {log.data.disableTokenTransfers ? `'Disabled'` : `'Enabled'`}
      </>
    )}
  />
)

export default CollectiveLogDataOnProposalRequireVoteSignatureChanged
