import dynamic from 'next/dynamic'
import Spinner from 'components/Spinner'
import ProposalDurationSelector from '../AddProposalModal/ProposalDurationSelector'
import {
  SurveyQuestions,
  IProps as ISurveyQuestionsProps
} from './SurveyQuestions'
import { ChangeEvent } from 'react'
import TextField from 'components/common/TextField'
import FieldLabel from 'components/common/FieldLabel'

export const QuillNoSSRWrapper = dynamic(
  () => import('components/QuillNoSSR'),
  {
    ssr: false,
    // eslint-disable-next-line react/display-name
    loading: () => <Spinner />
  }
)

interface IProps extends ISurveyQuestionsProps {
  title: string
  setTitle: (e: ChangeEvent<HTMLInputElement>) => void
  durationInHours: number
  setDurationInHours: CallbackWithParam<number>
  description: string
  setDescription: CallbackWithParam<string>
}

function DetailedSurveyForm({
  questions,
  onChange,
  onValidityChange,
  title,
  setTitle,
  durationInHours,
  setDurationInHours,
  description,
  setDescription
}: IProps) {
  return (
    <div className="flex-1 space-y-4 overflow-auto">
      <div className="flex flex-col sm:flex-row w-full max-sm:space-y-4">
        <div className="sm:w-4/6">
          <TextField
            autoFocus
            label="Poll Title"
            placeholder={`Enter a short title for your poll`}
            value={title}
            onChange={setTitle}
            horizantalFullWidth
          />
        </div>

        <div className="sm:mt-0 sm:w-2/6 sm:pl-2">
          <FieldLabel label="Voting Duration" />

          <ProposalDurationSelector
            value={durationInHours}
            onChange={setDurationInHours}
          />
        </div>
      </div>

      <div className="flex flex-col w-full">
        <FieldLabel label={`Poll Description`} />

        <QuillNoSSRWrapper
          detailsHtml={description}
          setDetailsHtml={setDescription}
          placeholder={`Describe your poll in more detail here`}
        />
      </div>

      <div className="flex flex-col w-full">
        <FieldLabel label={`Begin a poll by adding questions below`} />

        <SurveyQuestions
          questions={questions}
          onChange={onChange}
          onValidityChange={onValidityChange}
        />
      </div>
    </div>
  )
}

export default DetailedSurveyForm
