import { useMemo } from 'react'
import { BaseProfileFieldsFragment } from 'graphql/generated'
import Thumbnail from 'components/Thumbnail'
import { compactNumber } from 'lib/helpers'
import { twMerge } from 'tailwind-merge'

interface IProps {
  profiles: Pick<BaseProfileFieldsFragment, 'id' | 'thumb'>[]
  size?: number
  totalProfiles?: number
  spacing?: string
  className?: string
}

const FacePile: React.FC<IProps> = ({
  profiles,
  totalProfiles = 0,
  size = 12,
  spacing,
  className
}) => {
  const showAddtlIndicator = useMemo(
    () => totalProfiles > profiles.length,
    [totalProfiles, profiles.length]
  )

  return (
    <div className={twMerge('flex flex-col sm:flex-row', spacing, className)}>
      {profiles.map(profile => (
        <Thumbnail
          key={`profile-${profile.id}`}
          src={profile.thumb}
          className={`h-${size} w-${size} rounded-full overflow-hidden ring-2 ring-white`}
        />
      ))}

      {showAddtlIndicator && (
        <div
          className={`h-${size} w-${size} rounded-full overflow-hidden bg-gray-200 flex justify-center items-center ring-2 ring-white z-50`}
        >
          <p className="text-xs font-light text-text-primary">{`+${compactNumber(
            totalProfiles - profiles.length
          )}`}</p>
        </div>
      )}
    </div>
  )
}

export default FacePile
