import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'
import { LogCollectiveUserPopover } from './LogCollectiveUserPopover'

const CollectiveLogDataOnVoteInvalidated: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnVoteInvalidated'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    singleProposalText={'for proposal'}
    summaryActionText={`${
      bundledCollectiveLog.collectiveUsers.length > 2 ? `member's ` : `'s`
    } vote was invalidated due to member's token balance change`}
    detailsActionText={log => (
      <>
        <LogCollectiveUserPopover user={log.collectiveUser} />
        {`'s vote was invalidated due to member's token balance change`}
      </>
    )}
    hideDetailActors
  />
)

export default CollectiveLogDataOnVoteInvalidated
