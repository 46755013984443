import CollectiveUserPopover from 'components/Collectives/Common/CollectiveUserPopover'
import { TCollectiveBundledLog, TDataTypes } from './common'

export function LogCollectiveUserPopover<T extends TDataTypes>({
  user,
  withSpaceAfter = false
}: {
  user?: TCollectiveBundledLog<T>['logs'][0]['collectiveUser']
  withSpaceAfter?: boolean
}) {
  if (!user) {
    return null
  }
  return (
    <>
      <CollectiveUserPopover user={user} noAvatar style="dotted" />
      {withSpaceAfter && <>&nbsp;</>}
    </>
  )
}
