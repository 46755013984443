import CollectiveToken from '../../Common/CollectiveTokenPopover'
import BaseCollectiveLogListItem, {
  LogEtherscanTxLink
} from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnTransferBatch: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnTransferBatch'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={`transferred ${
        bundledCollectiveLog.logs.length
      } ERC-1155 NFT
       ${bundledCollectiveLog.logs.length === 1 ? 'asset' : 'assets'}
      `}
      detailsActionText={log => {
        const ids = log.data.tokenIds.map(
          (id, i) =>
            `${
              i > 1
                ? i === log.data.tokenIds.length - 1
                  ? ' & #'
                  : ', #'
                : '#'
            }${id}`
        )
        return (
          <>
            {`sent ${log.data.tokenIds.length} `}
            {log.data.token && (
              <CollectiveToken token={log.data.token} removeTokenFromName />
            )}
            {log.data.tokenIds.length === 1 ? `token` : `tokens`}
            {` (token ID ${ids})`}
            {` to address `}
            <LogEtherscanTxLink txHash={log.data.to} type="address" />
          </>
        )
      }}
    />
  )
}

export default CollectiveLogDataOnTransferBatch
