import { SingleValue } from 'react-select'
import { useCallback, useState } from 'react'
import {
  COMMAND_OPTIONS,
  ICommandOption,
  TCommandComponent
} from '../modals/AddProposalModal/AddProposal/ProposalCommand.types'
import ProposalCommandSelectOption from './SelectComponents/ProposalCommandSelectOption'
import ProposalCommandSelectSingleValue from './SelectComponents/ProposalCommandSelectSingleValue'
import { ProposalCommandType } from 'graphql/generated'
import { StyledSelect } from 'components/common/Select'

export interface IProposalCommandInputProps {
  disabled?: boolean
  value: ProposalCommandType
  onChange: CallbackWithParam<ProposalCommandType>
  blacklist?: ProposalCommandType[]
}

const originalTransferAssetComponent = COMMAND_OPTIONS.find(
  v => v.component === 'TransferAssetCommand'
)

const specialOptionCases = {
  [ProposalCommandType.TRANSFER_TOKENS]: {
    ...originalTransferAssetComponent,
    renderForTypes: [ProposalCommandType.TRANSFER_TOKENS],
    label: 'Transfer ERC20 tokens',
    description: `Send ERC20 tokens from the DAO's wallet to a given recipient`
  },
  [ProposalCommandType.TRANSFER_ERC721]: {
    ...originalTransferAssetComponent,
    renderForTypes: [ProposalCommandType.TRANSFER_ERC721],
    label: 'Transfer ERC721 tokens',
    description: `Send ERC721 tokens from the DAO's wallet to a given recipient`
  },
  [ProposalCommandType.TRANSFER_ERC1155]: {
    ...originalTransferAssetComponent,
    renderForTypes: [ProposalCommandType.TRANSFER_ERC1155],
    label: 'Transfer ERC1155 tokens',
    description: `Send ERC1155 tokens from the DAO's wallet to a given recipient`
  }
}

const DelegationCommandOptions = COMMAND_OPTIONS.reduce((total, current) => {
  current.renderForTypes.forEach(v => {
    const effectiveOption =
      specialOptionCases[v] ||
      ({ ...current, renderForTypes: [v] } as ICommandOption)
    total.push(effectiveOption)
  })

  return total
}, [] as ICommandOption[])

export function getOptionForDelegationCommandType(
  type: ProposalCommandType
): Maybe<ICommandOption> {
  return DelegationCommandOptions.find(option =>
    option.renderForTypes.includes(type)
  )
}

export function DelegationCommandInput(props: IProposalCommandInputProps) {
  const [component, setComponent] = useState<Maybe<TCommandComponent>>(
    getOptionForDelegationCommandType(props.value)?.component
  )

  const cleanedOptions = DelegationCommandOptions.filter(option =>
    option.renderForTypes.every(
      v => props.value === v || !props.blacklist?.includes(v)
    )
  )

  const handleChange = useCallback(
    (v: SingleValue<ICommandOption>) => {
      if (v) {
        setComponent(v.component)
        props.onChange(v.renderForTypes[0] as ProposalCommandType)
      }
    },
    [props]
  )

  return (
    <div>
      <StyledSelect<ICommandOption, false>
        height={50}
        options={cleanedOptions}
        value={component && cleanedOptions.find(x => x.component === component)}
        placeholder={`Select Command to delegate`}
        onChange={v => v && handleChange(v)}
        components={{
          Option: ProposalCommandSelectOption,
          SingleValue: ProposalCommandSelectSingleValue
        }}
      />
    </div>
  )
}

export default DelegationCommandInput
