import { formatToken } from 'lib/collectives/helpers'

import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnMemberWithdrewFunds: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnMemberWithdrewFunds'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`withdrew Ether from the DAO`}
    detailsActionText={log =>
      `withdrew ${formatToken(log.data.etherAmount)} ether from the DAO`
    }
  />
)

export default CollectiveLogDataOnMemberWithdrewFunds
