import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnEnableNewMembershipRequestsChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnEnableNewMembershipRequestsChanged'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    bundle={bundledCollectiveLog}
    isLast={isLast}
    summaryActionText={`Setting "Allow New Nembership Requests?" was changed ${
      bundledCollectiveLog.logs.length === 1
        ? bundledCollectiveLog.logs[0].data.enableNewMembershipRequests
          ? `to 'Yes'`
          : `to 'No'`
        : ''
    }`}
    hideSummaryActors
    detailsActionText={log => (
      <>
        {`Allow New Nembership Requests was changed to `}
        {log.data.enableNewMembershipRequests ? `'Yes'` : `'No`}
      </>
    )}
  />
)

export default CollectiveLogDataOnEnableNewMembershipRequestsChanged
