const classes = {
  nameText:
    'text-gray-800 text-sm break-all overflow-hidden text-ellipsis -webkit-box-overflow-ellipsis -webkit-line-clamp-2 line-clamp-2 -webkit-box-orient-vertical',
  walletNoticeText:
    'block pt-[5px] text-xs font-light text-gray-500 hover:text-gray-800',
  amountText: 'text-sm font-light break-all text-gray-500',
  estimatedValueEthIcon: 'text-gray-600 mr-[3px]',
  estimatedValueEthText:
    'pt-[3px] text-[13px] text-gray-800 break-all whitespace-nowrap',
  estimatedValueUSDText:
    'pt-[3px] text-[13px] font-light text-gray-500 break-all whitespace-nowrap',
  mediaContainer: 'flex flex-col items-center justify-center',
  mediaImage: 'h-full w-full rounded-t-lg',
  mediaIcon: 'text-[26px] text-gray-500 text-center'
}
export default classes
