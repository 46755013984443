import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnExchangeRateChanged: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnExchangeRateChanged'>
> = ({ bundledCollectiveLog, isLast }) => {
  const { newRate, previousRate } = bundledCollectiveLog.logs[0].data

  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={`Settings "Ether/Token exchange rate" was changed ${
        bundledCollectiveLog.logs.length === 1
          ? `from ${previousRate} to ${newRate}`
          : ''
      }`}
      hideSummaryActors
      detailsActionText={log =>
        `Exchange rate changed from ${log.data.previousRate} to ${log.data.newRate} (= 1 Ether)`
      }
    />
  )
}

export default CollectiveLogDataOnExchangeRateChanged
