import { faFilter, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { useCollectiveContext } from 'context/CollectiveContext'
import ERC20AssetListItem from './ERC20AssetListItem'
import ExternalAssetListItem from './ExternalAssetListItem'
import NFTAssetListItem from './NFTAssetListItem'
import { ReactElement, useEffect, useState } from 'react'
import { useControlledInput } from 'hooks'
import { useDebouncedCallback } from 'use-debounce'
import useCollectiveAssets from 'hooks/collectives/useCollectiveAssets'
import Typography from 'components/common/Typography'
import Spinner from 'components/Spinner'
import SearchInput from 'components/Collectives/Common/SearchInput'

export enum WalletFilter {
  ALL,
  MAIN,
  LINKED
}

export enum TypeFilter {
  ALL,
  TOKENS,
  NFT
}

function AssetsTable() {
  const { collective, demoMode } = useCollectiveContext()
  const [walletFilter, setWalletFilter] = useState<WalletFilter>(
    WalletFilter.ALL
  )
  const [typeFilter, setTypeFilter] = useState<TypeFilter>(TypeFilter.ALL)
  const [search, setSearch] = useState<string>()
  const [searchValue, onSearchValueChange, clearSearchValue] =
    useControlledInput()
  const onSearchValueChangeDebounced = useDebouncedCallback(e => {
    setSearch(e)
  }, 300)

  const { assets, pageInfo, loadMore, loading, loadingMore } =
    useCollectiveAssets({
      collectiveId: collective.id,
      walletFilter,
      typeFilter,
      search
    })

  useEffect(() => {
    onSearchValueChangeDebounced(searchValue)
  }, [onSearchValueChange, onSearchValueChangeDebounced, searchValue])

  const assetsListItems: ReactElement[] = assets.map(asset => {
    const node = asset.node
    const listItem =
      node.__typename === 'ERCAsset' ? (
        node.contractType === 'ERC721' || node.contractType === 'ERC1155' ? (
          <NFTAssetListItem key={node.id} asset={node} />
        ) : (
          <ERC20AssetListItem key={node.id} asset={node} />
        )
      ) : (
        <ExternalAssetListItem key={node.id} asset={node} />
      )

    return listItem
  })

  return (
    <div className="pt-5">
      <Typography
        className="max-w-[500px] pb-10"
        color="gray"
        fontWeight="light"
        size="sm"
      >
        {`Below are the DAO's assets we've found on the Blockchain. We use `}
        <a href="https://wgmi.io/" className="text-blue">
          {`wgmi.io`}
        </a>
        {` to calculate each NFT's estimated value based on its traits.`}
      </Typography>

      <div className="flex flex-col-reverse sm:flex-row sm:items-left max-xs:items-center sm:justify-between mb-4 gap-4">
        <div className="flex items-center pr-5">
          <Typography fontWeight="medium" size="sm">
            {loading || loadingMore ? (
              <>
                <FontAwesomeIcon icon={faSpinner} className="mr-1" spin />
                Loading DAO Vault...
              </>
            ) : !search ? (
              `Showing ${assets.length} assets`
            ) : (
              `Showing search results`
            )}
          </Typography>
        </div>

        {!demoMode && (
          <div className="flex-1 flex flex-col sm:flex-row space-x-2 space-y-2 sm:space-y-0 items-end justify-end">
            <div className="flex flex-col justify-end">
              <DropdownMenu
                button={
                  <Button
                    color={
                      assets.length &&
                      (walletFilter !== WalletFilter.ALL ||
                        typeFilter !== TypeFilter.ALL)
                        ? 'white'
                        : 'lightgray'
                    }
                    size="sm"
                    icon={faFilter}
                    label="Filter by Type"
                    showFocusRing={false}
                  >
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="ml-2 text-gray-400"
                    />
                  </Button>
                }
                items={[
                  { type: 'header', label: 'Wallets' },
                  {
                    type: 'checkbox',
                    label: 'All Wallets',
                    onChange: () => setWalletFilter(WalletFilter.ALL),
                    checked: walletFilter === WalletFilter.ALL
                  },
                  {
                    type: 'checkbox',
                    label: 'DAO Wallet',
                    onChange: () => setWalletFilter(WalletFilter.MAIN),
                    checked: walletFilter === WalletFilter.MAIN
                  },
                  {
                    type: 'checkbox',
                    label: 'Linked Wallets',
                    onChange: () => setWalletFilter(WalletFilter.LINKED),
                    checked: walletFilter === WalletFilter.LINKED
                  },
                  { type: 'separator' },
                  { type: 'header', label: 'Asset Type' },
                  {
                    type: 'checkbox',
                    label: 'All Assets',
                    onChange: () => setTypeFilter(TypeFilter.ALL),
                    checked: typeFilter === TypeFilter.ALL
                  },
                  {
                    type: 'checkbox',
                    label: 'NFTs',
                    onChange: () => setTypeFilter(TypeFilter.NFT),
                    checked: typeFilter === TypeFilter.NFT
                  },
                  {
                    type: 'checkbox',
                    label: 'Token Holdings',
                    onChange: () => setTypeFilter(TypeFilter.TOKENS),
                    checked: typeFilter === TypeFilter.TOKENS
                  }
                ]}
              />
            </div>

            <SearchInput
              clearSearchString={clearSearchValue}
              searchString={searchValue}
              contextText="User or Activity"
              onSearchStringChange={onSearchValueChange}
            />
          </div>
        )}
      </div>

      <div className="flex flex-wrap sm:mx-0">
        {assetsListItems.length ? (
          assetsListItems.map((assetsListColumn, i) => (
            <div
              className="w-[50%] md:w-[33.3%] xl:w-[25%] px-1 md:px-2 py-2"
              key={`asset_vault_${i}`}
            >
              {assetsListColumn}
            </div>
          ))
        ) : loading ? (
          <div className="w-full flex justify-center p-4">
            <Spinner />
          </div>
        ) : (
          !!search && (
            <div className="w-full flex justify-center p-4">
              <Typography color="gray">
                No Assets found for {`'${search}'`}
              </Typography>
            </div>
          )
        )}
      </div>
      {!demoMode && pageInfo?.hasNextPage && (
        <div className="flex p-4">
          <Button
            onClick={loadMore}
            disabled={loading || loadingMore}
            color="gray"
            size="sm"
            label={loading || loadingMore ? 'Loading...' : 'Load More'}
          />
        </div>
      )}
    </div>
  )
}

export default AssetsTable
