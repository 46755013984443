import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnExternalCollectiveJoined: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnExternalCollectiveJoined'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`joined the DAO`}
    detailsActionText={() => `joined the DAO`}
  />
)

export default CollectiveLogDataOnExternalCollectiveJoined
