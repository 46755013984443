import { twMerge } from 'tailwind-merge'
import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import Link from 'next/link'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | 'blue'
    | 'lightblue'
    | 'darkblue'
    | 'white'
    | 'gray'
    | 'lightgray'
    | 'darkgray'
    | 'red'
    | 'green'
    | 'transparent'
    | 'textOnly'
    | 'link'
  as?: 'button' | 'a'
  href?: string
  target?: React.HTMLAttributeAnchorTarget
}

function PillButton(
  {
    onClick,
    className,
    disabled = false,
    type = 'button',
    color = 'blue',
    as: Tag = 'button',
    href,
    target,
    children
  }: IProps,
  ref
) {
  const content = (
    <Tag
      ref={ref}
      type={Tag === 'button' ? type : undefined}
      className={twMerge(
        'inline-flex items-center rounded-full bg-gray-500 px-1.5 py-0.5 text-xs font-medium text-gray-800',
        color === 'blue' && 'text-white bg-blue hover:bg-blue-700 ',
        color === 'lightblue' && 'text-blue-700 bg-blue-100 hover:bg-blue-200 ',
        color === 'darkblue' &&
          'text-white  bg-light-blue-700 hover:bg-light-blue-800 ',
        color === 'white' &&
          'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm',
        color === 'gray' && 'text-black bg-gray-200 hover:bg-gray-300 ',
        color === 'lightgray' &&
          'text-gray-600 bg-gray-100 hover:bg-gray-200 border border-gray-300',
        color === 'darkgray' &&
          'text-gray-500 bg-gray-300 hover:bg-gray-500 hover:text-white',
        color === 'red' && 'text-white bg-red-600 hover:bg-red-700 ',
        color === 'green' && 'text-white bg-green-500 hover:bg-green-600 ',
        color === 'transparent' &&
          'text-blue bg-transparent hover:text-white hover:bg-blue border-2 border-blue',
        color === 'textOnly' &&
          'text-gray-500 bg-transparent hover:text-black shadow-none',
        color === 'link' &&
          'text-blue bg-transparent hover:text-black shadow-none',
        disabled && 'opacity-50 cursor-not-allowed',
        className
      )}
      // @ts-ignore
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Tag>
  )

  if (Tag === 'a' && href) {
    return (
      <Link href={href} target={target}>
        {content}
      </Link>
    )
  }

  return content
}

export default forwardRef<HTMLButtonElement, IProps>(PillButton)
