import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'
import { LogCollectiveUserPopover } from './LogCollectiveUserPopover'

const CollectiveLogDataOnCollectiveMembershipProposed: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnCollectiveMembershipProposed'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      isLast={isLast}
      bundle={bundledCollectiveLog}
      summaryActionText={`proposed ${bundledCollectiveLog.logs.length} new ${
        bundledCollectiveLog.logs.length === 1 ? 'member' : 'members'
      }`}
      detailsActionText={log => (
        <>
          {`proposed membership for `}
          {!!log.data.request?.user && (
            <LogCollectiveUserPopover user={log.data.request.user} />
          )}
        </>
      )}
    />
  )
}

export default CollectiveLogDataOnCollectiveMembershipProposed
