import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { SingleValueProps, components } from 'react-select'
import {
  getCommandLabel,
  ICommandOption,
  TCollective
} from '../../modals/AddProposalModal/AddProposal/ProposalCommand.types'

interface IProps extends SingleValueProps<ICommandOption, false, any> {
  collective: TCollective
}

const ProposalCommandSelectSingleValue = ({ collective, ...rest }: IProps) => {
  const command = rest.data as ICommandOption
  const label = getCommandLabel(command.renderForTypes[0], collective)

  return (
    <components.SingleValue {...rest}>
      <div className="flex flex-row">
        <div className="relative pr-[20px] flex flex-col justify-center">
          <FontAwesomeIcon icon={command.icon} size={'lg'} />

          {!!command.modifierIcon && (
            <FontAwesomeIcon
              icon={command.modifierIcon}
              className="absolute top-[5px] right-[5px] text-gray-500"
              size={'lg'}
            />
          )}
        </div>

        <div className="flex flex-col justify-center">
          <Typography className="text-sm font-normal">{label}</Typography>
        </div>
      </div>
    </components.SingleValue>
  )
}

export default ProposalCommandSelectSingleValue
