import { BigNumber } from 'lib/BigInt'
import { useQueryErrorHandler, useControlledInput } from 'hooks'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useState, useEffect } from 'react'
import {
  useCreateProposalMutation,
  CollectiveProposalType,
  QuestionCreationInput,
  ShowResultsAfter,
  QuestionType
} from 'graphql/generated'
import useToasts from 'hooks/useToasts'
import { parseWeb3ErrorMessage, TTxUiState } from 'lib/collectives/helpers'
import Web3ActionModal from 'components/Collectives/modals/Web3ActionModal'
import MembershipRequiredModal from '../MembershipRequiredModal'
import { useRouter } from 'next/router'
import QuickSurveyForm from './QuickSurveyForm'
import DetailedSurveyForm from './DetailedSurveyForm'
import Tabs from 'components/common/Tabs'

interface IProps {
  title?: string
  description?: string
  onClose?: Callback
}

export const AddSurveyModal: React.FC<IProps> = ({
  title: defaultTitle,
  description: defaultDescription,
  onClose
}) => {
  const { addToast } = useToasts()
  const { push } = useRouter()
  const { collective } = useCollectiveContext()

  const onError = useQueryErrorHandler(`Failed to create poll`)
  const [createProposal, { loading: creating }] = useCreateProposalMutation({
    onError: err => {
      onError(err)
      window.analytics?.trackAll(`Poll - Create Poll - Error`, {
        pollName: title,
        pollCategory:
          !title && !description && questions.length === 1
            ? 'Quick Poll'
            : 'Detailed Poll'
      })
    },
    onCompleted: resp => {
      window.analytics?.trackAll(`Poll - Create Poll - Complete`, {
        pollId: resp.createProposal?.id,
        pollName: title,
        pollCategory: resp.createProposal?.isShortForm
          ? 'Quick Poll'
          : 'Detailed Poll'
      })
    }
  })

  const [title, setTitle] = useControlledInput(defaultTitle || '')
  const [durationInHours, setDurationInHours] = useState(
    collective.parameters.votingPeriodInMinutes / 60 || 24
  )
  const [description, setDescription] = useControlledInput(
    defaultDescription || ''
  )
  const [questions, setQuestions] = useState<QuestionCreationInput[]>([
    {
      questionType: QuestionType.MULTIPLE_CHOICE,
      question: '',
      options: { required: true },
      choices: [{ stringChoice: '' }, { stringChoice: '' }]
    }
  ])

  const [detailedMode, setDetailedMode] = useState(false)
  const [areQuestionsValid, setAreQuestionsValid] = useState(true)
  const [state, setState] = useState<Maybe<TTxUiState>>(null)

  const hasMinimumBalance =
    collective.membership?.approved &&
    new BigNumber(collective.parameters.proposalThreshold).lte(
      collective.membership.totalTokens
    )

  const canSubmit =
    state === null &&
    !creating &&
    hasMinimumBalance &&
    areQuestionsValid &&
    (!detailedMode || (!!title.trim() && !!description.trim()))

  // when switching from detailed to quick
  useEffect(() => {
    if (!detailedMode) {
      if (
        // prevent looping due ot userEffect
        questions.length !== 1 ||
        questions[0].questionType !== QuestionType.MULTIPLE_CHOICE
      ) {
        const foundMultipleChoice = questions.find(
          question => question.questionType === QuestionType.MULTIPLE_CHOICE
        )
        const foundCheckboxType = questions.find(
          question => question.questionType === QuestionType.CHECKBOX
        )

        setQuestions([
          {
            questionType: QuestionType.MULTIPLE_CHOICE,
            question:
              foundMultipleChoice?.question ||
              foundCheckboxType?.question ||
              '',
            options: { required: true },
            choices: foundMultipleChoice?.choices ||
              foundCheckboxType?.choices || [
                { stringChoice: '' },
                { stringChoice: '' }
              ]
          }
        ])
      }
    }
  }, [detailedMode, questions])

  const doPropose = async () => {
    if (!canSubmit) {
      return
    }

    try {
      setState(TTxUiState.WAITING_FOR_NETWORK)
      const resp = await createProposal({
        variables: {
          collectiveId: collective.id,
          proposal: {
            type: CollectiveProposalType.SURVEY,
            title,
            durationInHours,
            description,
            commands: [],
            survey: {
              questions,
              options: {
                showResultsAfter: ShowResultsAfter.ALWAYS
              }
            }
          }
        }
      })
      const prop = resp.data?.createProposal
      if (!prop) {
        throw new Error(`Failed to create poll`)
      }

      window.analytics.trackAll('Survey Created', {
        collectiveId: collective.id,
        questionSize: questions.length,
        showResultsAfter: ShowResultsAfter.ALWAYS,
        surveyLength: durationInHours
      })

      setState(TTxUiState.TX_SUCCESS)

      push(prop.publicUrl)
    } catch (err) {
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      setState(null)
    }
  }

  return (
    <MembershipRequiredModal
      collective={collective}
      requiredRole={'approved'}
      onClose={onClose}
    >
      <Web3ActionModal
        title={'Add Poll'}
        state={state}
        onConfirm={doPropose}
        disableConfirm={!canSubmit}
        onClose={onClose}
        labels={{
          [TTxUiState.TX_SUCCESS]: `You've successfully submitted a poll`
        }}
        disableBackdropClick
      >
        <div className="flex flex-col flex-1 overflow-hidden sm:min-w-sm">
          {!hasMinimumBalance && (
            <div className="flex flex-1 justify-center items-center mb-2">
              <div className="bg-red-200 px-5 py-1.5 rounded-full">
                <p>{`You don't have enough tokens to create a poll`}</p>
              </div>
            </div>
          )}

          <Tabs
            selectedIndex={detailedMode ? 1 : 0}
            onChange={(_tab, index) => setDetailedMode(index === 1)}
            tabs={[
              {
                label: 'Quick Poll',
                onSelectDisplay: (
                  <QuickSurveyForm
                    question={questions[0]}
                    onChange={question => setQuestions([question])}
                    onValidityChange={setAreQuestionsValid}
                    durationInHours={durationInHours}
                    setDurationInHours={setDurationInHours}
                  />
                )
              },
              {
                label: 'Detailed Poll',
                onSelectDisplay: (
                  <DetailedSurveyForm
                    questions={questions}
                    onChange={newQuestions => setQuestions([...newQuestions])}
                    onValidityChange={setAreQuestionsValid}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    durationInHours={durationInHours}
                    setDurationInHours={setDurationInHours}
                  />
                )
              }
            ]}
          />
        </div>
      </Web3ActionModal>
    </MembershipRequiredModal>
  )
}
