import ModalTrigger from '../../../common/ModalTrigger'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import cls from 'classnames'

export interface IProps {
  icon: IconProp
  text: string
  subtext: string
  motionProps?: object
  first?: boolean
  last?: boolean
  children: JSX.Element
}

function ActionButton({
  icon,
  text,
  subtext,
  motionProps,
  first,
  last,
  children
}: IProps) {
  return (
    <ModalTrigger
      trigger={onClick => (
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClick}
          className={cls(
            'flex flex-row justify-center items-center space-x-2 border border-blue-400 py-2 w-36 hover:bg-blue-100 cursor-pointer',
            first && 'rounded-l-xl',
            last && 'rounded-r-xl -ml-px',
            motionProps?.['animate'] && 'bg-blue-100'
          )}
          {...motionProps}
        >
          <FontAwesomeIcon icon={icon} size="sm" className="text-blue-600" />
          <div className="text-blue-600">
            <span className="block text-xs font-medium">{text}</span>
            <span className="block text-xs font-light leading-tight">
              {subtext}
            </span>
          </div>
        </motion.div>
      )}
    >
      {children}
    </ModalTrigger>
  )
}

export default ActionButton
