import { BigNumber, formatNumber } from 'lib/BigInt'
import { CollectiveDepositModal } from 'components/Collectives/modals/CollectiveDepositModal'
import Thumbnail from 'components/Thumbnail'
import Link from 'next/link'
import {
  faBalanceScale,
  faBullhorn,
  faCoins,
  faPiggyBank,
  faPoll,
  faTreasureChest,
  faUsersCog
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollectiveContext } from 'context/CollectiveContext'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import {
  formatToken,
  getEtherscanUrlForCollective
} from 'lib/collectives/helpers'
import { useUploadCollectiveThumbnail } from 'hooks/collectives/useUploadCollectiveThumbnail'
import { useWeb3 } from 'context/Web3Context'
import EstimatedValuePopover from './EstimatedValuePopover'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import React, { useMemo } from 'react'
import { SummaryLink } from './SummaryLink'
import { CollectiveTokenType } from 'graphql/generated'
import { CollectiveWithdrawModal } from '../../modals/CollectiveWithdrawModal'
import { ManageDelegationsModal } from '../../modals/ManageDelegationsModal/ManageDelegationsModal'
import { AddProposalModal } from '../../modals/AddProposalModal/AddProposalModal'
import { AddSurveyModal } from '../../modals/AddSurveyModal/AddSurveyModal'
import ActionButton from './ActionButton'
import ActionButtonGroup from './ActionButtonGroup'
import CreateCollectiveAnnouncementModal from '../../modals/CreateCollectiveAnnouncementModal'
import AddToMetamaskButton from './AddToMetamaskButton'
import Typography from 'components/common/Typography'
import SummaryItem from './SummaryItem'

export function MemberView() {
  const { signerAddress } = useWeb3()
  const { collective, demoMode } = useCollectiveContext()
  const { setViewingMembers, currentStep, demoActiveProposal } =
    useCollectiveDemoContext()

  const animateDemoContribute = useMemo(
    () =>
      demoMode &&
      currentStep === 1 &&
      (!collective?.membership?.totalTokens ||
        collective?.membership?.totalTokens === '0'),
    [collective?.membership?.totalTokens, currentStep, demoMode]
  )

  const animateDemoAddProposal = useMemo(
    () => demoMode && currentStep === 2 && !demoActiveProposal,
    [currentStep, demoActiveProposal, demoMode]
  )

  const { onFileSelect } = useUploadCollectiveThumbnail({ collective })

  const isPrivileged = collective.membership?.privileged

  return (
    <>
      <div className={'p-4 flex flex-1 max-sm:flex-col max-sm:items-center'}>
        <div className={'mr-4 mb-4'}>
          <Thumbnail
            src={collective.thumb}
            initials={collective.name.substring(0)}
            className="h-20 w-20 rounded-full overflow-hidden"
          />

          {isPrivileged && (
            <label
              htmlFor="file-upload"
              className="relative block mt-2 cursor-pointer font-light text-xs text-center text-gray-600 hover:text-gray-500"
            >
              <span>Upload</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept="image/*"
                onChange={onFileSelect}
              />
            </label>
          )}
        </div>

        <div className={'flex-1 flex gap-6 flex-wrap px-4'}>
          <SummaryItem
            heading="Token"
            value={collective.tokens[0].symbol}
            link={
              <Link
                target="_blank"
                href={getEtherscanUrlForCollective(collective, 'token')}
              >
                <Typography size="xs" className={'pt-1 hover:text-blue'}>
                  <FontAwesomeIcon
                    icon={faCoins}
                    className="mr-1 text-gray-500"
                    size="1x"
                  />
                  {`${formatToken(collective.tokens[0].totalSupply, {
                    decimals: collective.tokens[0].decimals,
                    roundToDecimals: 2
                  })} supply >`}
                </Typography>
              </Link>
            }
          >
            {collective.tokens[0].type === CollectiveTokenType.OWNED && (
              <AddToMetamaskButton
                address={collective.address}
                decimals={collective.tokens[0].decimals}
                image={collective.thumb!}
                symbol={collective.tokens[0].symbol}
              />
            )}
          </SummaryItem>

          <SummaryItem
            heading="DAO Wallet"
            value={
              <>
                <FontAwesomeIcon
                  icon={faEthereum}
                  className="mr-1 text-gray-800"
                  style={{ verticalAlign: 'revert' }}
                  size="xs"
                />
                {formatToken(collective.treasuryBalance, {
                  roundToDecimals: 2
                })}
              </>
            }
            link={<EstimatedValuePopover />}
          />

          {collective.governanceType === CollectiveTokenType.OWNED && (
            <SummaryItem
              heading="Your Ownership"
              value={`${collective.membership?.percentOfTotalTokenSupply}%`}
              link={
                <SummaryLink
                  link={getEtherscanUrlForCollective(
                    collective,
                    'token',
                    signerAddress
                  )}
                  text={`${formatToken(collective.membership!.totalTokens, {
                    decimals: collective.tokens[0].decimals
                  })} ${collective.tokens[0].symbol} >`}
                  newTab
                  icon={
                    <FontAwesomeIcon
                      icon={faCoins}
                      className="mr-1 text-gray-500"
                      size="1x"
                    />
                  }
                />
              }
              childrenVertical
            >
              {isPrivileged && (
                <Typography size="xs" className={'text-blue'}>
                  <FontAwesomeIcon icon={faBolt} color={'#F0B328'} />
                  {` Authorized Signator`}
                </Typography>
              )}
            </SummaryItem>
          )}

          {collective.governanceType === CollectiveTokenType.EXTERNAL && (
            <SummaryItem
              heading="Your Voting Power"
              value={
                <>
                  {collective.membership
                    ? new BigNumber(collective.membership.totalTokens)
                        .times(100)
                        .div(collective.tokens[0].totalVotingSupply)
                        .toFixed(2)
                    : `0`}
                  {`%`}
                </>
              }
              link={
                <Link
                  target="_blank"
                  href={getEtherscanUrlForCollective(
                    collective,
                    'token',
                    signerAddress
                  )}
                >
                  <Typography size="xs" className={'pt-1 hover:text-blue'}>
                    <FontAwesomeIcon
                      icon={faCoins}
                      className="mr-1 text-gray-500"
                      size="1x"
                    />

                    {`${formatToken(collective.membership!.totalTokens, {
                      decimals: collective.tokens[0].decimals
                    })} ${collective.tokens[0].symbol} >`}
                  </Typography>
                </Link>
              }
              childrenVertical
            >
              {isPrivileged && (
                <Typography size="xs" className={'text-blue'}>
                  <FontAwesomeIcon icon={faBolt} color={'#F0B328'} />
                  {` Authorized Signator`}
                </Typography>
              )}
            </SummaryItem>
          )}

          <SummaryItem
            heading="Proposals"
            value={formatNumber(collective.totalProposals)}
            link={<SummaryLink link={`/proposals`} text="View all >" />}
          />

          <SummaryItem
            heading="Members"
            value={formatNumber(collective.totalMembers)}
            link={
              <SummaryLink
                link={`/members`}
                text="View members >"
                demoOnClick={() => setViewingMembers(true)}
              />
            }
          />

          <div className={'flex flex-col flex-1'} />
        </div>
      </div>

      <div className="flex flex-row justify-around py-4 px-2 border-t border-gray-200 flex-wrap items-start">
        {!collective.archived &&
          collective.governanceType === CollectiveTokenType.OWNED && (
            <ActionButtonGroup>
              <ActionButton
                icon={faPiggyBank}
                text="Contribute"
                subtext="ETH"
                motionProps={{
                  animate: animateDemoContribute
                    ? {
                        scale: [1, 1.15, 1]
                      }
                    : undefined,
                  transition: animateDemoContribute
                    ? {
                        type: 'spring',
                        damping: 10,
                        stiffness: 70,
                        duration: 1.5,
                        repeat: Infinity
                      }
                    : undefined
                }}
              >
                <CollectiveDepositModal />
              </ActionButton>

              <ActionButton
                icon={faTreasureChest}
                text="Withdraw"
                subtext="ETH"
              >
                <CollectiveWithdrawModal />
              </ActionButton>
            </ActionButtonGroup>
          )}

        {collective.archived &&
          new BigNumber(collective.treasuryBalance).isGreaterThan(0) && (
            <ActionButtonGroup>
              <ActionButton
                icon={faTreasureChest}
                text="Withdraw"
                subtext="ETH"
              >
                <CollectiveWithdrawModal />
              </ActionButton>
            </ActionButtonGroup>
          )}

        {!collective.archived && (
          <ActionButtonGroup>
            <ActionButton icon={faPoll} text="Take" subtext="Poll">
              <AddSurveyModal />
            </ActionButton>

            <ActionButton
              icon={faBalanceScale}
              text="Create"
              subtext="Proposal"
              motionProps={{
                animate: animateDemoAddProposal
                  ? {
                      scale: [1, 1.15, 1]
                    }
                  : undefined,

                transition: animateDemoAddProposal
                  ? {
                      type: 'spring',
                      damping: 10,
                      stiffness: 70,
                      duration: 1.5,
                      repeat: Infinity
                    }
                  : undefined
              }}
            >
              <AddProposalModal />
            </ActionButton>
          </ActionButtonGroup>
        )}

        {!collective.archived && !!collective.delegationAddress?.length && (
          <ActionButtonGroup>
            <ActionButton icon={faUsersCog} text="Delegate" subtext="Votes">
              <ManageDelegationsModal />
            </ActionButton>
          </ActionButtonGroup>
        )}

        {isPrivileged && !demoMode && (
          <ActionButtonGroup>
            <ActionButton icon={faBullhorn} text="Make" subtext="Announcement">
              <CreateCollectiveAnnouncementModal collectiveId={collective.id} />
            </ActionButton>
          </ActionButtonGroup>
        )}
      </div>
    </>
  )
}
