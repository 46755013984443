import Link from 'next/link'
import { BigNumber } from 'lib/BigInt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCoins,
  faExternalLink,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import BaseModal from 'components/modals/BaseModal'
import useToasts from 'hooks/useToasts'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCallback, useState } from 'react'
import useWithdrawEther from 'hooks/collectives/useWithdrawEther'
import {
  formatToken,
  getEtherscanUrl,
  parseWeb3ErrorMessage,
  POINT_ONE_ETHER_BN,
  TTxUiState,
  TX_STATUSES
} from 'lib/collectives/helpers'
import Web3ActionModal from 'components/Collectives/modals/Web3ActionModal'
import EtherInputField from 'components/Collectives/Common/EtherInputField'
import { useWeb3 } from 'context/Web3Context'
import MembershipRequiredModal from './MembershipRequiredModal'
import WalletCheckInfo from '../Common/WalletCheckInfo'
import { noop } from 'lodash'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import Button from 'components/Button'

function getEstimatedEthValue(
  amountInWei: BigNumber,
  treasuryBalance: BigNumber,
  totalTokenSupply: BigNumber
) {
  return `~${amountInWei
    .times(treasuryBalance)
    .div(totalTokenSupply)
    .formatEther()} ETH`
}

export function CollectiveWithdrawModal({ onClose }: { onClose?: Callback }) {
  const { addToast } = useToasts()
  const { demoMode, collective } = useCollectiveContext()
  const { signerAddress } = useWeb3()

  const { withdraw } = useWithdrawEther({ collective, demoMode })
  const [amountInWei, setAmountInWei] = useState<BigNumber>(POINT_ONE_ETHER_BN)
  const [validAmount, setValidAmount] = useState(true)
  const [state, setState] = useState<Maybe<TTxUiState>>(null)

  const mainToken = collective.tokens[0]
  const estimatorFn = useCallback(
    (value: BigNumber) =>
      getEstimatedEthValue(
        value,
        new BigNumber(collective.treasuryBalance),
        new BigNumber(mainToken.totalSupply)
      ),
    [collective.treasuryBalance, mainToken.totalSupply]
  )

  const doWithdraw = async () => {
    if (demoMode) {
      return
    }
    try {
      setState(TTxUiState.WAITING_FOR_PROVIDER)

      const { txStatus } = await withdraw({
        tokenAmount: amountInWei,
        onCall: uiState => setState(uiState)
      })

      const isSuccess = txStatus === TX_STATUSES.succeeded
      setState(isSuccess ? TTxUiState.TX_SUCCESS : TTxUiState.TX_FAILURE)
      window.analytics.trackAll(
        `Transfer - Withdraw - ${isSuccess ? 'Complete' : 'Error'}`,
        {
          amount: amountInWei.formatEther(),
          symbol: 'ETH'
        }
      )
    } catch (err) {
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      setState(null)
    }
  }

  const { showWalletNotice } = useWalletCheck(collective)

  const maxWei =
    collective.membership && collective.membership.totalTokens
      ? new BigNumber(collective.membership.totalTokens)
      : undefined

  if (!maxWei || maxWei.eq(0)) {
    return (
      <BaseModal>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faCoins}
            className="text-red-400"
            size={'3x'}
          />

          <div className="py-10">
            <p className="text-center font-medium text-xl pb-2">
              {`Not enough Tokens`}
            </p>

            <p className="text-center font-light text-sm pb-6">
              {`You don't have any tokens to withdraw.`}
            </p>
          </div>

          <div className="w-full">
            <div className="flex justify-end">
              <Button color="outline" onClick={onClose} label={`Dismiss`} />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  } else if (!collective.parameters.enableWithdraws) {
    return (
      <BaseModal>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faCoins}
            className="text-red-400"
            size={'3x'}
          />

          <div className="py-10">
            <p className="text-center font-medium text-xl pb-2">
              {`Withdrawals are Currently Disabled`}
            </p>

            <p className="text-center font-light text-sm pb-6">
              {`This DAO has disabled the ability to remove ETH from the DAO. To change this setting, create a proposal to allow withdrawals.`}
            </p>
          </div>

          <div className="w-full">
            <div className="flex justify-end">
              <Button color="outline" onClick={onClose} label={`Dismiss`} />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  } else if (showWalletNotice) {
    return (
      <Web3ActionModal
        title={'Withdraw Ether'}
        state={undefined}
        disableConfirm={true}
        onConfirm={noop}
        onClose={onClose}
      >
        <WalletCheckInfo
          collective={collective}
          className="bg-red-50 hover:bg-red-100 px-5 py-3 rounded-lg my-3"
        />
      </Web3ActionModal>
    )
  }

  return (
    <MembershipRequiredModal
      requiredRole={'approved'}
      collective={collective}
      onClose={onClose}
    >
      <Web3ActionModal
        title={'Withdraw Ether'}
        state={state}
        disableConfirm={!validAmount}
        onConfirm={doWithdraw}
        onClose={onClose}
        labels={{
          [TTxUiState.TX_SUCCESS]: `You've successfully taken out ${amountInWei.formatEther()} Ether from the DAO`
        }}
      >
        <div className="w-full">
          <EtherInputField
            label={`Enter the amount you wish to withdraw`}
            initialValueInWei={amountInWei}
            onChangeInWei={setAmountInWei}
            disabled={state !== null}
            onValidityChange={setValidAmount}
            minWei={POINT_ONE_ETHER_BN}
            maxWei={maxWei}
            type={'TOKEN'}
            estimatorFn={estimatorFn}
            symbol={collective.tokens[0].symbol}
            collective={collective}
          />

          <Link
            className="text-gray-600 hover:text-blue text-sm font-light pt-2 inline-block"
            target="_blank"
            href={getEtherscanUrl(
              collective.network,
              'token',
              collective.address,
              signerAddress
            )}
          >
            {`Available ${formatToken(maxWei)} ${collective.tokens[0].symbol}`}
            <FontAwesomeIcon icon={faExternalLink} className="ml-2" size="sm" />
          </Link>
        </div>
      </Web3ActionModal>
    </MembershipRequiredModal>
  )
}
