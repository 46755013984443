import { useEventEmitterContext } from 'context/EventEmitterContext'
import Popover, { CLOSE_POPOVER } from 'components/common/Popover'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useWalletSummary } from 'hooks/collectives/useWalletSummary'
import { SHOW_WALLETS } from '../CollectiveWallets/CollectiveWallets'

function EstimatedValuePopover() {
  const { collective } = useCollectiveContext()

  const hasLinkedWallets = collective.wallets.length > 1

  const mainWallet = useWalletSummary(
    collective,
    collective.wallets.filter(x => x.isMainWallet)
  )
  const linkedWallets = useWalletSummary(
    collective,
    collective.wallets.filter(x => !x.isMainWallet)
  )
  const allWallets = useWalletSummary(collective, collective.wallets)

  const assetsCount =
    collective.erc20Assets.length +
    collective.nftAssets.length +
    collective.externalAssets.length
  const hasAssets = assetsCount > 0

  const { emitter } = useEventEmitterContext()
  const scrollToWallets = () => {
    emitter.emit(SHOW_WALLETS)
    emitter.emit(CLOSE_POPOVER)
  }

  return (
    <Popover
      from={(ref, toggle, _visible, show) => (
        <div ref={ref}>
          <span
            className="text-xs font-normal text-gray-800 cursor-pointer pt-[5px]"
            onMouseOver={() => show()}
            onMouseOut={() => toggle()}
            onClick={() => {
              scrollToWallets()
              toggle()
            }}
          >
            {`~ $${allWallets.total.usd} >`}
          </span>
        </div>
      )}
      showDismissButton
    >
      <div className="relative w-[400px] p-[25px]">
        <p className="text-xs font-light text-gray-800 pb-[15px]">
          {hasAssets
            ? `This number represents the value of the DAO's Eth
        balance, in addition to the estimated values of all owned
        assets. Here's the breakdown:`
            : `This number represents the value of the DAO's Eth
        balance:`}
        </p>

        <table className="w-[75%] mx-auto my-0">
          <tbody>
            <tr>
              <td>
                <p className="text-xs font-light text-gray-500 text-left">{`Eth Balance`}</p>
              </td>
              <td>
                <p className="text-xs font-light text-gray-800 text-right">
                  {`$${mainWallet.balance.usd}`}
                </p>
              </td>
            </tr>

            {hasAssets && (
              <>
                <tr>
                  <td>
                    <p className="text-xs font-light text-gray-500 text-left">
                      Tokens
                    </p>
                  </td>
                  <td>
                    <p className="text-xs font-light text-gray-800 text-right">
                      {`$${mainWallet.tokens.usd}`}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p className="text-xs font-light text-gray-500 text-left">
                      NFTs
                    </p>
                  </td>
                  <td>
                    <p className="text-xs font-light text-gray-800 text-right">
                      {`$${mainWallet.nfts.usd}`}
                    </p>
                  </td>
                </tr>
              </>
            )}

            {hasLinkedWallets && (
              <tr>
                <td>
                  <p className="text-xs font-light text-gray-500 text-left">
                    Linked Wallets
                  </p>
                </td>
                <td>
                  <p className="text-xs font-light text-gray-800 text-right">
                    {`$${linkedWallets.total.usd}`}
                  </p>
                </td>
              </tr>
            )}

            <tr className="border-t-gray-300 border-t">
              <td>
                <p className="text-xs font-light text-gray-500 text-left">
                  Total Value
                </p>
              </td>
              <td>
                <p className="text-xs font-light text-gray-800 text-right">
                  {`$${allWallets.total.usd}`}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w-[75%] my-0 mx-auto pt-[3px]">
          <p
            onClick={scrollToWallets}
            className="text-xs font-light text-gray-500 cursor-pointer text-right hover:underline"
          >
            {`click to view detailed breakdown >`}
          </p>
        </div>
      </div>
    </Popover>
  )
}

export default EstimatedValuePopover
