export const MAIN_CHART_COLORS = [['rgb(12,95,227)', 'rgba(12,95,227,0.5)']]

export const CHART_COLORS = [
  ['rgb(255, 99, 132)', 'rgb(255, 99, 132, 0.5)'],
  ['rgb(255, 159, 64)', 'rgb(255, 159, 64, 0.5)'],
  ['rgb(255, 205, 86)', 'rgb(255, 205, 86, 0.5)'],
  ['rgb(75, 192, 192)', 'rgb(75, 192, 192, 0.5)'],
  ['rgb(54, 162, 235)', 'rgb(54, 162, 235, 0.5)'],
  ['rgb(153, 102, 255)', 'rgb(153, 102, 255, 0.5)'],
  ['rgb(201, 203, 207)', 'rgb(201, 203, 207, 0.5)']
]
