import { useCollectiveContext } from 'context/CollectiveContext'
import {
  useCollectiveAssetsQuery,
  CollectiveAssetType,
  CollectiveWalletType,
  CollectiveAssetsQueryVariables
} from 'graphql/generated'
import { useCallback, useMemo } from 'react'
import {
  WalletFilter,
  TypeFilter
} from 'components/Collectives/CollectiveDetails/AssetsTable/AssetsTable'
import { useQueryErrorHandler } from 'hooks'

interface IProps {
  collectiveId: string
  walletFilter?: WalletFilter
  typeFilter?: TypeFilter
  search?: string
  limit?: number
}

const walletFilterToType = {
  [WalletFilter.MAIN]: CollectiveWalletType.COLLECTIVE,
  [WalletFilter.LINKED]: CollectiveWalletType.LINKED
}
const typeFilterToType = {
  [TypeFilter.NFT]: CollectiveAssetType.NFT,
  [TypeFilter.TOKENS]: CollectiveAssetType.ERC20
}

function useCollectiveAssets({
  collectiveId,
  walletFilter,
  typeFilter,
  search,
  limit = 10
}: IProps) {
  const onError = useQueryErrorHandler(
    'Failed to load DAO vault assets. Please try again later.'
  )
  const { demoMode } = useCollectiveContext()

  const params: CollectiveAssetsQueryVariables = useMemo(
    () => ({
      collectiveId,
      where: {
        collectiveWalletType: walletFilter
          ? walletFilterToType[walletFilter]
          : null,
        assetType: typeFilter ? typeFilterToType[typeFilter] : null,
        searchString: search
      },
      assetsConnection: { first: 20 }
    }),
    [collectiveId, search, walletFilter, typeFilter]
  )

  const { data, loading, fetchMore, networkStatus } = useCollectiveAssetsQuery({
    variables: params,
    onError,
    skip: demoMode
  })

  const loadingMore = networkStatus === 3
  const assets = data?.collective?.assets.edges || []

  const loadMore = useCallback(() => {
    if (
      !data?.collective?.assets.pageInfo ||
      !data?.collective?.assets?.pageInfo?.hasNextPage
    ) {
      return
    } else if (loading || loadingMore) {
      return
    }

    fetchMore({
      variables: {
        ...params,
        assetsConnection: {
          first: limit,
          after: data.collective.assets.pageInfo.endCursor
        }
      }
    })
  }, [
    data?.collective?.assets.pageInfo,
    fetchMore,
    limit,
    loading,
    loadingMore,
    params
  ])

  return {
    assets,
    loadMore,
    loading,
    loadingMore,
    pageInfo: data?.collective?.assets.pageInfo
  }
}

export default useCollectiveAssets
