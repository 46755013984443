import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CollectiveTokenType,
  EthNetwork,
  ProposalCommandType
} from 'graphql/generated'
import React, { MouseEvent, useState } from 'react'
import { formatToken } from 'lib/collectives/helpers'
import { useCollectiveContext } from 'context/CollectiveContext'
import useToasts from 'hooks/useToasts'
import { formatNumber } from 'lib/BigInt'
import ModalTrigger from '../../common/ModalTrigger'
import { AddProposalModal } from '../modals/AddProposalModal/AddProposalModal'
import SyncStatus from './SyncStatus/SyncStatus'
import Typography from 'components/common/Typography'
import EtherscanLink from '../Common/EtherscanLink'
import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { inflect } from 'inflection'
import { useFlag } from 'hooks'
import {
  AnonymityDescriptionMap,
  AnonymityKeyMap
} from '../modals/AddProposalModal/AddProposal/ChangeVotingAnonymityCommand'
import CollectiveDetailsSectionWrapper from './CollectiveDetailsSectionWrapper'

const DescriptionSettingItem: React.FC<{
  title: string
  value: string | React.ReactElement
  description?: string | React.ReactElement
  action?: React.ReactElement | false
}> = ({ title, description, value, action }) => {
  return (
    <div
      className={
        'flex flex-col justify-between gap-4 sm:gap-2 py-2 border-t border-gray-200'
      }
    >
      <dt className={'flex justify-between gap-4'}>
        <Typography
          className="min-w-[33%]"
          color="gray"
          size="sm"
          component={'div'}
        >
          {title}
        </Typography>

        <Typography className="text-right" size="sm" component={'div'}>
          {value}
          {action}
        </Typography>
      </dt>

      {typeof description === 'string' ? (
        <dd className={'flex sm:justify-end'}>
          <Typography
            className={'sm:text-right sm:max-w-[400px]'}
            color="gray"
            fontWeight="light"
            size="xs"
            component={'div'}
          >
            {description}
          </Typography>
        </dd>
      ) : (
        description && <dd>{description}</dd>
      )}
    </div>
  )
}

const ChangeButton: React.FC<{
  title?: string
  onClick: CallbackWithParam<MouseEvent>
}> = ({ title = ' (change)', onClick }) => {
  return (
    <Typography
      color="blue"
      size="sm"
      onClick={onClick}
      className="cursor-pointer"
    >
      {title}
    </Typography>
  )
}

function CollectiveParameters() {
  const { addToast } = useToasts()
  const [show, setShow] = useState(false)
  const { collective } = useCollectiveContext()

  const isOwned = collective.governanceType === CollectiveTokenType.OWNED

  const onCopyToClipboard = () => {
    try {
      navigator.clipboard.writeText(collective.address)
      addToast('DAO address copied to clipboard')
    } catch (ex) {
      addToast('Failed to copy DAO address to clipboard', {
        appearance: 'warning'
      })
    }
  }

  const onCopySafeToClipboard = () => {
    try {
      navigator.clipboard.writeText(collective.safeAddress)
      addToast('DAO safe address copied to clipboard')
    } catch (ex) {
      addToast('Failed to copy DAO safe address to clipboard', {
        appearance: 'warning'
      })
    }
  }

  const cooldownPeriodMinutes = useFlag(
    'COLLECTIVE_SYNC_COOLDOWN_PERIOD_MINS',
    15
  )

  return (
    <div className={'bg-gray-50 p-2 sm:p-4 rounded-md'}>
      <CollectiveDetailsSectionWrapper
        title="DAO Settings"
        description="Parameters set by the Collective. Submit proposals to make changes."
        show={show}
        setShow={setShow}
      />

      {show && (
        <dl className={'mt-5 pt-1 border-b border-gray-200'}>
          <DescriptionSettingItem
            title="Contract Address"
            value={
              <div className="flex flex-col">
                <div className="sm:hidden space-y-1">
                  <EtherscanLink
                    inline
                    network={collective.network}
                    type={'address'}
                    address={collective.address}
                    shortAddress={true}
                  />
                  <div onClick={onCopyToClipboard}>
                    <Typography component={'div'} size="sm" color="gray">
                      {`or Click to Copy `}
                      <FontAwesomeIcon icon={faCopy} />
                    </Typography>
                  </div>
                </div>

                <div className="hidden sm:flex flex-col space-y-1">
                  <StyledTooltip arrow title={`Copy DAO address to clipboard`}>
                    <div className="flex">
                      <Typography
                        component={'div'}
                        size="sm"
                        className={'text-right hover:text-blue cursor-pointer'}
                        onClick={onCopyToClipboard}
                      >
                        {collective.address} <FontAwesomeIcon icon={faCopy} />
                      </Typography>
                    </div>
                  </StyledTooltip>
                  <EtherscanLink
                    inline
                    network={collective.network}
                    type={'address'}
                    address={collective.address}
                    label={'or, open in Etherscan '}
                  />
                </div>
              </div>
            }
          />

          {collective.membership?.privileged && (
            <DescriptionSettingItem
              title="Safe Address"
              description={
                <Typography component={'div'} size="sm">
                  How to Access Your Multi-Sig wallet
                  <Typography
                    className="text-left sm:flex gap-6 px-4 py-2"
                    size="xs"
                    color="gray"
                    fontWeight="light"
                  >
                    <ol className="list-decimal">
                      <li>
                        Copy the
                        <Typography
                          size="xs"
                          className="text-blue-600 cursor-pointer"
                          component={'span'}
                          onClick={onCopySafeToClipboard}
                        >
                          {` Safe Address `}
                          <FontAwesomeIcon icon={faCopy} />
                        </Typography>
                      </li>
                      <li>
                        Visit{' '}
                        <Typography
                          size="xs"
                          className="text-blue-600"
                          linkHover
                          newTab
                          href={`https://app.safe.global/new-safe/load?chain=${
                            collective.network === EthNetwork.GOERLI
                              ? 'gor'
                              : 'eth'
                          }&address=${collective.safeAddress}`}
                        >
                          safe.global
                        </Typography>
                      </li>
                      <li>
                        Connect with the wallet address you use for this DAO
                        (ensure it’s on the Ethereum network)
                      </li>
                      <li>
                        Paste the Safe Address (
                        <Typography
                          color="gray"
                          italics
                          size="xs"
                          component={'span'}
                        >
                          {collective.safeAddress})
                        </Typography>{' '}
                        and follow the on-screen instructions.
                      </li>
                    </ol>
                  </Typography>
                </Typography>
              }
              value={
                <div className="flex flex-col">
                  <div className="sm:hidden space-y-1">
                    <EtherscanLink
                      inline
                      network={collective.network}
                      type={'address'}
                      address={collective.safeAddress}
                      shortAddress={true}
                    />
                  </div>

                  <div className="hidden sm:flex flex-col space-y-1">
                    <StyledTooltip
                      arrow
                      title={`Copy DAO safe address to clipboard`}
                    >
                      <div className="flex">
                        <Typography
                          component={'div'}
                          size="sm"
                          className={
                            'text-right hover:text-blue cursor-pointer'
                          }
                          onClick={onCopySafeToClipboard}
                        >
                          {collective.safeAddress}{' '}
                          <FontAwesomeIcon icon={faCopy} />
                        </Typography>
                      </div>
                    </StyledTooltip>
                  </div>
                </div>
              }
            />
          )}

          <DescriptionSettingItem
            title="Minimum Signators"
            value={`${collective.safeThreshold} ${
              collective.safeThreshold === 1 ? 'Signator' : 'Signators'
            }`}
            description={`This is the minimum amount of signators needed to sign a proposal before it can be executed.`}
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_SAFE_THRESHOLD,
                        safeThreshold: collective.safeThreshold
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Require Signatures to Vote"
            value={`Signature ${
              collective.parameters.requireVotingSignature ? 'is' : 'is not'
            } required for voting`}
            description={`This will determine if you sign votes with your wallet.`}
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE,
                        requireVotingSignature:
                          !collective.parameters.requireVotingSignature
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Voting Privacy"
            value={AnonymityKeyMap[collective.parameters.votingAnonymity]}
            description={
              AnonymityDescriptionMap[collective.parameters.votingAnonymity]
            }
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_VOTING_ANONYMITY,
                        votingAnonymity: collective.parameters.votingAnonymity
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Proposal Threshold"
            value={`${
              isOwned
                ? formatToken(collective.parameters.proposalThreshold)
                : formatNumber(collective.parameters.proposalThreshold)
            } ${collective.tokens[0].symbol}`}
            description={`This is the minimum amount of ${collective.tokens[0].symbol}
            tokens a member must have in their authenticated wallet, before
            they're allowed to create a new proposal.`}
          />

          {isOwned && (
            <>
              <DescriptionSettingItem
                title="Allow anyone to join?"
                value={!collective.parameters.gateDeposits ? 'Yes' : 'No'}
                description={
                  collective.parameters.gateDeposits
                    ? 'People must be approved to join this DAO.'
                    : 'Anyone can join this DAO.'
                }
                action={
                  !collective.archived && (
                    <ModalTrigger
                      trigger={onClick => <ChangeButton onClick={onClick} />}
                    >
                      <AddProposalModal
                        commands={[
                          {
                            type: ProposalCommandType.CHANGE_GATE_DEPOSITS,
                            gateDeposits: !collective.parameters.gateDeposits
                          }
                        ]}
                      />
                    </ModalTrigger>
                  )
                }
              />

              <DescriptionSettingItem
                title="Allow New Membership Requests?"
                value={
                  collective.parameters.enableNewMembershipRequests
                    ? 'Yes'
                    : 'No'
                }
                description={
                  collective.parameters.enableNewMembershipRequests
                    ? `This DAO is open to accept new membership requests.`
                    : `This DAO isn't accepting any new membership requests at this time.`
                }
                action={
                  !collective.archived && (
                    <ModalTrigger
                      trigger={onClick => <ChangeButton onClick={onClick} />}
                    >
                      <AddProposalModal
                        commands={[
                          {
                            type: ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS,
                            enableNewMembershipRequests:
                              !collective.parameters.enableNewMembershipRequests
                          }
                        ]}
                      />
                    </ModalTrigger>
                  )
                }
              />

              <DescriptionSettingItem
                title="Exchange Rate"
                value={`1 Eth = ${formatNumber(
                  collective.parameters.exchangeRate
                )} ${collective.tokens[0].symbol}`}
                description={`Set the exchange value between ETH and your Collective’s tokens`}
                action={
                  !collective.archived && (
                    <ModalTrigger
                      trigger={onClick => <ChangeButton onClick={onClick} />}
                    >
                      <AddProposalModal
                        commands={[
                          {
                            type: ProposalCommandType.CHANGE_EXCHANGE_RATE,
                            exchangeRate: collective.parameters.exchangeRate
                          }
                        ]}
                      />
                    </ModalTrigger>
                  )
                }
              />

              <DescriptionSettingItem
                title="Enable Withdraws?"
                value={collective.parameters.enableWithdraws ? 'Yes' : 'No'}
                description={
                  collective.parameters.enableWithdraws
                    ? `Members are able to exchange their ${collective.tokens[0].symbol} tokens for Ether from the DAO's treasury.`
                    : `Members are not able to exchange their ${collective.tokens[0].symbol} tokens for Ether from the DAO's treasury.`
                }
                action={
                  !collective.archived && (
                    <ModalTrigger
                      trigger={onClick => <ChangeButton onClick={onClick} />}
                    >
                      <AddProposalModal
                        commands={[
                          {
                            type: ProposalCommandType.CHANGE_ENABLE_WITHDRAWS,
                            enableWithdraws:
                              !collective.parameters.enableWithdraws
                          }
                        ]}
                      />
                    </ModalTrigger>
                  )
                }
              />

              <DescriptionSettingItem
                title="Enable Contributions?"
                value={collective.parameters.enableDeposits ? 'Yes' : 'No'}
                description={
                  collective.parameters.enableDeposits
                    ? `Ether sent to the DAO will result in new ${collective.tokens[0].symbol} token minted & sent back to the depositor.`
                    : `No additional ${collective.tokens[0].symbol} token will be minted, nor any depositor credited for deposits.`
                }
                action={
                  !collective.archived && (
                    <ModalTrigger
                      trigger={onClick => <ChangeButton onClick={onClick} />}
                    >
                      <AddProposalModal
                        commands={[
                          {
                            type: ProposalCommandType.CHANGE_ENABLE_DEPOSITS,
                            enableDeposits:
                              !collective.parameters.enableDeposits
                          }
                        ]}
                      />
                    </ModalTrigger>
                  )
                }
              />
            </>
          )}

          <DescriptionSettingItem
            title="Token Transferability"
            value={`${
              collective.parameters.disableTokenTransfers
                ? 'Disabled'
                : 'Enabled'
            }`}
            description="This setting determines whether members can transfer DAO tokens between wallets."
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS,
                        disableTokenTransfers:
                          !collective.parameters.disableTokenTransfers
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Quorum Percentage"
            value={`${collective.parameters.quorumNeededInPercentage} %`}
            description={
              'Percentage of total members who must vote for a Proposal to meet quorum'
            }
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_QUORUM,
                        quorumNeededInPercentage:
                          collective.parameters.quorumNeededInPercentage
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Minimum Ether Contribution"
            value={formatToken(collective.parameters.minEthContribution)}
            description={'Minimum Ether contribution required to contribute'}
            action={
              !collective.archived && (
                <ModalTrigger
                  trigger={onClick => <ChangeButton onClick={onClick} />}
                >
                  <AddProposalModal
                    commands={[
                      {
                        type: ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION,
                        minEthContribution:
                          collective.parameters.minEthContribution
                      }
                    ]}
                  />
                </ModalTrigger>
              )
            }
          />

          <DescriptionSettingItem
            title="Upstream Fees"
            value={`${collective.parameters.depositFeeInPercentage}%`}
            description={`Fee automatically paid to Upstream on contributions`}
          />

          <DescriptionSettingItem
            title="Sync DAO to Blockchain"
            value={<SyncStatus />}
            description={`Allowed once every ${cooldownPeriodMinutes} ${inflect(
              'minutes',
              cooldownPeriodMinutes
            )}`}
          />
        </dl>
      )}
    </div>
  )
}

export default CollectiveParameters
