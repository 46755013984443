import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnCollectiveSynced: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnCollectiveSynced'>
> = ({ bundledCollectiveLog, isLast }) => (
  <BaseCollectiveLogListItem
    isLast={isLast}
    bundle={bundledCollectiveLog}
    summaryActionText={`synced the DAO to the Blockchain`}
    detailsActionText={() => `synced the DAO to the Blockchain`}
  />
)

export default CollectiveLogDataOnCollectiveSynced
