import { CollectiveQueryResult, ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'
import { StyledSelect } from 'components/common/Select'

type TWallet = NonNullable<CollectiveQueryResult['collective']>['wallets'][0]

function UnlinkWalletCommand({
  disabled,
  data,
  onChange,
  onValidityChange
}: IProposalCommandInputProps) {
  const [walletId, setWalletId] = useState<string>(data.walletId || '')

  const { collective } = useCollectiveContext()
  const wallets = collective.wallets

  useEffect(() => {
    onChange({
      type: ProposalCommandType.UNLINK_WALLET,
      walletId
    })
    // eslint-disable-next-line
  }, [walletId])

  const isValid = useMemo(() => !!walletId, [walletId])
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4">
        {wallets.length ? (
          <StyledSelect<TWallet, false>
            height={50}
            options={wallets}
            value={wallets.find(x => x.id === walletId)}
            placeholder={`Wallet`}
            onChange={x => x && setWalletId(x.address)}
            isDisabled={disabled}
            getOptionLabel={wallet => wallet.nickname || wallet.address}
            getOptionValue={wallet => wallet.id}
          />
        ) : (
          <p>{`This DAO doesn't have any linked wallets yet`}</p>
        )}
      </div>
    </div>
  )
}

export default UnlinkWalletCommand
