import { useEffect } from 'react'
import TextField from 'components/common/TextField'
import WalletAddressInputField from 'components/Collectives/Common/WalletAddressInputField'
import Toggle from 'components/common/Toggle'
import { friendlyMethodInputType, friendlyMethodLabel } from './types'
import Button from 'components/Button'
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { pullAt } from 'lodash'
import StyledTooltip from 'components/Tooltip/Tooltip'
import FieldLabel from 'components/common/FieldLabel'

const AbiInputFields: React.FC<{
  name?: Maybe<string>
  type: string
  value: any
  onChange: CallbackWithParam<any>
  index: number
  errors: boolean | boolean[]
  addMore?: CallbackWithParam<number>
  removeItem?: CallbackWithParam<number>
}> = ({ type, name, value, onChange, index, errors, addMore, removeItem }) => {
  const typeIsArray = type.includes('[]')
  // set array values once if not there
  useEffect(() => {
    if (typeIsArray && !value) {
      onChange([''])
    }
  }, [onChange, type, typeIsArray, value])

  // array type
  if (typeIsArray) {
    if (!value?.length) {
      return null
    }
    return (
      <>
        <FieldLabel label={friendlyMethodLabel(name, type)} />
        <div className="space-y-2">
          {value.map((val, i) => (
            <AbiInputFields
              type={type.replace('[]', '')}
              key={`${index}_${type}_${i}`}
              value={val}
              onChange={valNew => {
                value[i] = valNew
                onChange([...value])
              }}
              index={i}
              errors={errors[i]}
              addMore={i => {
                const copy = [...value]
                copy.splice(i + 1, 0, '')
                onChange(copy)
              }}
              removeItem={
                value.length !== 1
                  ? i => {
                      const copy = [...value]
                      pullAt(copy, [i])
                      onChange([...copy])
                    }
                  : undefined
              }
            />
          ))}
        </div>
      </>
    )
  }

  const isInsideArrayType = !!addMore || !!removeItem

  // regular types
  return type === 'address' ? (
    <WalletAddressInputField
      label={friendlyMethodLabel(name, type)}
      placeholder={friendlyMethodInputType(type)}
      defaultValue={value}
      onChange={address => {
        if (value !== address) {
          onChange(address)
        }
      }}
      horizantalFullWidth
    />
  ) : type === 'bool' ? (
    <Toggle
      label={friendlyMethodLabel(name, type)}
      checked={!!value}
      onChange={checked => {
        onChange(checked)
      }}
      className="w-full"
    />
  ) : (
    <TextField
      label={
        (isInsideArrayType && index > 0) || isInsideArrayType
          ? undefined
          : friendlyMethodLabel(name, type)
      }
      placeholder={friendlyMethodInputType(type)}
      value={value}
      onChange={e => {
        const val = typeIsArray ? [...value, e.target.value] : e.target.value
        onChange(val)
      }}
      horizantalFullWidth
      error={
        errors
          ? `Entered data type does not match the expected type - ${type} exptected`
          : undefined
      }
      preLabel={isInsideArrayType ? `${index + 1}.` : undefined}
      preLabelClassName="px-2 mr-1 border-r border-gray-200"
      postContent={
        <div className="flex space-x-1">
          {removeItem && (
            <StyledTooltip title="Remove This Item">
              <Button
                icon={faMinus}
                onClick={() => removeItem(index)}
                size="xs"
              />
            </StyledTooltip>
          )}
          {addMore && (
            <StyledTooltip title="Insert After This Item">
              <Button icon={faPlus} onClick={() => addMore(index)} size="xs" />
            </StyledTooltip>
          )}
        </div>
      }
    />
  )
}

export default AbiInputFields
