import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { faChevronDown, faFilter } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CollectiveLogEventType } from 'graphql/generated'
import { without, uniq } from 'lodash'
import Button from 'components/Button'

interface IProps {
  types: CollectiveLogEventType[]
  setTypes: CallbackWithParam<Array<CollectiveLogEventType>>
}

function CollectiveLogsFilter({ types, setTypes }: IProps) {
  function toggle(toggleTypes: CollectiveLogEventType[]) {
    if (includesAll(toggleTypes)) {
      setTypes(without(types, ...toggleTypes))
    } else {
      setTypes(uniq([...types, ...toggleTypes]))
    }
  }

  function includesAll(toggleTypes: CollectiveLogEventType[]) {
    return toggleTypes.every(toggleType => types.includes(toggleType))
  }

  return (
    <DropdownMenu
      button={
        <Button
          color={types.length ? 'white' : 'lightgray'}
          size="sm"
          icon={faFilter}
          label="Filter by Type"
          showFocusRing={false}
          className="w-full"
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className="ml-2 text-gray-400"
          />
        </Button>
      }
      items={[
        { type: 'header', label: 'Activity Types' },
        {
          type: 'checkbox',
          label: 'All Types',
          onChange: () => setTypes([]),
          checked: types.length === 0
        },
        {
          type: 'checkbox',
          label: 'Token Transfers',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_TRANSFER_,
              CollectiveLogEventType.ON_TRANSFER_BATCH,
              CollectiveLogEventType.ON_TRANSFER_SINGLE
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_TRANSFER_,
            CollectiveLogEventType.ON_TRANSFER_BATCH,
            CollectiveLogEventType.ON_TRANSFER_SINGLE
          ])
        },
        {
          type: 'checkbox',
          label: 'Memberships',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_MEMBER_ADDED,
              CollectiveLogEventType.ON_MEMBER_REMOVED,
              CollectiveLogEventType.ON_COLLECTIVE_MEMBERSHIP_PROPOSED,
              CollectiveLogEventType.ON_COLLECTIVE_MEMBERSHIP_REQUESTED,
              CollectiveLogEventType.ON_ENABLED_NEW_MEMBER_REQUESTS_CHANGED,
              CollectiveLogEventType.ON_EXTERNAL_COLLECTIVE_JOINED,
              CollectiveLogEventType.ON_ADDED_OWNER,
              CollectiveLogEventType.ON_REMOVED_OWNER
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_MEMBER_ADDED,
            CollectiveLogEventType.ON_MEMBER_REMOVED,
            CollectiveLogEventType.ON_COLLECTIVE_MEMBERSHIP_PROPOSED,
            CollectiveLogEventType.ON_COLLECTIVE_MEMBERSHIP_REQUESTED,
            CollectiveLogEventType.ON_ENABLED_NEW_MEMBER_REQUESTS_CHANGED,
            CollectiveLogEventType.ON_EXTERNAL_COLLECTIVE_JOINED,
            CollectiveLogEventType.ON_ADDED_OWNER,
            CollectiveLogEventType.ON_REMOVED_OWNER
          ])
        },
        {
          type: 'checkbox',
          label: 'Treasury',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_MEMBER_WITHDREW_FUNDS,
              CollectiveLogEventType.ON_MEMBER_DESPOSITED_FUNDS,
              CollectiveLogEventType.ON_NON_DEPOSITED_ETHER,
              CollectiveLogEventType.ON_FUNDS_DEPOSITED,
              CollectiveLogEventType.ON_EXCHANGERATE_CHANGED,
              CollectiveLogEventType.ON_WALLET_LINKED,
              CollectiveLogEventType.ON_WALLET_UNLINKED
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_MEMBER_WITHDREW_FUNDS,
            CollectiveLogEventType.ON_MEMBER_DESPOSITED_FUNDS,
            CollectiveLogEventType.ON_NON_DEPOSITED_ETHER,
            CollectiveLogEventType.ON_FUNDS_DEPOSITED,
            CollectiveLogEventType.ON_EXCHANGERATE_CHANGED,
            CollectiveLogEventType.ON_WALLET_LINKED,
            CollectiveLogEventType.ON_WALLET_UNLINKED
          ])
        },
        {
          type: 'checkbox',
          label: 'Announcements',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_ANNOUNCEMENT_CHANGED,
              CollectiveLogEventType.ON_ANNOUNCEMENT_CREATED,
              CollectiveLogEventType.ON_ANNOUNCEMENT_DELETED
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_ANNOUNCEMENT_CHANGED,
            CollectiveLogEventType.ON_ANNOUNCEMENT_CREATED,
            CollectiveLogEventType.ON_ANNOUNCEMENT_DELETED
          ])
        },
        {
          type: 'checkbox',
          label: 'Delegations',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_DELEGATION_GRANTED,
              CollectiveLogEventType.ON_DELEGATION_REVOKED
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_DELEGATION_GRANTED,
            CollectiveLogEventType.ON_DELEGATION_REVOKED
          ])
        },
        {
          type: 'checkbox',
          label: 'Polls & Proposals',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_PROPOSALS_BATCHED,
              CollectiveLogEventType.ON_PROPOSAL_CANCELED,
              CollectiveLogEventType.ON_PROPOSAL_EXECUTED,
              CollectiveLogEventType.ON_PROPOSAL_EXECUTED_RESET,
              CollectiveLogEventType.ON_PROPOSAL_SIGNED,
              CollectiveLogEventType.ON_VOTE_CAST,
              CollectiveLogEventType.ON_VOTE_CHANGED
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_PROPOSALS_BATCHED,
            CollectiveLogEventType.ON_PROPOSAL_CANCELED,
            CollectiveLogEventType.ON_PROPOSAL_EXECUTED,
            CollectiveLogEventType.ON_PROPOSAL_EXECUTED_RESET,
            CollectiveLogEventType.ON_PROPOSAL_SIGNED,
            CollectiveLogEventType.ON_VOTE_CAST,
            CollectiveLogEventType.ON_VOTE_CHANGED
          ])
        },
        {
          type: 'checkbox',
          label: 'Guards & Gates',
          onChange: () =>
            toggle([
              CollectiveLogEventType.ON_DISABLE_TOKEN_TRANSFERS_CHANGED,
              CollectiveLogEventType.ON_ENABLE_DESPOSITS_CHANGED,
              CollectiveLogEventType.ON_ENABLE_WITHDRAWS_CHANGED,
              CollectiveLogEventType.ON_GATE_DEPOSITS_CHANGED,
              CollectiveLogEventType.ON_MIN_ETH_CONTRIBUTION_CHANGED,
              CollectiveLogEventType.ON_QUORUM_NEEDED_IN_PERCETANGE
            ]),
          checked: includesAll([
            CollectiveLogEventType.ON_DISABLE_TOKEN_TRANSFERS_CHANGED,
            CollectiveLogEventType.ON_ENABLE_DESPOSITS_CHANGED,
            CollectiveLogEventType.ON_ENABLE_WITHDRAWS_CHANGED,
            CollectiveLogEventType.ON_GATE_DEPOSITS_CHANGED,
            CollectiveLogEventType.ON_MIN_ETH_CONTRIBUTION_CHANGED,
            CollectiveLogEventType.ON_QUORUM_NEEDED_IN_PERCETANGE
          ])
        }
      ]}
    />
  )
}

export default CollectiveLogsFilter
