import Typography from 'components/common/Typography'
import { BundledCollectiveLogFieldsFragment } from 'graphql/generated'
import { uniqBy } from 'lodash'
import { LogCollectiveUserPopover } from './LogCollectiveUserPopover'

interface IProps {
  actors: BundledCollectiveLogFieldsFragment['collectiveUsers']
  limit?: number
}

function LogActors({ actors, limit = 2 }: IProps) {
  if (!actors.length) {
    return (
      <span className="inline-flex text-sm text-gray-700">{`An unknown user `}</span>
    )
  }

  const uniqueActors = uniqBy(actors, actor => actor.id)
  const renderedActors = uniqueActors
    .slice(0, limit)
    .map(actor => (
      <LogCollectiveUserPopover key={`actor-${actor.address}`} user={actor} />
    ))
  const unrenderedActorsCount = uniqueActors.length - renderedActors.length

  return (
    <div className="flex inline-flex items-center">
      {renderedActors.flatMap((actor, index) => (
        <div key={`actor-${index}`}>
          <Typography size="sm" component={'span'}>
            {actor}
            {index !== renderedActors.length - 1 &&
              (!unrenderedActorsCount && index === 0 ? <>&nbsp;&</> : ',')}
            &nbsp;
          </Typography>
        </div>
      ))}

      {unrenderedActorsCount > 0 && (
        <Typography size="sm">
          {`& ${unrenderedActorsCount} more`}&nbsp;
        </Typography>
      )}
    </div>
  )
}

export default LogActors
