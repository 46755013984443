import cls from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Thumbnail from 'components/Thumbnail'
import Link from 'next/link'
import { faChevronCircleRight } from '@fortawesome/pro-duotone-svg-icons'
import { CollectiveToken } from 'graphql/generated'
import { faCoin } from '@fortawesome/pro-regular-svg-icons'
import { getEtherscanUrlForToken } from 'lib/collectives/helpers'
import { useMemo } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'

interface IProps {
  textStyle?: string
  noAvatar?: boolean
  token: Pick<CollectiveToken, 'id' | 'name' | 'thumb' | 'address'>
  removeTokenFromName?: boolean
}

function CollectiveTokenPopover({
  noAvatar,
  textStyle,
  token,
  removeTokenFromName = false
}: IProps) {
  const { collective } = useCollectiveContext()
  const effectiveLink = useMemo(
    () =>
      getEtherscanUrlForToken(
        collective.network,
        'address',
        token.address,
        token.id
      ),
    [collective.network, token.address, token.id]
  )

  const name =
    token.name && removeTokenFromName
      ? token.name.replace('Token', '').replace('Token', '')
      : token.name || token.address
  return (
    <Link
      href={effectiveLink}
      className="ml-1 inline-flex items-baseline flex-row items-center space-x-1 group pr-2"
    >
      {!noAvatar &&
        (token.thumb ? (
          <Thumbnail
            src={token.thumb}
            initials={token.name}
            className="h-6 w-6 rounded-full overflow-hidden"
          />
        ) : (
          <FontAwesomeIcon
            icon={faCoin}
            className="rounded-full overflow-hidden"
          />
        ))}

      <p className={cls('text-sm text-gray-600', textStyle)}>{name}</p>

      <FontAwesomeIcon
        icon={faChevronCircleRight}
        className="opacity-20 group-hover:opacity-100 relative left-0 group-hover:left-1 transition-all"
      />
    </Link>
  )
}

export default CollectiveTokenPopover
