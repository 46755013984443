import BaseCollectiveLogListItem from './BaseCollectiveLogListItem'
import { ICollectiveLogListItemProps } from './common'

const CollectiveLogDataOnVoteCast: React.FC<
  ICollectiveLogListItemProps<'CollectiveLogDataOnVoteCast'>
> = ({ bundledCollectiveLog, isLast }) => {
  return (
    <BaseCollectiveLogListItem
      bundle={bundledCollectiveLog}
      isLast={isLast}
      summaryActionText={`voted on `}
      singleProposalText={'proposal'}
      detailsActionText={() => `voted on `}
      summaryManyProposalText={count => ` ${count} proposals`}
    />
  )
}

export default CollectiveLogDataOnVoteCast
