import {
  DelegatorCommand,
  DelegatorGranted
} from './DelegationsGrantedToViewerList'
import { ProposalCommandType } from 'graphql/generated'
import { formatToken } from 'lib/collectives/helpers'
import { BigNumber } from 'lib/BigInt'
import { getOptionForDelegationCommandType } from 'components/Collectives/Common/DelegationCommandInput'
import { getCommandLabel } from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import { useCollectiveContext } from 'context/CollectiveContext'
import CollectiveUserPopover from 'components/Collectives/Common/CollectiveUserPopover'
import { CustomDelegationDropdownDetails } from 'components/Collectives/Common/DelegationGrantedItem'

interface IProps {
  children?: React.ReactNode
  address: string
  delegator: DelegatorGranted
}

export default function DelegationsGrantedToViewerItem(props: IProps) {
  const { delegator } = props
  const { collective } = useCollectiveContext()

  const commandText = (command: DelegatorCommand) => {
    if (command.wildcard) {
      return 'Entire voting power'
    } else if (command.surveys) {
      return CustomDelegationDropdownDetails['SURVEYS']
        ?.selectedDelegationDescription
    } else if (
      !command.command ||
      !Object.values(ProposalCommandType).includes(command.command)
    ) {
      return '(unknown command)'
    }

    const commandOption = getOptionForDelegationCommandType(
      command.command as ProposalCommandType
    )
    const label = commandOption
      ? getCommandLabel(commandOption.renderForTypes[0], collective)
      : null
    return label || '(unknown command)'
  }

  const valueText = (command: DelegatorCommand) => {
    return command.command === ProposalCommandType.SEND_ETHER ||
      command.command === ProposalCommandType.SEND_TOKEN
      ? `: ${formatToken(
          new BigNumber(command.maxValue ? command.maxValue.toString() : 0)
        )} per proposal`
      : ''
  }

  return (
    <div className="flex space-x-2 px-3 py-4">
      <div className="basis-1/4">
        <CollectiveUserPopover user={delegator.collectiveMember.user} />
      </div>

      <div className="basis-3/4 space-y-2">
        <p className="text-gray-500 font-light text-sm">Delegations Granted:</p>

        <ul className="list-disc list-inside text-gray-700 font-medium text-sm">
          {delegator.commands.map((command, idx) => (
            <li key={idx}>
              <span>{commandText(command)}</span>
              <span>{valueText(command)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
