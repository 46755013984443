import { ProposalCommandType } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useState, useEffect, useMemo } from 'react'
import TextField from 'components/common/TextField'

function ChangeSafeThresholdCommand({
  disabled,
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const [safeThreshold, setSafeThreshold] = useState<number>(
    data.safeThreshold || 1
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CHANGE_SAFE_THRESHOLD,
      safeThreshold
    })
    // eslint-disable-next-line
  }, [safeThreshold])

  const isValid = useMemo(
    () => safeThreshold > 0 && safeThreshold !== collective.safeThreshold,
    [safeThreshold, collective.safeThreshold]
  )
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="flex flex-col w-full">
      <div className="flex pt-2 justify-center items-center">
        <TextField
          horizantalFullWidth
          type={'number'}
          label={`Minimum number of signators to execute:`}
          placeholder={'1'}
          value={safeThreshold}
          onChange={e =>
            setSafeThreshold(+e.target.value < 0 ? 0 : +e.target.value)
          }
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default ChangeSafeThresholdCommand
