import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { useCollectiveContext } from 'context/CollectiveContext'
import { BigNumber } from 'lib/BigInt'
import { CollectiveWallet, ERCAsset } from 'graphql/generated'
import listItemClasses from './useListItemStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatToken, getEtherscanUrl } from 'lib/collectives/helpers'
import { faCoins, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import HeldInHotWalletNotice from './HeldInHotWalletNotice'
import { BaseListItem } from './BaseListItem'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
  asset: Pick<
    ERCAsset,
    | 'thumb'
    | 'name'
    | 'balance'
    | 'decimals'
    | 'symbol'
    | 'estimatedValueEth'
    | 'estimatedValueUSD'
    | 'address'
  > & { wallet?: Maybe<Pick<CollectiveWallet, 'id' | 'isMainWallet'>> }
}

function ERC20AssetListItem({ asset }: IProps) {
  const { collective } = useCollectiveContext()

  const [isError, setIsError] = useState(!asset.thumb)
  const [isLoading, setIsLoading] = useState(!isError)
  return (
    <BaseListItem
      isError={isError}
      isLoading={isLoading}
      errorIcon={faCoins}
      topContent={
        asset.thumb && (
          <img
            className={twMerge(
              listItemClasses.mediaImage,
              'object-cover hover:object-contain',
              isError && 'object-contain'
            )}
            src={asset.thumb}
            onLoad={() => {
              setIsLoading(false)
            }}
            onError={({ currentTarget }) => {
              setIsError(true)
              setIsLoading(false)
              currentTarget.onerror = null // prevents looping
            }}
          />
        )
      }
      bottomContent={
        <>
          <div className="flex justify-between items-center">
            <div className="mr-1">
              <p className={listItemClasses.nameText}>{asset?.name}</p>
            </div>

            <DropdownMenu
              buttonClassName="-mr-1"
              items={[
                {
                  label: 'View on Etherscan',
                  icon: faExternalLink,
                  href: getEtherscanUrl(
                    collective.network,
                    'token',
                    asset.address
                  ),
                  target: '_blank'
                }
              ]}
            />
          </div>

          {!asset.wallet?.isMainWallet && (
            <HeldInHotWalletNotice
              className={listItemClasses.walletNoticeText}
              asset={asset}
              collective={collective}
            />
          )}

          <div className="flex justify-between">
            <div>
              {!!asset.estimatedValueEth && (
                <p className={listItemClasses.estimatedValueEthText}>
                  <FontAwesomeIcon
                    icon={faEthereum}
                    className={listItemClasses.estimatedValueEthIcon}
                  />
                  {formatToken(asset.estimatedValueEth, {
                    roundToDecimals: 5
                  })}
                </p>
              )}
              <p className={listItemClasses.estimatedValueUSDText}>
                {asset.estimatedValueUSD
                  ? `(~$${new BigNumber(asset.estimatedValueUSD).toFormat(2)})`
                  : ``}
              </p>
            </div>

            <div>
              <p className={listItemClasses.amountText}>{`${formatToken(
                asset.balance,
                {
                  decimals: asset.decimals,
                  roundToDecimals: 2
                }
              )} ${asset.symbol}`}</p>
            </div>
          </div>
        </>
      }
    />
  )
}

export default ERC20AssetListItem
