import EtherInputField from 'components/Collectives/Common/EtherInputField'
import { BigNumber } from 'lib/BigInt'
import useWalletConnect from 'hooks/collectives/walletConnect/useWalletConnect'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Spinner from 'components/Spinner'
import { ZERO_BN } from 'lib/collectives/helpers'
import TextField from 'components/common/TextField'
import Button from 'components/Button'
import { TRemoteConnect } from './types'

export function RemoteWalletConnect({
  onValidityChange,
  target,
  setTarget,
  abiData,
  setAbiData,
  amountInWei,
  setAmountInWei
}: TRemoteConnect) {
  const isValid = useMemo(
    () => !!target.trim() && !!abiData.trim(),
    [target, abiData]
  )
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  const [wcCode, setWcCode] = useState('')
  const onEthTransaction = useCallback(
    ({ to, data, value }: any) => {
      setTarget(to)
      setAbiData(data)
      setAmountInWei(value)
    },
    [setAbiData, setAmountInWei, setTarget]
  )

  const {
    wcConnect,
    wcClientData,
    wcDisconnect,
    isWallectConnectInitialized,
    error
  } = useWalletConnect({ onEthTransaction })

  const [isConnecting, setIsConnecting] = useState(false)
  useEffect(() => {
    if (isConnecting) {
      setIsConnecting(!wcClientData)
    }
    // eslint-disable-next-line
  }, [wcClientData])

  const onCodePaste = useCallback(
    (event: React.ClipboardEvent) => {
      const connectWithUri = (data: string) => {
        if (data.startsWith('wc:')) {
          setIsConnecting(true)
          wcConnect(data)
        }
      }

      setWcCode('')

      if (wcClientData) {
        return
      }

      const items = event.clipboardData.items

      for (const index in items) {
        const item = items[index]

        if (item.kind === 'string' && item.type === 'text/plain') {
          connectWithUri(event.clipboardData.getData('Text'))
        }
      }
    },
    [wcClientData, wcConnect]
  )

  if (!isWallectConnectInitialized) {
    return (
      <div className="flex flex-col w-full">
        <div className="pt-4">
          <Spinner />

          <p className="text-base text-center pt-5">
            {`Initializing WalletConnect Provider...`}
          </p>
        </div>
      </div>
    )
  } else if (isConnecting) {
    return (
      <div className="flex flex-col w-full">
        <div className="pt-4">
          <Spinner />

          <p className="text-base text-center pt-5">
            {`Establishing WalletConnect session...`}
          </p>
        </div>
      </div>
    )
  } else if (!wcClientData) {
    return (
      <div className="flex flex-col w-full">
        <div className="pt-4">
          <TextField
            label={`Wallet Connect Pairing Code (must paste code)`}
            value={wcCode}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setWcCode(e.target.value)
            }
            onPaste={onCodePaste}
          />
        </div>

        {!!error && <p className="text-sm text-red-500 pt-2">Error: {error}</p>}
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center bg-white p-5 rounded-lg border-1 border-gray-200 mt-5">
        <img
          src={wcClientData.icons[0]}
          className="w-[75px] rounded-[10px] mr-5"
        />

        <div className="flex flex-col flex-1">
          <p className="font-light text-base pt-5">
            Connected to {wcClientData.name} via WalletConnect. Please initiate
            transaction remotely.
          </p>

          <div className="pt-5">
            <Button
              color="blue"
              size="sm"
              onClick={() => wcDisconnect()}
              label="Disconnect Session"
            />
          </div>
        </div>
      </div>

      <div className="pt-4">
        <TextField
          label={`Remote Contract Address`}
          value={target}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTarget(e.target.value)
          }
          disabled
        />
      </div>

      <div className="pt-4">
        <TextField
          label={`ABI Call Data`}
          value={abiData}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAbiData(e.target.value)
          }
          disabled
        />
      </div>

      <div className="pt-4">
        <EtherInputField
          label={`Amount`}
          disabled={true}
          initialValueInWei={amountInWei.gt(0) ? amountInWei : BigNumber(0)}
          onChangeInWei={setAmountInWei}
          minWei={ZERO_BN}
        />
      </div>
    </div>
  )
}
