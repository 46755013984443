import Button from 'components/Button'
import { BigNumber } from 'lib/BigInt'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ProposalCommandType,
  useDelegatesGrantedByViewerQuery
} from 'graphql/generated'
import useToasts from 'hooks/useToasts'
import { parseWeb3ErrorMessage, TTxUiState } from 'lib/collectives/helpers'
import useDelegateVotes from 'hooks/collectives/useDelegateVote'
import { DelegationsGrantedList } from '../../Common/DelegationsGrantedList'
import CollectiveMembersDropdown from '../../Common/CollectiveMembersDropdown'
import {
  faCheck,
  faChevronRight,
  faSpinner
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual, noop, sortBy } from 'lodash'
import EtherscanLink from 'components/Collectives/Common/EtherscanLink'
import { CustomDelegationValues } from 'components/Collectives/Common/DelegationGrantedItem'
import { VoteDelegation } from '@upstreamapp/upstream-dao'

const BASE_PROPOSAL_OPTIONS: VoteDelegation.DelegationStruct[] = sortBy(
  [
    ...Object.values(CustomDelegationValues),
    ...Object.values(ProposalCommandType)
  ].map(type => ({
    command: type,
    delegatee: '',
    maxValue: ''
  })),
  'command'
)

function YourDelegations({
  onClose,
  onWildcardModeChange
}: {
  onClose?: Callback
  onWildcardModeChange: CallbackWithParam<boolean>
}) {
  const { demoMode, collective } = useCollectiveContext()
  const { addToast } = useToasts()

  const [state, setState] = useState<Maybe<TTxUiState>>(null)
  const [wildcardMode, setWildcardMode] = useState<boolean>(true)
  const [wildcardDelegateAddress, setWildcardDelegateAddress] =
    useState<Maybe<string>>(null)
  const [committedDelegations, setCommittedDelegations] = useState<
    VoteDelegation.DelegationStruct[]
  >([])
  const [delegations, setDelegations] = useState<
    VoteDelegation.DelegationStruct[]
  >([])

  const commandsChanged = useMemo(
    () => !isEqual(delegations, committedDelegations),
    [delegations, committedDelegations]
  )

  useEffect(() => {
    onWildcardModeChange(wildcardMode)
  }, [wildcardMode, onWildcardModeChange])

  const [revokingDelegations, setRevokingDelegations] = useState(false)
  const { delegateVotes, txHash } = useDelegateVotes({ collective })

  const [hasDelegations, setHasDelegations] = useState(false)
  const { loading } = useDelegatesGrantedByViewerQuery({
    variables: {
      collectiveId: collective.id
    },
    skip: demoMode,
    fetchPolicy: 'no-cache',
    onCompleted: resp => {
      const delegatesGrantedByViewer = resp.collective?.delegatesGrantedByViewer
      if (!delegatesGrantedByViewer) {
        return
      }

      const _hasDelegates = delegatesGrantedByViewer.length > 0
      setHasDelegations(_hasDelegates)

      const _wildcardDelegate = delegatesGrantedByViewer.find(x => x.wildcard)
      setWildcardMode(!_hasDelegates || !!_wildcardDelegate)
      setWildcardDelegateAddress(
        _wildcardDelegate
          ? _wildcardDelegate.collectiveMember.user.address
          : null
      )

      const _commands = delegatesGrantedByViewer.reduce((acc, val) => {
        if (val.command) {
          acc.push({
            maxValue: val.maxValue || BigInt(0),
            command: val.command,
            delegatee: val.collectiveMember.user.address
          })
        }

        if (val.surveys) {
          acc.push({
            maxValue: BigInt(0),
            command: CustomDelegationValues.SURVEYS,
            delegatee: val.collectiveMember.user.address
          })
        }

        return acc
      }, [] as VoteDelegation.DelegationStruct[])

      const all = sortBy(
        [
          ..._commands,
          ...BASE_PROPOSAL_OPTIONS.filter(
            option =>
              !_commands.find(
                newCommand => option.command === newCommand.command
              )
          )
        ],
        'command'
      )

      setCommittedDelegations(all.map(item => ({ ...item })))
      setDelegations(all)
    }
  })

  const executeDelegation = async (resetDelegations?: boolean) => {
    if (demoMode) {
      return
    }
    if (resetDelegations) {
      setRevokingDelegations(true)
    }

    try {
      setState(TTxUiState.WAITING_FOR_PROVIDER)
      const value = resetDelegations
        ? []
        : Object.values(delegations)
            .filter(v => v.command && v.delegatee)
            .map(({ delegatee, command, maxValue }) => ({
              delegatee,
              command,
              maxValue: maxValue || new BigNumber(0).toEthersBigNumber()
            }))
      await delegateVotes({
        value,
        onCall: setState
      })

      setState(TTxUiState.TX_SUCCESS)
    } catch (err) {
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      setState(null)
    } finally {
      if (resetDelegations) {
        setRevokingDelegations(false)
      }
    }
  }

  const delegateAllVotes = useCallback((delegatee: Maybe<string>) => {
    setDelegations(
      delegatee
        ? [
            {
              command: '*',
              delegatee,
              maxValue: new BigNumber(0).toEthersBigNumber()
            }
          ]
        : [...BASE_PROPOSAL_OPTIONS]
    )
  }, [])

  if (loading) {
    return (
      <div className="py-4 space-y-4 text-center text-gray-500 text-sm font-light">
        <p>Loading your delegations</p>
        <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
      </div>
    )
  } else if (state === TTxUiState.WAITING_FOR_PROVIDER) {
    return (
      <div className="py-4 space-y-4 text-center text-gray-500 text-sm font-light">
        <p>Waiting for your your wallet transaction approval...</p>

        <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
      </div>
    )
  } else if (state === TTxUiState.WAITING_FOR_NETWORK) {
    return (
      <div className="py-4 space-y-4 text-center text-gray-500 text-sm font-light">
        <p>Waiting for your delegation transaction to clear the network...</p>

        <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />

        {!!txHash && (
          <EtherscanLink
            network={collective.network}
            address={txHash}
            type="tx"
            label="View transaction on Etherscan"
            shortAddress
          />
        )}
      </div>
    )
  } else if (state === TTxUiState.TX_SUCCESS) {
    return (
      <div>
        <div className="py-4 space-y-4 text-center text-gray-500 text-sm font-light">
          <p>{`You've successfully ${
            revokingDelegations ? 'revoked' : 'delegated'
          } your voting power.`}</p>

          <FontAwesomeIcon icon={faCheck} className="text-green-600" />

          {!!txHash && (
            <EtherscanLink
              network={collective.network}
              address={txHash}
              type="tx"
              label="View transaction on Etherscan"
              shortAddress
            />
          )}
        </div>

        <div className="flex justify-end">
          <Button
            rounded="md"
            onClick={onClose}
            color="lightgray"
            label="Close"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col overflow-hidden">
      {wildcardMode ? (
        <div className="overflow-auto flex-1">
          <p className="text-gray-900 font-light text-sm pt-2 pb-4">
            {`You're able to delegate your voting power to a specific person in
            the DAO. Choose to delegate all of your votes or just for
            specific commands. You can revoke your voting power at any time.`}
          </p>

          <p className="text-gray-900 font-bold text-sm">
            Delegate your entire voting power
          </p>

          <CollectiveMembersDropdown
            value={wildcardDelegateAddress ?? ''}
            onChange={delegateAllVotes}
            collectiveId={collective.id}
            excludeCurrentMember={true}
          />

          <div className="pt-4 pb-3">
            <p className="text-gray-700 text-sm font-light">
              {'or '}
              <Button
                className="px-0"
                onClick={() => {
                  setWildcardMode(false)
                  delegateAllVotes(null)
                }}
                color="link"
              >
                {'Delegate a specific command '}
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-auto flex-1">
          <DelegationsGrantedList
            onChange={setDelegations}
            delegations={delegations}
          />

          <div className="pt-2 pb-3">
            <p className="text-gray-700 text-sm font-light">
              {'or '}
              <Button
                className="px-0"
                onClick={() => setWildcardMode(true)}
                color="link"
              >
                {'Delegate your entire voting power '}
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-between pt-1">
        {!loading && hasDelegations ? (
          <Button
            rounded="md"
            color="lightgray"
            onClick={() => executeDelegation(true)}
            label={'Revoke All'}
          />
        ) : (
          <div />
        )}

        <div className="flex justify-end space-x-2">
          <Button
            rounded="md"
            onClick={onClose}
            color="outline"
            label="Close"
          />

          <Button
            rounded="md"
            color="blue"
            onClick={!demoMode ? () => executeDelegation() : noop}
            label="Save Delegations"
            disabled={!demoMode && (!!state || !commandsChanged)}
          />
        </div>
      </div>
    </div>
  )
}

export default YourDelegations
