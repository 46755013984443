import { BigNumber } from 'lib/BigInt'
import { CollectiveQueryResult, WalletType } from 'graphql/generated'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollectiveContext } from 'context/CollectiveContext'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { getEtherscanUrl } from 'lib/collectives/helpers'
import { ISummary, useWalletSummary } from 'hooks/collectives/useWalletSummary'
import EtherscanLink from '../../Common/EtherscanLink'
import { CHART_COLORS } from './WalletColors'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { Checkbox, Typography } from 'components/common'

interface IProps {
  wallet: NonNullable<CollectiveQueryResult['collective']>['wallets'][0]
  walletIndex: number
  charted: boolean
  onToggleChart: Callback
}

function getTypeLabel(type: WalletType) {
  switch (type) {
    case WalletType.FLOW:
      return 'Flow'
    case WalletType.ETHEREUM:
    default:
      return 'Ethereum'
  }
}

type TListItem = {
  isFlow: boolean
  isEth: boolean
  flowBalance: string
  charted: boolean
  onToggleChart: Callback
  walletIndex: number
  summary: ISummary
  wallet: NonNullable<CollectiveQueryResult['collective']>['wallets'][0]
}

const MobileItem: React.FC<TListItem> = ({
  isEth,
  isFlow,
  flowBalance,
  charted,
  onToggleChart,
  summary,
  walletIndex,
  wallet
}) => {
  const { collective } = useCollectiveContext()
  return (
    <tr className={'wallet-list-row'}>
      <td className={'mobile-narrow-cell py-5 px-0 text-center align-baseline'}>
        <input
          type={'checkbox'}
          color="primary"
          checked={charted}
          className="rounded"
          onChange={onToggleChart}
          style={{
            color: CHART_COLORS[walletIndex % CHART_COLORS.length][0],
            width: 24,
            height: 24
          }}
        />
      </td>

      <td>
        <table width={'100%'}>
          <tbody>
            <tr>
              <td className={'py-[15px] px-[10px] cell'} colSpan={4}>
                <Typography
                  color="gray"
                  className={'text-[15px] break-keep whitespace-nowrap'}
                >
                  {wallet.nickname}
                </Typography>

                <div className="flex items-center">
                  <Typography size="xs" color="gray" fontWeight="light">
                    {getTypeLabel(wallet.type)} Wallet:{' '}
                  </Typography>

                  {isEth && (
                    <EtherscanLink
                      shortAddress
                      className={'text-[12px] font-medium text-gray-500'}
                      address={wallet.address}
                      network={collective.network}
                      type={'address'}
                    />
                  )}
                  {isFlow && (
                    <p className={'text-[12px] font-medium text-gray-500'}>
                      {wallet.address}
                    </p>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <table width={'100%'}>
                  <tbody>
                    <tr>
                      <td className={'py-[15px] px-[10px] cell'} width={'50%'}>
                        <Typography
                          size="xs"
                          fontWeight="medium"
                          color="gray"
                          className="uppercase text-left break-keep whitespace-nowrap"
                        >{`Balance`}</Typography>
                        <Typography
                          size="sm"
                          fontWeight="light"
                          color="gray"
                          className="pt-1"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={'text-gray-500 mr-[2px]'}
                          />
                          {summary.balance.ethFormatted}
                        </Typography>
                        <Typography
                          size="xs"
                          fontWeight="light"
                          color="gray"
                          className="pt-[5px]"
                        >{`$${summary.balance.usd}`}</Typography>
                        {isFlow && (
                          <Typography
                            size="xs"
                            fontWeight="light"
                            color="gray"
                          >{`${flowBalance} FLOW`}</Typography>
                        )}
                      </td>

                      <td className={'py-[15px] px-[10px] cell'} width={'50%'}>
                        <Typography
                          size="xs"
                          fontWeight="medium"
                          color="gray"
                          className="uppercase text-left break-keep whitespace-nowrap"
                        >{`Tokens`}</Typography>
                        <Typography
                          size="sm"
                          fontWeight="light"
                          color="gray"
                          className="pt-1"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={'text-gray-500 mr-[2px]'}
                          />
                          {summary.tokens.ethFormatted}
                        </Typography>
                        <Typography
                          size="xs"
                          fontWeight="light"
                          color="gray"
                          className="pt-[5px]"
                        >{`$${summary.tokens.usd}`}</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td className={'py-[15px] px-[10px] cell'} width={'50%'}>
                        <Typography
                          size="xs"
                          fontWeight="medium"
                          color="gray"
                          className="uppercase text-left break-keep whitespace-nowrap"
                        >{`NFTs`}</Typography>
                        <Typography
                          size="sm"
                          fontWeight="light"
                          color="gray"
                          className="pt-1"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={'text-gray-500 mr-[2px]'}
                          />
                          {summary.nfts.ethFormatted}
                        </Typography>
                        <Typography
                          size="xs"
                          fontWeight="light"
                          color="gray"
                          className="pt-[5px]"
                        >{`$${summary.nfts.usd}`}</Typography>
                      </td>

                      <td className={'py-[15px] px-[10px] cell'} width={'50%'}>
                        <Typography
                          size="xs"
                          fontWeight="medium"
                          color="gray"
                          className="uppercase text-left break-keep whitespace-nowrap"
                        >{`Total Value`}</Typography>
                        <Typography
                          size="sm"
                          fontWeight="light"
                          color="gray"
                          className="pt-1"
                        >
                          <FontAwesomeIcon
                            icon={faEthereum}
                            className={'text-gray-500 mr-[2px]'}
                          />
                          {summary.total.ethFormatted}
                        </Typography>
                        <Typography
                          size="xs"
                          fontWeight="light"
                          color="gray"
                          className="pt-[5px]"
                        >{`$${summary.total.usd}`}</Typography>
                        {isFlow && (
                          <Typography
                            size="xs"
                            fontWeight="light"
                            color="gray"
                          >{`${flowBalance} FLOW`}</Typography>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>

      <td
        align="right"
        className={'mobile-narrow-cell py-5 px-0 text-center align-baseline'}
      >
        <div className="flex justify-center">
          <DropdownMenu
            items={[
              isEth && {
                label: 'View on OpenSea',
                icon: faExternalLink,
                href: `https://opensea.io/${wallet.address}`,
                target: '_blank'
              },
              isEth && {
                label: 'View on Etherscan',
                icon: faExternalLink,
                href: getEtherscanUrl(
                  collective.network,
                  'address',
                  wallet.address
                ),
                target: '_blank'
              },
              isFlow && {
                label: 'View on Flowscan',
                icon: faExternalLink,
                href: `https://flowscan.org/account/${wallet.address}`,
                target: '_blank'
              }
            ]}
          />
        </div>
      </td>
    </tr>
  )
}

const FullListItem: React.FC<TListItem> = ({
  charted,
  flowBalance,
  isEth,
  isFlow,
  onToggleChart,
  walletIndex,
  summary,
  wallet
}) => {
  const { collective } = useCollectiveContext()
  return (
    <tr className={'wallet-list-row'}>
      <td className={'p-[5px] narrow-cell'}>
        <div className="ml-3">
          <Checkbox
            checked={charted}
            size={'lg'}
            onChange={onToggleChart}
            customColor={`${
              CHART_COLORS[walletIndex % CHART_COLORS.length][0]
            }`}
          />
        </div>
      </td>

      <td className={'py-[15px] px-[10px] cell'}>
        <Typography
          color="gray"
          className={'text-[15px] break-keep whitespace-nowrap'}
        >
          {wallet.nickname}
        </Typography>

        <Typography
          size="sm"
          fontWeight="light"
          color="gray"
          className={'pt-[10px]'}
        >
          {getTypeLabel(wallet.type)} Wallet
        </Typography>
        {isEth && (
          <EtherscanLink
            shortAddress
            className={'text-[12px] font-medium text-gray-500'}
            address={wallet.address}
            network={collective.network}
            type={'address'}
          />
        )}
        {isFlow && (
          <p className={'text-[12px] font-medium text-gray-500'}>
            {wallet.address}
          </p>
        )}
      </td>

      <td align="right" className={'py-[15px] px-[10px] cell'}>
        <Typography
          size="sm"
          fontWeight="light"
          color="gray"
          className="pt-[10px]"
        >
          <FontAwesomeIcon
            icon={faEthereum}
            className={'text-gray-500 mr-[2px]'}
          />
          {summary.balance.ethFormatted}
        </Typography>
        <Typography
          size="xs"
          fontWeight="light"
          color="gray"
          className="pt-[5px]"
        >{`$${summary.balance.usd}`}</Typography>
        {isFlow && (
          <Typography
            size="xs"
            fontWeight="light"
            color="gray"
          >{`${flowBalance} FLOW`}</Typography>
        )}
      </td>

      <td align="right" className={'py-[15px] px-[10px] cell'}>
        <Typography
          size="sm"
          fontWeight="light"
          color="gray"
          className="pt-[10px]"
        >
          <FontAwesomeIcon
            icon={faEthereum}
            className={'text-gray-500 mr-[2px]'}
          />
          {summary.tokens.ethFormatted}
        </Typography>
        <Typography
          size="xs"
          fontWeight="light"
          color="gray"
          className="pt-[5px]"
        >{`$${summary.tokens.usd}`}</Typography>
      </td>

      <td align="right" className={'py-[15px] px-[10px] cell'}>
        <Typography
          size="sm"
          fontWeight="light"
          color="gray"
          className="pt-[10px]"
        >
          <FontAwesomeIcon
            icon={faEthereum}
            className={'text-gray-500 mr-[2px]'}
          />
          {summary.nfts.ethFormatted}
        </Typography>
        <Typography
          size="xs"
          fontWeight="light"
          color="gray"
          className="pt-[5px]"
        >{`$${summary.nfts.usd}`}</Typography>
      </td>

      <td align="right" className={'py-[15px] px-[10px] cell'}>
        <Typography
          size="sm"
          fontWeight="light"
          color="gray"
          className="pt-[10px]"
        >
          <FontAwesomeIcon
            icon={faEthereum}
            className={'text-gray-500 mr-[2px]'}
          />
          {summary.total.ethFormatted}
        </Typography>
        <Typography
          size="xs"
          fontWeight="light"
          color="gray"
          className="pt-[5px]"
        >{`$${summary.total.usd}`}</Typography>
        {isFlow && (
          <Typography
            size="xs"
            fontWeight="light"
            color="gray"
          >{`${flowBalance} FLOW`}</Typography>
        )}
      </td>

      <td align="right" className={'p-[5px] narrow-cell'}>
        <div className="flex justify-center">
          <DropdownMenu
            items={[
              isEth && {
                label: 'View on OpenSea',
                icon: faExternalLink,
                href: `https://opensea.io/${wallet.address}`,
                target: '_blank'
              },
              isEth && {
                label: 'View on Etherscan',
                icon: faExternalLink,
                href: getEtherscanUrl(
                  collective.network,
                  'address',
                  wallet.address
                ),
                target: '_blank'
              },
              isFlow && {
                label: 'View on Flowscan',
                icon: faExternalLink,
                href: `https://flowscan.org/account/${wallet.address}`,
                target: '_blank'
              }
            ]}
          />
        </div>
      </td>
    </tr>
  )
}

function WalletListItem({
  wallet,
  walletIndex,
  charted,
  onToggleChart
}: IProps) {
  const { collective } = useCollectiveContext()
  const summary = useWalletSummary(collective, [wallet], {
    ethFormattedDecimals: 2
  })

  const isEth = wallet.type === WalletType.ETHEREUM
  const isFlow = wallet.type === WalletType.FLOW
  const flowBalance =
    wallet.type === WalletType.FLOW
      ? new BigNumber(wallet.balance).shiftedBy(-8).toFormat(2)
      : ''

  const { isSm } = useBreakpoint()

  return isSm ? (
    <FullListItem
      isEth={isEth}
      isFlow={isFlow}
      flowBalance={flowBalance}
      charted={charted}
      onToggleChart={onToggleChart}
      summary={summary}
      walletIndex={walletIndex}
      wallet={wallet}
    />
  ) : (
    <MobileItem
      isEth={isEth}
      isFlow={isFlow}
      flowBalance={flowBalance}
      charted={charted}
      onToggleChart={onToggleChart}
      summary={summary}
      walletIndex={walletIndex}
      wallet={wallet}
    />
  )
}

export default WalletListItem
